import Api from "../api";

class StudentService {
  async getProfile() {
    let response;
    try {
      response = await Api.get("student/profile");
    } catch (err) {
      response = err;
    }
    return response;
  }

  async updateStudent(id, body) {
    let response;
    try {
      response = await Api.put(`student/${id}`, body);
    } catch (err) {
      response = err;
    }
    return response;
  }

  async getLanguages() {
    let response;
    try {
      response = await Api.get("language");
    } catch (err) {
      response = err;
    }
    return response;
  }

  async getCareers() {
    let response;
    try {
      response = await Api.get("career");
    } catch (err) {
      response = err;
    }
    return response;
  }

  async getKnowledges() {
    let response;
    try {
      response = await Api.get("knowledge");
    } catch (err) {
      response = err;
    }
    return response;
  }

  async getSoftSkills() {
    let response;
    try {
      response = await Api.get("skill");
    } catch (err) {
      response = err;
    }
    return response;
  }

  async getJobOpportunities() {
    let response;
    try {
      response = await Api.get(`job-opportunities/for-me`);
    } catch (err) {
      response = err;
    }
    return response;
  }

  async createCertification(body) {
    let response;
    try {
      response = await Api.post(`informal-education`, body);
    } catch (err) {
      response = err;
    }
    return response;
  }

  async updateCertification(id, body) {
    let response;
    try {
      response = await Api.put(`informal-education/${id}`, body);
    } catch (err) {
      response = err;
    }
    return response;
  }

  async deleteCertification(id) {
    let response;
    try {
      response = await Api.delete(`informal-education/${id}`);
    } catch (err) {
      response = err;
    }
    return response;
  }

  async createExperience(body) {
    let response;
    try {
      response = await Api.post(`experience`, body);
    } catch (err) {
      response = err;
    }
    return response;
  }

  async updateExperience(id, body) {
    let response;
    try {
      response = await Api.put(`experience/${id}`, body);
    } catch (err) {
      response = err;
    }
    return response;
  }

  async deleteExperience(id) {
    let response;
    try {
      response = await Api.delete(`experience/${id}`);
    } catch (err) {
      response = err;
    }
    return response;
  }

  async applyToJob(jobId) {
    let response;
    try {
      response = await Api.put(`job-opportunities/apply/${jobId}`);
    } catch (err) {
      response = err;
    }
    return response;
  }

  async getAgreements() {
    let response;
    try {
      response = await Api.get(`agreements/student`);
    } catch (err) {
      response = err;
    }
    return response;
  }

  async createLanguage(body) {
    let response;
    try {
      response = await Api.post(`student/language`, body);
    } catch (err) {
      response = err;
    }
    return response;
  }

  async deleteLanguage(languageId) {
    let response;
    try {
      response = await Api.delete(`student-language/${languageId}`);
    } catch (err) {
      response = err;
    }
    return response;
  }
}

export default new StudentService();
