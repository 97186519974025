import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import useAPIError from "../../../hooks";
import breakpoints from "../../../assets/theme/base/breakpoints";
import backgroundImage from "../../../assets/images/bg-profile.jpeg";
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import { Tab, Tabs } from "@mui/material";
import { Formik } from "formik";
import {
  StudentOnboardingStep1Schema,
  StudentOnboardingStep2Schema,
  StudentOnboardingStep3Schema,
  StudentOnboardingStep4Schema,
  StudentOnboardingStep5Schema,
} from "../../../helpers/schemas/onboardingSchemas";
import StudentOnboardingStep1 from "../onboarding/steps/studentOnboardingStep1";
import StudentOnboardingStep2 from "../onboarding/steps/studentOnboardingStep2";
import StudentOnboardingStep3 from "../onboarding/steps/studentOnboardingStep3";
import StudentOnboardingStep4 from "../onboarding/steps/studentOnboardingStep4";
import StudentService from "../../../services/api/student/studentService";
import MDSnackbar from "../../../components/MDSnackbar";
import ProfileStep5 from "./profile/profileStep5";
import { getCurrentDate } from "../../../utils/dashboard";

function StudentProfile() {
  const [studentData, setStudentData] = useState();
  const [openNotification, setOpenNotification] = useState(false);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const { addError } = useAPIError();
  const [socialNetworksState, setSocialNetworksState] = React.useState({
    instagram: false,
    facebook: false,
    twitter: false,
    linkedin: false,
  });
  const [experiences, setExperiences] = React.useState([]);
  const [languages, setLanguages] = React.useState([]);
  const [certifications, setCertifications] = React.useState([]);
  const [knowledges, setKnowledges] = React.useState([]);
  const [softSkills, setSoftSkills] = React.useState([]);

  const handleChangeSocialNetworks = (event) => {
    setSocialNetworksState({
      ...socialNetworksState,
      [event.target.name]: event.target.checked,
    });
  };

  const socialNetworks = () => {
    let array = [];
    for (const socialNetwork in socialNetworksState) {
      if (socialNetworksState[socialNetwork] === true) {
        array.push(socialNetwork);
      }
    }
    return array;
  };
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const getStudentData = async () => {
    const response = await StudentService.getProfile();
    setStudentData(response.data);
    socialNetworksState.instagram =
      response.data.socialNetworks.includes("instagram");
    socialNetworksState.facebook =
      response.data.socialNetworks.includes("facebook");
    socialNetworksState.twitter =
      response.data.socialNetworks.includes("twitter");
    socialNetworksState.linkedin =
      response.data.socialNetworks.includes("linkedin");
    setExperiences(response.data.experiences);
    setLanguages(response.data.languages);
    setCertifications(response.data.informalEducations);
    const studentKnowledges = response.data.knowledges.map((k) => k.knowledge);
    setKnowledges(studentKnowledges);
    const studentSoftSkills = response.data.skills.map((s) => s.skill);
    setSoftSkills(studentSoftSkills);
  };

  useEffect(() => {
    getStudentData();
  }, []);

  const onSubmit = async (values) => {
    const knowledgeIds = knowledges.map((k) => {
      return k.id;
    });
    const softSkillIds = softSkills.map((s) => {
      return s.id;
    });
    const body = {
      cuil: values.cuil,
      birthdate: values.birthdate,
      phone: values.phone,
      gender: values.gender,
      civilStatus: values.civilStatus,
      socialNetworks: socialNetworks(),
      address: values.address,
      isOwner: values.isOwner === "true",
      studentLocation: {
        city: values.city,
        province: values.province,
        postalCode: values.postalCode,
      },
      highSchoolName: values.highSchoolName,
      highSchoolLocation: {
        city: values.highSchoolCity,
        province: values.highSchoolProvince,
        postalCode: values.highSchoolPostalCode,
      },
      highSchoolDegree: values.highSchoolDegree,
      careerId: values.careerId,
      startYear: values.startYear,
      currentCareerYear: values.currentCareerYear,
      approvedSubjectAmount: values.approvedSubjectAmount,
      hasHealthInsurance: values.hasHealthInsurance === "true",
      healthInsurance: values.healthInsurance,
      bloodType: values.bloodType,
      bloodDonor: values.bloodDonor === "true",
      personalMotivation: values.personalMotivation,
      knowledgeIds: knowledgeIds,
      skillIds: softSkillIds,
    };
    const res1 = await StudentService.updateStudent(studentData?.id, body);
    if (res1.status === 200) {
      await getStudentData();
      setOpenNotification(true);
    } else {
      addError(`${res1.data.message}`, res1.status);
    }
  };

  const initialValues = () => {
    return {
      cuil: studentData?.cuil || "",
      gender: studentData?.gender || "",
      civilStatus: studentData?.civilStatus || "",
      birthdate: studentData?.birthdate.split("T")[0] || getCurrentDate(),
      phone: studentData?.phone || "",
      address: studentData?.address || "",
      city: studentData?.location.city || "",
      province: studentData?.location.province || "",
      postalCode: studentData?.location.postalCode || "",
      isOwner: studentData?.isOwner || false,
      highSchoolName: studentData?.highSchool.name || "",
      highSchoolDegree: studentData?.highSchoolDegree || "",
      highSchoolCity: studentData?.highSchool.location.city || "",
      highSchoolProvince: studentData?.highSchool.location.province || "",
      highSchoolPostalCode: studentData?.highSchool.location.postalCode || "",
      careerId: studentData?.career.id || "",
      approvedSubjectAmount: studentData?.approvedSubjectsAmount || "",
      startYear: studentData?.startYear || "",
      currentCareerYear: studentData?.currentCareerYear || "",
      hasHealthInsurance: studentData?.hasHealthInsurance || false,
      healthInsurance: studentData?.healthInsurance.name || "",
      bloodType: studentData?.bloodType || "",
      bloodDonor: !!studentData?.bloodDonor || false,
      personalMotivation: studentData?.personalMotivation || "",
    };
  };

  const schemaDecider = () => {
    if (tabValue === 0) {
      return StudentOnboardingStep1Schema;
    }
    if (tabValue === 1) {
      return StudentOnboardingStep2Schema;
    }
    if (tabValue === 2) {
      return StudentOnboardingStep3Schema;
    }
    if (tabValue === 3) {
      return StudentOnboardingStep4Schema;
    }
    if (tabValue === 4) {
      return StudentOnboardingStep5Schema;
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDSnackbar
        icon="notifications"
        color="success"
        title="Perfil actualizado correctamente"
        open={openNotification}
        close={() => setOpenNotification(false)}
      />
      <MDBox mb={2} />
      <MDBox position="relative" mb={5}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="10.75rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({
              functions: { rgba, linearGradient },
              palette: { gradients },
            }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.6),
                rgba(gradients.info.state, 0.6)
              )}, url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />
        <Card
          sx={{
            position: "relative",
            mt: -8,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  Mis datos
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sx={{ ml: "auto" }}>
              <AppBar position="static">
                <Tabs
                  orientation={tabsOrientation}
                  value={tabValue}
                  onChange={handleSetTabValue}
                >
                  <Tab
                    label="Datos personales"
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        filter_1
                      </Icon>
                    }
                  />
                  <Tab
                    label="Datos de locación"
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        filter_2
                      </Icon>
                    }
                  />
                  <Tab
                    label="Datos académicos"
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        filter_3
                      </Icon>
                    }
                  />
                  <Tab
                    label="Datos de salud"
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        filter_4
                      </Icon>
                    }
                  />
                  <Tab
                    label="Antecedentes"
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        filter_5
                      </Icon>
                    }
                  />
                </Tabs>
              </AppBar>
            </Grid>
          </Grid>

          <Formik
            initialValues={initialValues()}
            validationSchema={schemaDecider()}
            validateOnChange={true}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {({ errors, values, handleSubmit, setFieldValue, touched }) => (
              <>
                {tabValue === 0 && (
                  <StudentOnboardingStep1
                    setFieldValue={setFieldValue}
                    errors={errors}
                    values={values}
                    touched={touched}
                    socialNetworksState={socialNetworksState}
                    onChangeSocialNetworks={handleChangeSocialNetworks}
                    isProfilePage={true}
                  ></StudentOnboardingStep1>
                )}
                {tabValue === 1 && (
                  <StudentOnboardingStep2
                    setFieldValue={setFieldValue}
                    errors={errors}
                    values={values}
                    touched={touched}
                  ></StudentOnboardingStep2>
                )}
                {tabValue === 2 && (
                  <StudentOnboardingStep3
                    setFieldValue={setFieldValue}
                    errors={errors}
                    values={values}
                    touched={touched}
                  ></StudentOnboardingStep3>
                )}
                {tabValue === 3 && (
                  <StudentOnboardingStep4
                    setFieldValue={setFieldValue}
                    errors={errors}
                    values={values}
                    touched={touched}
                  ></StudentOnboardingStep4>
                )}
                {tabValue === 4 && (
                  <ProfileStep5
                    studentId={studentData?.id}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    values={values}
                    touched={touched}
                    experiences={experiences}
                    onSetExperience={setExperiences}
                    languages={languages}
                    onSetLanguage={setLanguages}
                    studentCertifications={certifications}
                    studentKnowledges={knowledges}
                    onSetKnowledge={setKnowledges}
                    studentSoftSkills={softSkills}
                    onSetSoftSkills={setSoftSkills}
                    onGetStudentData={getStudentData}
                  ></ProfileStep5>
                )}
                <Grid container justifyContent="end" mt={4} mb={1}>
                  <MDButton
                    color="info"
                    size="small"
                    onClick={() => handleSubmit()}
                  >
                    Actualizar datos
                  </MDButton>
                </Grid>
              </>
            )}
          </Formik>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default StudentProfile;
