import React from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox/index";
import MDTypography from "components/MDTypography/index.js";
import { BloodType, CivilStatus, Genders } from "../../enums";
import Chip from "@mui/material/Chip";
import { changeDateFormat } from "../../utils/dashboard";

function StudentInfo({ student }) {
  return (
    <>
      <Grid container spacing={1} mt={2}>
        <Grid item xs={12}>
          <MDTypography variant="h5" color="info">
            Datos personales
          </MDTypography>
        </Grid>

        <Grid item xs={12}>
          <MDTypography variant="h6">CUIL</MDTypography>
          <MDTypography variant="button" color="text">
            {student?.cuil}
          </MDTypography>
        </Grid>

        <Grid item xs={12}>
          <MDTypography variant="h6">Fecha de nacimiento</MDTypography>
          <MDTypography variant="button" color="text">
            {student && changeDateFormat(student.birthdate)}
          </MDTypography>
        </Grid>
        <Grid item xs={12}>
          <MDTypography variant="h6">Teléfono</MDTypography>
          <MDTypography variant="button" color="text">
            {student?.phone}
          </MDTypography>
        </Grid>
        <Grid item xs={12}>
          <MDTypography variant="h6">Género</MDTypography>
          <MDTypography variant="button" color="text">
            {student?.gender && Genders[student.gender]}
          </MDTypography>
        </Grid>
        <Grid item xs={12}>
          <MDTypography variant="h6">Estado civil</MDTypography>
          <MDTypography variant="button" color="text">
            {student?.civilStatus && CivilStatus[student.civilStatus]}
          </MDTypography>
        </Grid>
        <Grid item xs={12}>
          <MDTypography variant="h6">Redes sociales</MDTypography>
          <Grid item container xs={12} spacing={1}>
            {student?.socialNetworks.map((socialNetwork) => {
              return (
                <Grid item key={socialNetwork}>
                  <Chip label={socialNetwork} variant="filled" />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1} mt={2}>
        <Grid item xs={12}>
          <MDTypography variant="h5" color="info">
            Datos de locación
          </MDTypography>
        </Grid>
        <Grid item xs={12}>
          <MDTypography variant="h6">Domicilio</MDTypography>
          <MDTypography variant="button" color="text">
            {student?.address}
          </MDTypography>
        </Grid>
        <Grid item xs={12}>
          <MDTypography variant="h6">Es propietario</MDTypography>
          <MDTypography variant="button" color="text">
            {student?.isOwner ? "Si" : "No"}
          </MDTypography>
        </Grid>
        <Grid item xs={12}>
          <MDTypography variant="h6">Localidad</MDTypography>
          <MDTypography variant="button" color="text">
            {student?.location.city}
          </MDTypography>
        </Grid>
        <Grid item xs={12}>
          <MDTypography variant="h6">Provincia</MDTypography>
          <MDTypography variant="button" color="text">
            {student?.location.province}
          </MDTypography>
        </Grid>
        <Grid item xs={12}>
          <MDTypography variant="h6">Código Postal</MDTypography>
          <MDTypography variant="button" color="text">
            {student?.location.postalCode}
          </MDTypography>
        </Grid>
      </Grid>
      <Grid container spacing={1} mt={2}>
        <Grid item xs={12}>
          <MDTypography variant="h5" color="info">
            Datos académicos de nivel medio
          </MDTypography>
        </Grid>
        <Grid item xs={12}>
          <MDTypography variant="h6">
            Entidad estudiantil de nivel medio
          </MDTypography>
          <MDTypography variant="button" color="text">
            {student?.highSchool.name}
          </MDTypography>
        </Grid>
        <Grid item xs={12}>
          <MDTypography variant="h6">Titulo de nivel medio</MDTypography>
          <MDTypography variant="button" color="text">
            {student?.highSchoolDegree}
          </MDTypography>
        </Grid>
        <Grid item xs={12}>
          <MDTypography variant="h6">Localidad</MDTypography>
          <MDTypography variant="button" color="text">
            {student?.highSchool.location.city}
          </MDTypography>
        </Grid>
        <Grid item xs={12}>
          <MDTypography variant="h6">Provincia</MDTypography>
          <MDTypography variant="button" color="text">
            {student?.highSchool.location.province}
          </MDTypography>
        </Grid>
        <Grid item xs={12}>
          <MDTypography variant="h6">Código Postal</MDTypography>
          <MDTypography variant="button" color="text">
            {student?.highSchool.location.postalCode}
          </MDTypography>
        </Grid>
      </Grid>
      <Grid container spacing={1} mt={2}>
        <Grid item xs={12}>
          <MDTypography variant="h5" color="info">
            Datos académicos de nivel superior
          </MDTypography>
        </Grid>
        <Grid item xs={12}>
          <MDTypography variant="h6">Legajo</MDTypography>
          <MDTypography variant="button" color="text">
            {student?.id}
          </MDTypography>
        </Grid>
        <Grid item xs={12}>
          <MDTypography variant="h6">Carrera de grado en curso</MDTypography>
          <MDTypography variant="button" color="text">
            {student?.career.name}
          </MDTypography>
        </Grid>
        <Grid item xs={12}>
          <MDTypography variant="h6">
            Cantidad de materias aprobadas
          </MDTypography>
          <MDTypography variant="button" color="text">
            {student?.approvedSubjectsAmount}
          </MDTypography>
        </Grid>
        <Grid item xs={12}>
          <MDTypography variant="h6">Año de ingreso</MDTypography>
          <MDTypography variant="button" color="text">
            {student?.startYear}
          </MDTypography>
        </Grid>
        <Grid item xs={12}>
          <MDTypography variant="h6">Año de curso actual</MDTypography>
          <MDTypography variant="button" color="text">
            {student?.currentCareerYear}
          </MDTypography>
        </Grid>
      </Grid>
      <Grid container spacing={1} mt={2}>
        <Grid item xs={12}>
          <MDTypography variant="h5" color="info">
            Datos de salud
          </MDTypography>
        </Grid>
        <Grid item xs={12}>
          <MDTypography variant="h6">Tiene obra social</MDTypography>
          <MDTypography variant="button" color="text">
            {student?.hasHealthInsurance ? "Si" : "No"}
          </MDTypography>
        </Grid>
        {student?.hasHealthInsurance && (
          <Grid item xs={12}>
            <MDTypography variant="h6">Obra social</MDTypography>
            <MDTypography variant="button" color="text">
              {student?.healthInsurance.name}
            </MDTypography>
          </Grid>
        )}
        <Grid item xs={12}>
          <MDTypography variant="h6">Grupo sanguíneo</MDTypography>
          <MDTypography variant="button" color="text">
            {student?.bloodType && BloodType[student.bloodType]}
          </MDTypography>
        </Grid>
        <Grid item xs={12}>
          <MDTypography variant="h6">Es donante de sangre</MDTypography>
          <MDTypography variant="button" color="text">
            {student?.bloodDonor ? "Si" : "No"}
          </MDTypography>
        </Grid>
      </Grid>
      <Grid container spacing={1} mt={2}>
        <Grid item xs={12}>
          <MDTypography variant="h5" color="info">
            Información laboral
          </MDTypography>
        </Grid>
        <Grid item xs={12}>
          <MDTypography variant="h6">Antecedentes laborales</MDTypography>
          <Grid item xs={6}>
            {student?.experiences.length > 0
              ? student?.experiences.map((experience, index) => (
                  <MDBox
                    component="li"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    bgColor={"#250b4617"}
                    borderRadius="lg"
                    p={3}
                    mb={0}
                    mt={2}
                    key={index}
                  >
                    <Grid item xs={12}>
                      <MDBox mb={1} lineHeight={0}>
                        <MDTypography variant="caption" fontWeight="medium">
                          Empresa:&nbsp;&nbsp;&nbsp;
                          <MDTypography
                            variant="caption"
                            textTransform="capitalize"
                          >
                            {experience.enterprise}
                          </MDTypography>
                        </MDTypography>
                      </MDBox>
                      <MDBox lineHeight={0}>
                        <MDTypography variant="caption" fontWeight="medium">
                          Cargo:&nbsp;&nbsp;&nbsp;
                          <MDTypography variant="caption">
                            {experience.position}
                          </MDTypography>
                        </MDTypography>
                      </MDBox>
                      <MDTypography variant="caption" fontWeight="medium">
                        Período:&nbsp;&nbsp;&nbsp;
                        <MDTypography variant="caption">
                          {changeDateFormat(experience.startDate)} -{" "}
                          {changeDateFormat(experience.endDate)}
                        </MDTypography>
                      </MDTypography>
                      <MDBox lineHeight={0}>
                        <MDTypography variant="caption" fontWeight="medium">
                          Referente:&nbsp;&nbsp;&nbsp;
                          <MDTypography variant="caption">
                            {experience.referralName +
                              " - Teléfono: " +
                              experience.referralPhone}
                          </MDTypography>
                        </MDTypography>
                      </MDBox>
                      <MDBox mt={1} lineHeight={0}>
                        <MDTypography variant="caption" fontWeight="medium">
                          Tareas:&nbsp;&nbsp;&nbsp;
                        </MDTypography>
                        <MDBox ml={3} mt={1} lineHeight={0}>
                          <MDTypography variant="caption">
                            <ul>
                              {experience.tasks.map((task, index) => (
                                <li key={`task-${index}`}>{task.task.name}</li>
                              ))}
                            </ul>
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                  </MDBox>
                ))
              : null}
          </Grid>
        </Grid>
        <Grid item xs={12} mt={2}>
          <MDTypography variant="h6">Idiomas</MDTypography>
          <Grid item xs={6}>
            {student?.languages.length > 0
              ? student?.languages.map((language, index) => (
                  <MDBox
                    component="li"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    bgColor={"#250b4617"}
                    borderRadius="lg"
                    p={3}
                    mb={0}
                    mt={2}
                    key={index}
                  >
                    <MDTypography variant="caption" fontWeight="medium">
                      {language.language.name} - {language.language.level}
                    </MDTypography>
                  </MDBox>
                ))
              : null}
          </Grid>
        </Grid>
        <Grid item xs={12} mt={2}>
          <MDTypography variant="h6">Cursos y seminarios</MDTypography>
          <Grid item xs={9}>
            {student?.informalEducations.length > 0
              ? student?.informalEducations.map((informalEducation, index) => (
                  <MDBox
                    component="li"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    bgColor={"#250b4617"}
                    borderRadius="lg"
                    p={3}
                    mb={0}
                    mt={2}
                    key={index}
                  >
                    <Grid item xs={12} md={10}>
                      <MDTypography variant="caption" fontWeight="medium">
                        Curso:&nbsp;&nbsp;&nbsp;
                        <MDTypography variant="caption">
                          {informalEducation.name} - {informalEducation.year} -
                          {informalEducation.hasCertificate
                            ? "Tiene certificado"
                            : "No tiene certificado"}
                        </MDTypography>
                      </MDTypography>
                    </Grid>
                  </MDBox>
                ))
              : null}
          </Grid>
        </Grid>
        <Grid item xs={12} mt={2}>
          <MDTypography variant="h6">Conocimientos</MDTypography>
          <Grid item container spacing={1} mt={1}>
            {student?.knowledges.map((knowledge) => {
              return (
                <Grid item key={knowledge.knowledge.subCategory}>
                  <Chip
                    label={knowledge.knowledge.subCategory}
                    variant="filled"
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={12} mt={2}>
          <MDTypography variant="h6">Habilidades blandas</MDTypography>
          <Grid item container spacing={1} mt={1}>
            {student?.skills.map((skill) => {
              return (
                <Grid item key={skill.skill.name}>
                  <Chip label={skill.skill.name} variant="filled" />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={12} mt={2}>
          <MDTypography variant="h6">Motivación personal</MDTypography>
          <MDTypography variant="button" color="text">
            {student?.personalMotivation}
          </MDTypography>
        </Grid>
      </Grid>
    </>
  );
}

export default StudentInfo;
