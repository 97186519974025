import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Input from "components/molecules/input/input";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Auth from "../../../services/api/auth";
import { Formik } from "formik";
import MDSnackbar from "../../../components/MDSnackbar";
import { RecoverPasswordSchema } from "helpers/schemas/authSchemas";
import useAPIError from "../../../hooks";
import { useState } from "react";

function Cover() {
  const [openNotification, setOpenNotification] = useState(false);
  const { addError } = useAPIError();

  const onRecoverPassword = async (values) => {
    const response = await Auth.recoverPassword({ email: values.email });
    if (response.status === 201) {
      setOpenNotification(true);
    } else {
      addError(`${response.data.message}`, response.status);
    }
  };
  return (
    <BasicLayout>
      <MDSnackbar
        icon="notifications"
        color="success"
        title="Se ha enviado un email a tu casilla de correo"
        open={openNotification}
        close={() => setOpenNotification(false)}
      />
      <MDBox
        bgColor="white"
        borderRadius="lg"
        mx={2}
        mt={-3}
        pr={15}
        pl={0}
        textAlign="center"
      >
        <Grid container>
          <Grid item xs={12}>
            <MDTypography variant="h4" fontWeight="medium" color="dark" mt={1}>
              Cambiar contraseña
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6" color="dark" mt={1}>
              Recibirás un mail para poder cambiar tu contraseña
            </MDTypography>
          </Grid>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={RecoverPasswordSchema}
            validateOnChange={true}
            onSubmit={onRecoverPassword}
          >
            {({ errors, setFieldValue, handleSubmit, touched }) => (
              <Grid item xs={12} mt={4}>
                <Input
                  type={"email"}
                  label={"Email"}
                  onChange={(e) => setFieldValue("email", e.target.value)}
                  error={!!errors.email && touched.email}
                  errorText={errors.email}
                  fullWidth
                />
                <MDBox mb={1}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Recuperar contraseña
                  </MDButton>
                </MDBox>
              </Grid>
            )}
          </Formik>
        </Grid>
      </MDBox>
    </BasicLayout>
  );
}

export default Cover;
