import {useState, useEffect} from "react";
import { Formik } from "formik";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import MDTypography from "components/MDTypography";
import { Link } from "react-router-dom";
import MDButton from "components/MDButton";
import CompanyOnboardingStep1 from "./steps/companyOnboardingStep1";
import CompanyOnboardingStep2 from "./steps/companyOnboardingStep2";
import CompanyOnboardingStep3 from "./steps/companyOnboardingStep3";
import { CompanyOnboardingStep1Schema } from "helpers/schemas/onboardingSchemas";
import { CompanyOnboardingStep2Schema } from "helpers/schemas/onboardingSchemas";
import companyOnboarding from "../../../services/api/company/companyOnboarding";
import { isMobile } from "../../../utils/isMobile";
import useAPIError from "../../../hooks";
import CompanyService from "../../../services/api/company/companyService";


const steps = [
  "Datos fiscales",
  "Datos de locación y contacto",
  "Representante/s de la empresa",
];

function CompanyOnboarding() {
  const [activeStep, setActiveStep] = useState(0);
  const [representatives, setRepresentatives] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [companyData, setCompanyData] = useState();
  
  const getCompanyData = async () => {
    const response = await CompanyService.getProfile();
    setCompanyData(response.data);

    if (response.data.onboardingStep) {
      setActiveStep(response.data.onboardingStep - 1);
    }
  };

  useEffect(() => {
    getCompanyData();
  }, []);

  const { addError } = useAPIError();

  const onSubmit = async (values) => {
    const formData = new FormData();
    setIsSubmitting(true);

    if (activeStep === 0) {
      formData.append("legalPersonType", values.legalPersonType);
      formData.append("taxStatus", values.taxStatus);
      formData.append("companyPurpose", values.companyPurpose);
      formData.append("file", values.file);

      const res1 = await companyOnboarding.step1(formData);
      if (res1.status === 201) {
        handleNext();
      } else {
        addError(`${res1.data.message}`, res1.status);
      }
    }
    if (activeStep === 1) {
      const res2 = await companyOnboarding.step2({
        realAddress: values.realAddress,
        legalAddress: values.legalAddress,
        phone: values.phone,
        whatsapp: values.whatsapp,
        companyEmail: values.companyEmail,
        website: values.website,
        location: {
          city: values.city,
          province: values.province,
          postalCode: values.postalCode,
        },
      });
      if (res2.status === 200) {
        handleNext();
      } else {
        addError(`${res2.data.message}`, res2.status);
      }
    }
    if (activeStep === 2) {
      if(representatives.length < 2) {
        addError("Debe agregar al menos 2 representantes", 400);
        setIsSubmitting(false);
        return;
      }
      const positionFiles = [];
      const dniFiles = [];
      const rep = representatives.map((rep) => {
        positionFiles.push(rep.positionFile);
        dniFiles.push(rep.dniFile);
        return {
          name: rep.name,
          surname: rep.surname,
          cuit: rep.cuit,
          address: rep.address,
          position: rep.position,
          positionFile: rep.positionFile.name.replace(".pdf", ""),
          dniFile: rep.dniFile.name.replace(".pdf", ""),
        };
      });
      rep.map((representative, index) => {
        formData.append("representatives", JSON.stringify(representative));
        formData.append("positionFile", positionFiles[index]);
        formData.append("dniFile", dniFiles[index]);
      });
      const res3 = await companyOnboarding.step3(formData);

      if (res3.status === 201) {
        handleNext();
      } else {
        addError(`${res3.data.message}`, res3.status);
      }
    }
     setIsSubmitting(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const initialValues = () => {
    return {
      legalPersonType: companyData?.legalPersonType || "",
      taxStatus: companyData?.taxStatus || "",
      companyPurpose: companyData?.companyPurpose || "",
      file: "",
      realAddress: companyData?.realAddress || "",
      legalAddress: companyData?.legalAddress || "",
      city: companyData?.location ? companyData.location.city : "",
      province: companyData?.location ? companyData?.location.province : "",
      postalCode: companyData?.location ? companyData?.location.postalCode : "",
      phone: companyData?.phone || "",
      whatsapp: companyData?.whatsapp || "",
      website: companyData?.website || "",
      companyEmail: companyData?.companyEmail || "",
    };
  };


  const schemaDecider = () => {
    if (activeStep === 0) {
      return CompanyOnboardingStep1Schema;
    }
    if (activeStep === 1) {
      return CompanyOnboardingStep2Schema;
    }
  };

  return (
    <Formik
      initialValues={initialValues()}
      validationSchema={schemaDecider()}
      validateOnChange={false}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ errors, values, setFieldValue, handleSubmit, touched }) => (
        <Box p={3}>
          {!isMobile() && (
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                return (
                  <Step key={label} style={{ padding: "0 2rem 0.5rem" }}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          )}

          {activeStep === steps.length ? (
            <>
              <MDTypography
                variant="h4"
                fontWeight="medium"
                color="dark"
                mt={5}
                mb={2}
                textAlign="center"
              >
                Su registro ha sido finalizado
              </MDTypography>
              <Box
                sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                justifyContent="center"
              >
                <MDButton
                  variant="gradient"
                  color="success"
                  component={Link}
                  to="/company/dashboard"
                >
                  Ir al dashboard
                </MDButton>
              </Box>
            </>
          ) : (
            <>
              {activeStep === 0 ? (
                <CompanyOnboardingStep1
                  setFieldValue={setFieldValue}
                  errors={errors}
                  values={values}
                  touched={touched}
                ></CompanyOnboardingStep1>
              ) : null}
              {activeStep === 1 ? (
                <CompanyOnboardingStep2
                  setFieldValue={setFieldValue}
                  errors={errors}
                  values={values}
                  touched={touched}
                ></CompanyOnboardingStep2>
              ) : null}
              {activeStep === 2 ? (
                <CompanyOnboardingStep3
                  onSetRepresentative={setRepresentatives}
                />
              ) : null}
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <MDButton
                  variant="text"
                  color="info"
                  size="small"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >
                  Anterior
                </MDButton>
                <Box sx={{ flex: "1 1 auto" }} />
                {activeStep === steps.length - 1 ? (
                  <MDButton
                    color="info"
                    size="small"
                    disabled={isSubmitting}
                    onClick={() => handleSubmit()}
                  >
                    Finalizar
                  </MDButton>
                ) : (
                  <MDButton
                    variant="outlined"
                    color="info"
                    size="small"
                    disabled={isSubmitting}
                    onClick={() => handleSubmit()}
                  >
                    Siguiente
                  </MDButton>
                )}
              </Box>
            </>
          )}
        </Box>
      )}
    </Formik>
  );
}

export default CompanyOnboarding;
