import Api from "../api";

class StudentOnboarding {
  async step1(body) {
    let response;
    try {
      response = await Api.put("student/onboarding-step-1", body);
    } catch (err) {
      response = err;
    }
    return response;
  }
  async step2(body) {
    let response;
    try {
      response = await Api.put("student/onboarding-step-2", body);
    } catch (err) {
      response = err;
    }
    return response;
  }
  async step3(body) {
    let response;
    try {
      response = await Api.put("student/onboarding-step-3", body);
    } catch (err) {
      response = err;
    }
    return response;
  }
  async step4(body) {
    let response;
    try {
      response = await Api.put("student/onboarding-step-4", body);
    } catch (err) {
      response = err;
    }
    return response;
  }
  async step5(body) {
    let response;
    try {
      response = await Api.put("student/onboarding-step-5", body);
    } catch (err) {
      response = err;
    }
    return response;
  }
}

export default new StudentOnboarding();
