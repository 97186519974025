import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import AdminService from "../../services/api/admin/adminService";
import CustomModal from "../../components/molecules/modal/modal";
import DataTable from "../../examples/Tables/DataTable";

function Companies() {
  const [openModal, setOpenModal] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [currentCompany, setCurrentCompany] = useState(null);

  useEffect(() => {
    getCompanies();
  }, []);

  const getCompanies = async () => {
    const response = await AdminService.getCompanies();

    setCompanies(response.data);
  };

  const handleGetCompany = async (companyId) => {
    const response = await AdminService.getCompany(companyId);
    setCurrentCompany(response.data);
    setOpenModal(true);
  };

  const columns = [
    { Header: "Nombre", accessor: "name", align: "left" },
    { Header: "Dirección", accessor: "address", align: "center" },
    { Header: "Acciones", accessor: "action", align: "center" },
  ];

  const rows = companies.map((company) => {
    return {
      name: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {company.name}
        </MDTypography>
      ),
      address: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {company.legalAddress}
        </MDTypography>
      ),
      action:
        company.onboardingStep < 3 ? (
          <MDTypography
            variant="caption"
            color="warning"
            fontWeight="medium"
          >
            Sin finalizar registro
          </MDTypography>
        ) : (
          <MDTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            onClick={() => handleGetCompany(company.id)}
          >
            Ver más
          </MDTypography>
        ),
    };
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDTypography variant="h3">Empresas registradas</MDTypography>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <MDBox pt={2} pb={3}>
                {companies.length > 0 ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                ) : (
                  <MDTypography variant="caption" ml={3}>
                    Aún no hay empresas registradas
                  </MDTypography>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <CustomModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="company-info-modal"
      >
        <MDTypography variant="h2" color="info">
          {currentCompany?.name}
        </MDTypography>
        <Grid container spacing={1} mt={2}>
          <Grid item xs={12}>
            <MDTypography variant="h5" color="info">
              Datos fiscales
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Tipo de persona jurídica</MDTypography>
            <MDTypography variant="button" color="text">
              {currentCompany?.legalPersonType}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Condición fiscal</MDTypography>
            <MDTypography variant="button" color="text">
              {currentCompany?.taxStatus}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Rubro de la razón social</MDTypography>
            <MDTypography variant="button" color="text">
              {currentCompany?.companyPurpose}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">
              Constancia de inscripción de AFIP
            </MDTypography>
            <MDTypography
              component="a"
              href={currentCompany?.afipFile}
              target="_blank"
              variant="caption"
              color="primary"
              fontWeight="medium"
            >
              Ver Constancia AFIP
            </MDTypography>
          </Grid>
        </Grid>
        <Grid container spacing={1} mt={2}>
          <Grid item xs={12}>
            <MDTypography variant="h5" color="info">
              Datos de locación y contacto
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">
              Domicilio real de la empresa
            </MDTypography>
            <MDTypography variant="button" color="text">
              {currentCompany?.realAddress}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">
              Domicilio legal de la empresa
            </MDTypography>
            <MDTypography variant="button" color="text">
              {currentCompany?.legalAddress}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Localidad</MDTypography>
            <MDTypography variant="button" color="text">
              {currentCompany?.location.city}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Provincia</MDTypography>
            <MDTypography variant="button" color="text">
              {currentCompany?.location.province}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Código postal</MDTypography>
            <MDTypography variant="button" color="text">
              {currentCompany?.location.postalCode}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Teléfono</MDTypography>
            <MDTypography variant="button" color="text">
              {currentCompany?.phone}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Whatsapp</MDTypography>
            <MDTypography variant="button" color="text">
              {currentCompany?.whatsapp}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Sitio Web</MDTypography>
            <MDTypography variant="button" color="text">
              {currentCompany?.website}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Email de la empresa</MDTypography>
            <MDTypography variant="button" color="text">
              {currentCompany?.companyEmail}
            </MDTypography>
          </Grid>
        </Grid>
        <Grid container spacing={1} mt={2}>
          <Grid item xs={12}>
            <MDTypography variant="h5" color="info">
              Representantes
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            {currentCompany?.representatives.length > 0
              ? currentCompany.representatives.map((representative) => (
                  <MDBox
                    component="li"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    bgColor={"grey-300"}
                    borderRadius="lg"
                    p={3}
                    mb={0}
                    mt={2}
                    key={representative.name}
                  >
                    <MDBox width="100%" display="flex" flexDirection="column">
                      <MDTypography
                        variant="button"
                        fontWeight="medium"
                        textTransform="capitalize"
                      >
                        {representative.name}&nbsp;
                        {representative.surname}
                      </MDTypography>

                      <MDBox mb={1} lineHeight={0}>
                        <MDTypography variant="caption" color="text">
                          CUIT Nº:&nbsp;&nbsp;&nbsp;
                          <MDTypography
                            variant="caption"
                            fontWeight="medium"
                            textTransform="capitalize"
                          >
                            {representative.cuit}
                          </MDTypography>
                        </MDTypography>
                      </MDBox>
                      <MDBox mb={1} lineHeight={0}>
                        <MDTypography variant="caption" color="text">
                          Domicilio:&nbsp;&nbsp;&nbsp;
                          <MDTypography variant="caption" fontWeight="medium">
                            {representative.address}
                          </MDTypography>
                        </MDTypography>
                      </MDBox>
                      <MDTypography variant="caption" color="text">
                        Ocupación:&nbsp;&nbsp;&nbsp;
                        <MDTypography variant="caption" fontWeight="medium">
                          {representative.position}
                        </MDTypography>
                      </MDTypography>
                      <MDTypography variant="caption" color="text">
                        DNI:&nbsp;&nbsp;&nbsp;
                        <MDTypography
                          component="a"
                          href={representative.dniFile}
                          target="_blank"
                          variant="caption"
                          color="primary"
                          fontWeight="medium"
                        >
                          Ver PDF
                        </MDTypography>
                      </MDTypography>
                      <MDTypography variant="caption" color="text">
                        Ocupación:&nbsp;&nbsp;&nbsp;
                        <MDTypography
                          component="a"
                          href={representative.positionFile}
                          target="_blank"
                          variant="caption"
                          color="primary"
                          fontWeight="medium"
                        >
                          Ver PDF
                        </MDTypography>
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                ))
              : null}
          </Grid>
        </Grid>
      </CustomModal>
    </DashboardLayout>
  );
}

export default Companies;
