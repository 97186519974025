import MDSnackbar from "../../MDSnackbar";
import useAPIError from "../../../hooks";

function ErrorNotification() {
  const { error, removeError } = useAPIError();

  const handleSubmit = () => {
    removeError();
  };

  return (
    <MDSnackbar
      icon="error"
      title={"Error"}
      content={error && error.message}
      open={!!error}
      color="error"
      dateTime=""
      close={() => handleSubmit()}
    />
  );
}

export default ErrorNotification;
