import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBadge from "../../components/MDBadge";
import React from "react";
import authStorage from "../../services/localStorage/authStorage";

function StudentsList({
  students,
  isCompany,
  opportunityId,
  onValidateStudent,
}) {
  const renderAppliedStudents = students?.map(
    ({ student, finalPercentage, hasApplied, isValidated, id }) => (
      <MDBox
        key={student.id}
        component="li"
        display="flex"
        alignItems="center"
        py={1}
        mb={1}
      >
        <MDBox
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
        >
          <MDTypography variant="button" fontWeight="medium">
            {student.name + " " + student.surname}
            {!isCompany && finalPercentage && (
              <MDBadge
                badgeContent={`aplica un ${finalPercentage}%`}
                color="warning"
                variant="contained"
                size="md"
              />
            )}
            {!isCompany && hasApplied && (
              <MDBadge
                badgeContent="Ha aplicado a la oportunidad"
                color="success"
                variant="contained"
                size="md"
              />
            )}
          </MDTypography>
          <MDTypography variant="caption" color="text" mt={1}>
            <b>Carrera:</b> {student.career.name}
          </MDTypography>
        </MDBox>
        <MDBox ml="auto">
          {authStorage.getSessionUserRole() === "admin" && (
            <>
              {isValidated && isValidated === true ? (
                <MDTypography variant="button" color="success" mt={1}>
                  <strong>VALIDADO</strong>
                </MDTypography>
              ) : (
                <MDButton
                  size={"small"}
                  color={"dark"}
                  onClick={() => onValidateStudent(student.id, id)}
                >
                  Validar
                </MDButton>
              )}
            </>
          )}
          {!isCompany && (
            <MDButton
              component="a"
              href={`/admin/student-information?id=${student.id}&opportunityId=${opportunityId}`}
              target="_blank"
              rel="noreferrer"
              variant="text"
              color={"dark"}
            >
              Más información
            </MDButton>
          )}
        </MDBox>
      </MDBox>
    )
  );

  return (
    <Card sx={{ height: "100%", boxShadow: false }}>
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {renderAppliedStudents}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default StudentsList;
