import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBadge from "components/MDBadge";
import CustomModal from "../../../components/molecules/modal/modal";
import studentService from "../../../services/api/student/studentService";
import DataTable from "../../../examples/Tables/DataTable";
import {
  changeDateFormat,
  isHigherDateThanCurrentDate,
  sortArrayByCurrentDate,
} from "../../../utils/dashboard";

const columns = [
  { Header: "Fecha", accessor: "date", align: "left" },
  { Header: "Empresa", accessor: "company", align: "left" },
  {
    Header: "Duración",
    accessor: "duration",
    align: "center",
  },
  { Header: "Tipo", accessor: "type", align: "center" },
  { Header: "Estado", accessor: "status", align: "center" },
  { Header: "Acciones", accessor: "action", align: "center" },
];

function Agreements() {
  const [openModal, setOpenModal] = useState(false);
  const [agreements, setAgreements] = useState([]);
  const [currentAgreement, setCurrentAgreement] = useState(null);

  useEffect(() => {
    getAgreements();
  }, []);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const getAgreements = async () => {
    const response = await studentService.getAgreements();

    setAgreements(sortArrayByCurrentDate(response.data));
  };

  const handleOpenAgreement = async (id) => {
    const agreement = agreements.find((agreement) => agreement.id === id);
    setCurrentAgreement(agreement);
    setOpenModal(true);
  };

  const rows = agreements.map((agreement) => {
    return {
      date: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {changeDateFormat(agreement.date)}
        </MDTypography>
      ),
      company: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {agreement.company.name}
        </MDTypography>
      ),
      duration: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {agreement.duration} meses
        </MDTypography>
      ),
      type: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent={
              agreement.internship ? "pasantia" : "empleo efectivo"
            }
            color={"info"}
            variant="gradient"
            size="sm"
          />
        </MDBox>
      ),
      status: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent={
              isHigherDateThanCurrentDate(agreement.endDate)
                ? "en curso"
                : "finalizado"
            }
            color={
              isHigherDateThanCurrentDate(agreement.endDate)
                ? "success"
                : "error"
            }
            variant="gradient"
            size="sm"
          />
        </MDBox>
      ),
      action: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
          onClick={() => handleOpenAgreement(agreement.id)}
        >
          Ver convenio
        </MDTypography>
      ),
    };
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDTypography variant="h3">
              Convenios de pasantía / empleo efectivo
            </MDTypography>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <MDBox pt={2} pb={3}>
                {agreements.length > 0 ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                ) : (
                  <MDTypography variant="caption" ml={3}>
                    Aún no hay convenios
                  </MDTypography>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <CustomModal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="student-agreement-info-modal"
      >
        <MDTypography variant="h3" color="info">
          Convenio - Empresa {currentAgreement?.company.name}
        </MDTypography>
        <Grid container spacing={1} mt={2}>
          {currentAgreement?.extension &&
            currentAgreement.extensionStatus === "accepted" && (
              <MDBadge
                badgeContent={`Extensión de convenio: (${currentAgreement?.extension} meses)`}
                color={"success"}
                variant="gradient"
                size="sm"
              />
            )}
          <Grid item xs={12}>
            <MDTypography variant="h5" color="info">
              Datos convenio
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Duración</MDTypography>
            <MDTypography variant="button" color="text">
              {currentAgreement?.duration} meses
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Fecha inicio</MDTypography>
            <MDTypography variant="button" color="text">
              {currentAgreement &&
                changeDateFormat(currentAgreement?.startDate)}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Fecha fin</MDTypography>
            <MDTypography variant="button" color="text">
              {currentAgreement && changeDateFormat(currentAgreement?.endDate)}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Días de trabajo</MDTypography>
            <MDTypography variant="button" color="text">
              {currentAgreement?.laborDays}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Horas semanales</MDTypography>
            <MDTypography variant="button" color="text">
              {currentAgreement?.weeklyHours}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Hora inicio</MDTypography>
            <MDTypography variant="button" color="text">
              {currentAgreement?.startHour}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Hora fin</MDTypography>
            <MDTypography variant="button" color="text">
              {currentAgreement?.endHour}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Domicilio trabajo</MDTypography>
            <MDTypography variant="button" color="text">
              {currentAgreement?.workAddress}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Provincia trabajo</MDTypography>
            <MDTypography variant="button" color="text">
              {currentAgreement?.province}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Localidad trabajo</MDTypography>
            <MDTypography variant="button" color="text">
              {currentAgreement?.city}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Salario</MDTypography>
            <MDTypography variant="button" color="text">
              {currentAgreement?.salary}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Tutor Empresa</MDTypography>
            <MDTypography variant="button" color="text">
              {currentAgreement?.companyTutorName}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">DNI Tutor Empresa</MDTypography>
            <MDTypography variant="button" color="text">
              {currentAgreement?.companyTutorDni}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Tareas a desempeñar</MDTypography>
            <Grid item container ml={3} xs={12}>
              <ul>
                {currentAgreement?.jobOpportunity.tasks.map((task) => {
                  return (
                    <li key={task.task.name}>
                      <MDTypography variant="button">
                        {task.task.name}
                      </MDTypography>
                    </li>
                  );
                })}
              </ul>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Área de trabajo</MDTypography>
            <MDTypography variant="button" color="text">
              {currentAgreement?.jobOpportunity.area.name}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Tipo</MDTypography>
            <MDTypography variant="button" color="text">
              {currentAgreement?.isInternship ? "Pasantía" : "Empleo efectivo"}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h5" color="info" mt={2}>
              Datos Profesor Tutor
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Nombre</MDTypography>
            <MDTypography variant="button" color="text">
              {currentAgreement?.teacher.name}{" "}
              {currentAgreement?.teacher.surname}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Email</MDTypography>
            <MDTypography variant="button" color="text">
              {currentAgreement?.teacher.email}
            </MDTypography>
          </Grid>
        </Grid>
      </CustomModal>
    </DashboardLayout>
  );
}

export default Agreements;
