import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CustomModal from "../../components/molecules/modal/modal";
import DataTable from "../../examples/Tables/DataTable";
import MDButton from "../../components/MDButton";
import AddIcon from "@mui/icons-material/Add";
import { Formik } from "formik";
import { addTeacherSchema } from "../../helpers/schemas/adminSchemas";
import Input from "../../components/molecules/input/input";
import adminService from "services/api/admin/adminService";
import AdminService from "services/api/admin/adminService";
import Modal from "@mui/material/Modal";
import MDSnackbar from "../../components/MDSnackbar";
import useAPIError from "../../hooks";

const columns = [
  { Header: "Legajo", accessor: "id", align: "left" },
  { Header: "Cuil", accessor: "cuil", align: "left" },
  { Header: "Nombre", accessor: "name", align: "left" },
  { Header: "Email", accessor: "email", align: "center" },
  { Header: "Acciones", accessor: "action", align: "center" },
];

function Teachers() {
  const [openModal, setOpenModal] = useState(false);

  const [isEditModal, setIsEditModal] = useState(false);
  const [teachers, setTeachers] = useState([]);
  const [currentTeacher, setCurrentTeacher] = useState(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openNotification, setOpenNotification] = useState({
    open: false,
    title: "",
  });
  const { addError } = useAPIError();

  useEffect(() => {
    getTeachers();
  }, []);

  const getTeachers = async () => {
    const response = await adminService.getTeachers();

    setTeachers(response.data);
  };

  const rows = teachers.map((teacher) => {
    return {
        id: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {teacher.id}
            </MDTypography>
          ),
      cuil: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {teacher.cuil}
        </MDTypography>
      ),
      name: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {teacher.name + " " + teacher.surname}
        </MDTypography>
      ),
      email: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {teacher.email}
        </MDTypography>
      ),
      action: (
        <Grid container spacing={2}>
          <Grid item>
            <MDTypography
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              onClick={() => onEditTeacher(teacher.id)}
            >
              Ver tutor
            </MDTypography>
          </Grid>
        </Grid>
      ),
    };
  });

  const onEditTeacher = (id) => {
    setIsEditModal(true);
    const teacher = teachers.find((teacher) => teacher.id === id);
    setCurrentTeacher(teacher);
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setIsEditModal(false);
  };

  const initialValuesDecider = () => {
    if (isEditModal) {
      return {
        id: currentTeacher.id,
        cuil: currentTeacher.cuil,
        email: currentTeacher.email,
        name: currentTeacher.name,
        surname: currentTeacher.surname,
      };
    } else {
      return {
        id: '',
        cuil: '',
        email: '',
        name: '',
        surname: '',
      };
    }
  };

  const addTeacher = async (values) => {

    const res = await AdminService.createTeacher(
      values
    );
    if (res.status === 201) {
      handleCloseModal();
      setOpenNotification({
        open: true,
        title: "Tutor creado correctamente",
      });
      await getTeachers();
    } else {
      addError(`Algo ha salido mal`, res.status);
    }
  };

   const deleteTeacher = async (teacherId) => {
    const res = await AdminService.deleteTeacher(teacherId);
    if (res.status === 200) {
      handleCloseModal();
      setOpenConfirmationModal(false);
      setOpenNotification({
        open: true,
        title: "Tutor eliminado correctamente",
      });
      await getTeachers();
    } else {
      addError(`El tutor no pudo ser eliminado debido a que se encuentra relacionado a un convenio`, res.status);
    }
  };

  const updateTeacher = (values) => {
    setIsEditModal(false);
    setOpenModal(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDSnackbar
        icon="notifications"
        title={openNotification.title}
        content=""
        open={openNotification.open}
        color={"success"}
        close={() => setOpenNotification({ open: false, title: "" })}
      />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDTypography variant="h3">Profesores Tutores</MDTypography>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="contained"
                bgColor="dark"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container>
                  <Grid item>
                    <MDButton
                      color="light"
                      variant="outlined"
                      fullWidth
                      onClick={() => setOpenModal(true)}
                    >
                      <AddIcon />
                      &nbsp; Agregar nuevo tutor
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3} pb={3}>
                {teachers.length > 0 ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                ) : (
                  <MDTypography variant="caption" ml={3}>
                    Aún no hay tutores
                  </MDTypography>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <CustomModal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-teachers"
        aria-describedby="modal-modal-teachers"
      >
        <MDTypography mb={2} variant="h5" fontWeight="medium" color="dark">
          {isEditModal
            ? `Tutor ${currentTeacher.name} ${currentTeacher.surname}`
            : "Agregar nuevo tutor"}
        </MDTypography>
        <Formik
          initialValues={initialValuesDecider()}
          validationSchema={addTeacherSchema}
          validateOnChange={true}
          onSubmit={isEditModal ? updateTeacher : addTeacher}
          enableReinitialize={true}
        >
          {({ errors, values, setFieldValue, handleSubmit, touched }) => (
            <Grid container mt={2} mb={2}>
                <Grid item container spacing={1} xs={12} mb={1}>
                <Grid item xs={4}>
                  <Input
                    type={"text"}
                    label={"Legajo"}
                    fullWidth={true}
                    onChange={(e) =>
                      setFieldValue("id", e.target.value)
                    }
                    value={values.id}
                    error={
                      !!errors.id && touched.id
                    }
                    errorText={errors.id}
                    disabled={isEditModal}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Input
                    type={"text"}
                    label={"Nombre"}
                    fullWidth={true}
                    onChange={(e) =>
                      setFieldValue("name", e.target.value)
                    }
                    value={values.name}
                    error={
                      !!errors.name && touched.name
                    }
                    errorText={errors.name}
                    disabled={isEditModal}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Input
                    type={"text"}
                    label={"Apellido"}
                    fullWidth={true}
                    onChange={(e) =>
                      setFieldValue("surname", e.target.value)
                    }
                    value={values.surname}
                    error={
                      !!errors.surname && touched.surname
                    }
                    errorText={errors.surname}
                    disabled={isEditModal}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={1} xs={12} mb={1}>
                <Grid item xs={6}>
                  <Input
                    type={"number"}
                    label={"CUIL"}
                    fullWidth={true}
                    onChange={(e) =>
                      setFieldValue("cuil", e.target.value)
                    }
                    value={values.cuil}
                    error={
                      !!errors.cuil && touched.cuil
                    }
                    errorText={errors.cuil}
                    disabled={isEditModal}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    type={"email"}
                    label={"Email"}
                    fullWidth={true}
                    onChange={(e) =>
                      setFieldValue("email", e.target.value)
                    }
                    value={values.email}
                    error={
                      !!errors.email && touched.email
                    }
                    errorText={errors.email}
                    disabled={isEditModal}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                justifyContent="space-between"
                xs={12}
                mt={4}
              >
                <Grid item>
                  <MDButton
                    variant="text"
                    color="info"
                    size="small"
                    onClick={handleCloseModal}
                  >
                    Cancelar
                  </MDButton>
                </Grid>
                <Grid item>
                  <MDButton
                    color={isEditModal ? "error" : "info"}
                    size="small"
                    onClick={() => {
                      isEditModal
                        ? setOpenConfirmationModal(true)
                        : handleSubmit();
                    }}
                  >
                    {isEditModal ? "Eliminar" : "Agregar"}
                  </MDButton>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Formik>
      </CustomModal>
      <Modal
        open={openConfirmationModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-confirmation"
        aria-describedby="modal-confirmation"
      >
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            p: 4,
          }}
        >
          <MDTypography variant="h5" fontWeight="medium" color="dark" mb={3}>
            Está seguro que desea eliminar el tutor?
          </MDTypography>
          <Grid item container justifyContent="space-between" xs={12}>
            <Grid item>
              <MDButton
                variant="text"
                color="info"
                size="small"
                onClick={() => setOpenConfirmationModal(false)}
              >
                Cancelar
              </MDButton>
            </Grid>
            <Grid item>
              <MDButton
                color="info"
                size="small"
                onClick={() => deleteTeacher(currentTeacher.id)}
              >
                Aceptar
              </MDButton>
            </Grid>
          </Grid>
        </Card>
      </Modal>
    </DashboardLayout>
  );
}

export default Teachers;
