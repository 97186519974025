import Icon from "@mui/material/Icon";
import OnboardingLayout from "../layouts/onboarding/onboardingLayout";
import Dashboard from "../pages/company/dashboard/dashboard";
import Profile from "../pages/company/dashboard/profile";
import JobOffers from "../pages/company/dashboard/jobOffers";
import Agreements from "../pages/company/dashboard/agreements";
import CompanyAccountInfo from "../pages/company/dashboard/account";

const companyRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "company-dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Oportunidades",
    key: "job-offers",
    icon: <Icon fontSize="small">work_history</Icon>,
    route: "/dashboard/job-offers",
    component: <JobOffers />,
  },
  {
    type: "collapse",
    name: "Convenios",
    key: "agreements",
    icon: <Icon fontSize="small">work</Icon>,
    route: "/dashboard/agreements",
    component: <Agreements />,
  },
  {
    type: "collapse",
    name: "Onboarding",
    key: "company-onboarding",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/onboarding",
    component: <OnboardingLayout />,
    hidden: true,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "company-profile",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/profile",
    component: <Profile />,
    hidden: true,
  },
  {
    type: "collapse",
    name: "Account",
    key: "company-account",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/account",
    component: <CompanyAccountInfo />,
    hidden: true,
  },
];

export default companyRoutes;
