import Api from "../api";

class AdminService {
  async getProfile() {
    let response;
    try {
      response = await Api.get("admin/profile");
    } catch (err) {
      response = err;
    }
    return response;
  }

  async updateAdmin(id, body) {
    let response;
    try {
      response = await Api.put(`admin/${id}`, body);
    } catch (err) {
      response = err;
    }
    return response;
  }
  async getJobOffers() {
    let response;
    try {
      response = await Api.get("job-opportunities");
    } catch (err) {
      response = err;
    }
    return response;
  }

  async updateJobOffer(id, body) {
    let response;
    try {
      response = await Api.put(`job-opportunities/${id}`, body);
    } catch (err) {
      response = err;
    }
    return response;
  }

  async validateStudent(id, body) {
    let response;
    try {
      response = await Api.put(
        `job-opportunities/validate-student/${id}`,
        body
      );
    } catch (err) {
      response = err;
    }
    return response;
  }

  async getStudents() {
    let response;
    try {
      response = await Api.get(`student`);
    } catch (err) {
      response = err;
    }
    return response;
  }

  async getTeachers() {
    let response;
    try {
      response = await Api.get(`teachers`);
    } catch (err) {
      response = err;
    }
    return response;
  }

  async createTeacher(body) {
    let response;
    try {
      response = await Api.post(`teachers`, body);
    } catch (err) {
      response = err;
    }
    return response;
  }

  async deleteTeacher(id) {
    let response;
    try {
      response = await Api.delete(`teachers/${id}`, {});
    } catch (err) {
      response = err;
    }
    return response;
  }

  async getStudent(id) {
    let response;
    try {
      response = await Api.get(`student/${id}`);
    } catch (err) {
      response = err;
    }
    return response;
  }

  async getCompanies() {
    let response;
    try {
      response = await Api.get(`company`);
    } catch (err) {
      response = err;
    }
    return response;
  }

  async getCompany(id) {
    let response;
    try {
      response = await Api.get(`company/${id}`);
    } catch (err) {
      response = err;
    }
    return response;
  }

  async createAgreement(body) {
    let response;
    try {
      response = await Api.post(`agreements`, body);
    } catch (err) {
      response = err;
    }
    return response;
  }

  async getAgreements() {
    let response;
    try {
      response = await Api.get(`agreements`);
    } catch (err) {
      response = err;
    }
    return response;
  }

  async deleteAgreement(id) {
    let response;
    try {
      response = await Api.delete(`agreements/${id}`, {});
    } catch (err) {
      response = err;
    }
    return response;
  }
  async acceptAgreementExtension(id) {
    let response;
    try {
      response = await Api.put(`agreements/extension/accept/${id}`);
    } catch (err) {
      response = err;
    }
    return response;
  }

  async rejectAgreementExtension(id) {
    let response;
    try {
      response = await Api.put(`agreements/extension/reject/${id}`);
    } catch (err) {
      response = err;
    }
    return response;
  }
}

export default new AdminService();
