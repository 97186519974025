import { useMemo } from "react";
import PropTypes from "prop-types";
import { Doughnut } from "react-chartjs-2";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import configs from "examples/Charts/DoughnutCharts/DefaultDoughnutChart/configs";
import Grid from "@mui/material/Grid";
import MDBadge from "../../../../components/MDBadge";

function DefaultDoughnutChart({
  icon,
  title,
  description,
  height,
  chart,
  resultPercentage,
  subjectsAmountPercentage,
  careerPercentage,
  areaPercentage,
}) {
  const { data, options } = configs(
    chart.labels || [],
    chart.datasets || {},
    chart.cutout
  );

  const textCenter = {
    id: "textCenter",
    beforeDatasetsDraw(chart, args, options) {
      const { ctx, data } = chart;

      ctx.save();
      ctx.textBaseline = "bottom";
      ctx.textAlign = "center";
      ctx.fillStyle = "#344767";
      ctx.font = "bold 30px Roboto";
      ctx.fillText(
        `${data.datasets[0].data[0]}%`,
        chart.getDatasetMeta(0).data[0].x,
        chart.getDatasetMeta(0).data[0].y
      );
      ctx.restore();
    },
  };

  const printLabels = () => {
    const data = [
      "Resultado",
      "Materias aprobadas",
      "Especialidad",
      "Área de empresa",
    ];
    const captions = [
      "Este valor hace referencia a cómo será el desempeño del estudiante en dicha pasantía. Teniendo en cuenta sus datos personales, académicos, laborales y lo solicitado por la empresa se predice cuánto se adaptará el alumno en dicho trabajo",
      "Este valor representa la diferencia entre las materias aprobadas que tiene y las materias que son requeridas para esta oportunidad, las cuales fueron predecidas",
      "El porcentaje será 15% si la especialidad del estudiante coincide con la especialidad predecida para esta oportunidad, en caso contrario será 0%",
      "El porcentaje será 15% si según los datos del estudiante el área predecida coincide con la de esta oportunidad, en caso contrario será 0%",
    ];
    const dataValues = [
      resultPercentage,
      subjectsAmountPercentage,
      careerPercentage,
      areaPercentage,
    ];
    const colors = ["primary", "secondary", "info", "dark"];
    const percentages = ["50", "20", "15", "15"];
    return data.map((value, index) => {
      return (
        <Grid item xs={12} key={index}>
          <MDBadge
            badgeContent={`${data[index]}: ${dataValues[index]} / ${percentages[index]}%`}
            color={colors[index]}
            variant="contained"
            size="md"
          />
          <br />
          <MDTypography variant="caption" color="text">
            {captions[index]}
          </MDTypography>
        </Grid>
      );
    });
  };

  const renderChart = (
    <MDBox py={2} pr={2} pl={icon.component ? 1 : 2}>
      {title || description ? (
        <MDBox display="flex" px={description ? 1 : 0} pt={description ? 1 : 0}>
          {icon.component && (
            <MDBox
              width="4rem"
              height="4rem"
              bgColor={icon.color || "info"}
              variant="gradient"
              coloredShadow={icon.color || "info"}
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
              mt={-5}
              mr={2}
            >
              <Icon fontSize="medium">{icon.component}</Icon>
            </MDBox>
          )}
          <MDBox mt={icon.component ? -2 : 0}>
            {title && <MDTypography variant="h6">{title}</MDTypography>}
            <MDBox mb={2}>
              <MDTypography component="div" variant="button" color="text">
                {description}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      ) : null}
      {useMemo(
        () => (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <MDBox height={height}>
                <Doughnut
                  data={data}
                  options={options}
                  plugins={[textCenter]}
                />
              </MDBox>
            </Grid>

            <Grid item container justifyContent={"center"} xs={12} md={6}>
              {printLabels(data)}
            </Grid>
          </Grid>
        ),
        [chart, height]
      )}
    </MDBox>
  );

  return title || description ? <Card>{renderChart}</Card> : renderChart;
}

// Setting default values for the props of DefaultDoughnutChart
DefaultDoughnutChart.defaultProps = {
  icon: { color: "info", component: "" },
  title: "",
  description: "",
  height: "19.125rem",
};

// Typechecking props for the DefaultDoughnutChart
DefaultDoughnutChart.propTypes = {
  icon: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
      "white",
      "gradientRedToYellow",
    ]),
    component: PropTypes.node,
  }),
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object])
  ).isRequired,
};

export default DefaultDoughnutChart;
