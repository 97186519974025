import { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Sidenav from "examples/Sidenav";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import routes from "routes/routes";
import { useMaterialUIController, setMiniSidenav } from "context";
import ErrorProvider from "./providers/errorProvider";
import ErrorNotification from "./components/molecules/notification/errorNotification";
import authStorage from "./services/localStorage/authStorage";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, layout, sidenavColor, darkMode } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route && !route.role) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }
      if (route.route && route.role === authStorage.getSessionUserRole()) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  const initialRouteDecider = () => {
    if (!!authStorage.getSession()) {
      if (
        authStorage.isOnboardingCompleted() == "true" ||
        authStorage.getSessionUserRole() === "admin"
      ) {
        return (
          <Route
            path="*"
            element={
              <Navigate to={`/${authStorage.getSessionUserRole()}/dashboard`} />
            }
          />
        );
      } else {
        return (
          <Route
            path="*"
            element={
              <Navigate
                to={`/${authStorage.getSessionUserRole()}/onboarding`}
              />
            }
          />
        );
      }
    } else {
      return <Route path="*" element={<Navigate to="/auth/signin" />} />;
    }
  };
  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <ErrorProvider>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brandName="Pasantías SAU"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          </>
        )}
        <Routes>
          {getRoutes(routes)}
          {initialRouteDecider()}
        </Routes>
        <ErrorNotification />
      </ErrorProvider>
    </ThemeProvider>
  );
}
