import React, {useState, useEffect} from "react";
import { Formik } from "formik";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import MDTypography from "components/MDTypography";
import { Link } from "react-router-dom";
import MDButton from "components/MDButton";
import StudentOnboardingStep1 from "./steps/studentOnboardingStep1";
import StudentOnboardingStep2 from "./steps/studentOnboardingStep2";
import StudentOnboardingStep3 from "./steps/studentOnboardingStep3";
import StudentOnboardingStep4 from "./steps/studentOnboardingStep4";
import StudentOnboardingStep5 from "./steps/studentOnboardingStep5";
import {
  StudentOnboardingStep1Schema,
  StudentOnboardingStep2Schema,
  StudentOnboardingStep3Schema,
  StudentOnboardingStep4Schema,
  StudentOnboardingStep5Schema,
} from "helpers/schemas/onboardingSchemas";
import { isMobile } from "../../../utils/isMobile";
import { getCurrentDate } from "../../../utils/dashboard";
import studentOnboarding from "../../../services/api/student/studentOnboarding";
import useAPIError from "../../../hooks";
import StudentService from "../../../services/api/student/studentService";

const steps = [
  "Datos personales",
  "Datos de locación",
  "Datos académicos",
  "Datos de salud",
  "Antecedentes y conocimientos",
];

function StudentOnboarding() {
  const [activeStep, setActiveStep] = useState(0);
  const [socialNetworksState, setSocialNetworksState] = useState({
    instagram: false,
    facebook: false,
    twitter: false,
    linkedin: false,
  });
  const [experiences, setExperiences] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [certifications, setCertifications] = useState([]);
  const [knowledges, setKnowledges] = useState([]);
  const [softSkills, setSoftSkills] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { addError } = useAPIError();
  const [studentData, setStudentData] = useState();

  const getStudentData = async () => {
    const response = await StudentService.getProfile();
    setStudentData(response.data);
    if (response.data.onboardingStep) {
      setActiveStep(response.data.onboardingStep - 1);
    }
  };

  useEffect(() => {
    getStudentData();
  }, []);

  const handleChangeSocialNetworks = (event) => {
    setSocialNetworksState({
      ...socialNetworksState,
      [event.target.name]: event.target.checked,
    });
  };

  const socialNetworks = () => {
    let array = [];
    for (const socialNetwork in socialNetworksState) {
      if (socialNetworksState[socialNetwork] === true) {
        array.push(socialNetwork);
      }
    }
    return array;
  };

  const onSubmit = async (values) => {
    setIsSubmitting(true);
    if (activeStep === 0) {
      const res1 = await studentOnboarding.step1({
        cuil: values.cuil,
        birthdate: values.birthdate,
        phone: values.phone,
        gender: values.gender,
        civilStatus: values.civilStatus,
        socialNetworks: socialNetworks(),
      });
      if (res1.status === 200) {
        handleNext();
      } else {
        addError(`${res1.data.message}`, res1.status);
      }
    }
    if (activeStep === 1) {
      const res2 = await studentOnboarding.step2({
        address: values.address,
        isOwner: values.isOwner,
        studentLocation: {
          city: values.city,
          province: values.province,
          postalCode: values.postalCode,
        },
      });
      if (res2.status === 200) {
        handleNext();
      } else {
        addError(`${res2.data.message}`, res2.status);
      }
    }
    if (activeStep === 2) {
      const res3 = await studentOnboarding.step3({
        highSchoolName: values.highSchoolName,
        highSchoolLocation: {
          city: values.highSchoolCity,
          province: values.highSchoolProvince,
          postalCode: values.highSchoolPostalCode,
        },
        highSchoolDegree: values.highSchoolDegree,
        careerId: values.careerId,
        startYear: values.startYear,
        currentCareerYear: values.currentCareerYear,
        approvedSubjectAmount: values.approvedSubjectAmount,
      });
      if (res3.status === 200) {
        handleNext();
      } else {
        addError(`${res3.data.message}`, res3.status);
      }
    }
    if (activeStep === 3) {
      const res4 = await studentOnboarding.step4({
        hasHealthInsurance: values.hasHealthInsurance,
        healthInsurance: values.healthInsurance,
        bloodType: values.bloodType,
        bloodDonor: values.bloodDonor,
      });
      if (res4.status === 200) {
        handleNext();
      } else {
        addError(`${res4.data.message}`, res4.status);
      }
    }
    if (activeStep === 4) {
      if(knowledges.length < 1){
        addError("Debe agregar al menos un conocimiento", 400);
         setIsSubmitting(false);
        return;
      }
      if(softSkills.length < 1){
        addError("Debe agregar al menos una habilidad blanda", 400);
         setIsSubmitting(false);
        return;
      }
      const languageIds = languages.map((l) => {
        return l.language;
      });
      const knowledgeIds = knowledges.map((k) => {
        return k.id;
      });
      const softSkillIds = softSkills.map((s) => {
        return s.id;
      });
      const res5 = await studentOnboarding.step5({
        experiences: experiences,
        languageIds: languageIds,
        knowledgeIds: knowledgeIds,
        informalEducations: certifications,
        skillIds: softSkillIds,
        personalMotivation: values.personalMotivation,
      });
      if (res5.status === 200) {
        handleNext();
      } else {
        addError(`${res5.data.message}`, res5.status);
      }
    }
    setIsSubmitting(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const initialValues = () => {
    return {
      cuil: studentData?.cuil || "",
      gender: studentData?.gender || "",
      civilStatus: studentData?.civilStatus || "",
      birthdate: studentData?.birthdate
        ? studentData.birthdate.split("T")[0]
        : getCurrentDate(),
      phone: studentData?.phone || "",
      address: studentData?.address || "",
      city: studentData?.location ? studentData.location.city : "",
      province: studentData?.location ? studentData?.location.province : "",
      postalCode: studentData?.location ? studentData?.location.postalCode : "",
      isOwner: studentData?.isOwner || false,
      highSchoolName: studentData?.highSchool
        ? studentData.highSchool.name
        : "",
      highSchoolDegree: studentData?.highSchoolDegree || "",
      highSchoolCity: studentData?.highSchool
        ? studentData.highSchool.location?.city
        : "",
      highSchoolProvince: studentData?.highSchool
        ? studentData.highSchool.location?.province
        : "",
      highSchoolPostalCode: studentData?.highSchool
        ? studentData.highSchool.location?.postalCode
        : "",
      careerId: studentData?.career ? studentData.career.id : "",
      approvedSubjectAmount: studentData?.approvedSubjectsAmount || "",
      startYear: studentData?.startYear || "",
      currentCareerYear: studentData?.currentCareerYear || "",
      hasHealthInsurance: studentData?.hasHealthInsurance || false,
      healthInsurance: studentData?.healthInsurance ? studentData.healthInsurance.name : "",
      bloodType: studentData?.bloodType || "",
      bloodDonor: !!studentData?.bloodDonor || false,
      personalMotivation: studentData?.personalMotivation || "",
    };
  };

  const schemaDecider = () => {
    if (activeStep === 0) {
      return StudentOnboardingStep1Schema;
    }
    if (activeStep === 1) {
      return StudentOnboardingStep2Schema;
    }
    if (activeStep === 2) {
      return StudentOnboardingStep3Schema;
    }
    if (activeStep === 3) {
      return StudentOnboardingStep4Schema;
    }
    if (activeStep === 4) {
      return StudentOnboardingStep5Schema;
    }
  };

  return (
    <Formik
      initialValues={initialValues()}
      validationSchema={schemaDecider}
      validateOnChange={false}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ errors, values, setFieldValue, handleSubmit, touched }) => (
        <Box p={3}>
          {!isMobile() && (
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step
                    key={label}
                    {...stepProps}
                    style={{ padding: "0 2rem 0.5rem" }}
                  >
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          )}
          {activeStep === steps.length ? (
            <React.Fragment>
              <MDTypography
                variant="h4"
                fontWeight="medium"
                color="dark"
                mt={5}
                mb={2}
                textAlign="center"
              >
                Su registro ha sido finalizado
              </MDTypography>
              <Box
                sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                justifyContent="center"
              >
                <MDButton
                  variant="gradient"
                  color="success"
                  component={Link}
                  to="/student/dashboard"
                >
                  Ir al dashboard
                </MDButton>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {activeStep === 0 ? (
                <StudentOnboardingStep1
                  setFieldValue={setFieldValue}
                  errors={errors}
                  values={values}
                  touched={touched}
                  socialNetworksState={socialNetworksState}
                  onChangeSocialNetworks={handleChangeSocialNetworks}
                ></StudentOnboardingStep1>
              ) : null}
              {activeStep === 1 ? (
                <StudentOnboardingStep2
                  setFieldValue={setFieldValue}
                  errors={errors}
                  values={values}
                  touched={touched}
                ></StudentOnboardingStep2>
              ) : null}
              {activeStep === 2 ? (
                <StudentOnboardingStep3
                  setFieldValue={setFieldValue}
                  errors={errors}
                  values={values}
                  touched={touched}
                ></StudentOnboardingStep3>
              ) : null}
              {activeStep === 3 ? (
                <StudentOnboardingStep4
                  setFieldValue={setFieldValue}
                  errors={errors}
                  values={values}
                  touched={touched}
                ></StudentOnboardingStep4>
              ) : null}
              {activeStep === 4 ? (
                <StudentOnboardingStep5
                  setFieldValue={setFieldValue}
                  errors={errors}
                  values={values}
                  touched={touched}
                  onSetExperience={setExperiences}
                  onSetLanguage={setLanguages}
                  onSetCertification={setCertifications}
                  onSetKnowledge={setKnowledges}
                  onSetSoftSkills={setSoftSkills}
                ></StudentOnboardingStep5>
              ) : null}
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <MDButton
                  variant="text"
                  color="info"
                  size="small"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >
                  Anterior
                </MDButton>
                <Box sx={{ flex: "1 1 auto" }} />

                {activeStep === steps.length - 1 ? (
                  <MDButton
                    color="info"
                    size="small"
                    onClick={() => handleSubmit()}
                  >
                    Finalizar
                  </MDButton>
                ) : (
                  <MDButton
                    variant="outlined"
                    color="info"
                    size="small"
                    onClick={() => handleSubmit()}
                  >
                    Siguiente
                  </MDButton>
                )}
              </Box>
            </React.Fragment>
          )}
        </Box>
      )}
    </Formik>
  );
}

export default StudentOnboarding;
