import React, { useState } from "react";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import Input from "components/molecules/input/input";
import useAPIError from "../../../../hooks";

const MAX_FILE_SIZE = 1024 * 1024; // 1MB

function CompanyOnboardingStep1({
  setFieldValue,
  errors,
  values,
  touched,
  isProfilePage,
}) {
    const { addError } = useAPIError();

  const handleUploadFile = (e) => {
    const file = e.currentTarget.files[0];
    if (file.size > MAX_FILE_SIZE) {
      addError("El archivo no puede superar 1MB", 400);
      setFieldValue("file", null); // Clear the file input
      return;
    }
    setFieldValue("file", file);
  };

  return (
    <Grid container mt={3}>
      <Grid item xs={12}>
        {!isProfilePage && (
          <>
            <MDTypography variant="h4" fontWeight="medium" color="dark">
              Te damos la bienvenida
            </MDTypography>
            <MDTypography variant="subtitle2">
              Por favor, complete los siguientes datos para poder avanzar con su
              registro
            </MDTypography>
          </>
        )}
      </Grid>
      <Grid mt={1} item xs={12}>
        <MDTypography variant="h6" fontWeight="medium" color="dark">
          Datos fiscales
        </MDTypography>
      </Grid>
      <Grid item mt={1} xs={12} md={7}>
        <Input
          type={"text"}
          label={"Tipo de Persona Jurídica"}
          fullWidth={true}
          onChange={(e) => setFieldValue("legalPersonType", e.target.value)}
          value={values.legalPersonType}
          error={!!errors.legalPersonType && touched.legalPersonType}
          errorText={errors.legalPersonType}
        />
        <Input
          type={"text"}
          label={"Condición Fiscal"}
          fullWidth={true}
          onChange={(e) => setFieldValue("taxStatus", e.target.value)}
          value={values.taxStatus}
          error={!!errors.taxStatus && touched.taxStatus}
          errorText={errors.taxStatus}
        />
        <Input
          type={"text"}
          label={"Rubro de la razón social"}
          fullWidth={true}
          onChange={(e) => setFieldValue("companyPurpose", e.target.value)}
          value={values.companyPurpose}
          error={!!errors.companyPurpose && touched.companyPurpose}
          errorText={errors.companyPurpose}
        />
        <input
          accept="*"
          style={{ display: "none", marginBottom: "100px" }}
          id="button-file"
          multiple
          type="file"
          onChange={(e) => {
            handleUploadFile(e);
          }}
        />
        <label htmlFor="button-file">
          <MDBox
            display="flex"
            alignItems="center"
            mb={3}
            sx={{ cursor: "pointer" }}
          >
            <Icon fontSize="small">picture_as_pdf</Icon>
            <MDTypography variant="button" fontWeight="bold">
              &nbsp;Subir constancia de inscripción de AFIP &nbsp;
            </MDTypography>
            <MDTypography variant="caption">
              {values.file?.name ? values.file.name : null}
            </MDTypography>
            {errors?.file ? (
              <MDTypography variant="caption" color="error">
                {errors.file}
              </MDTypography>
            ) : null}
          </MDBox>
        </label>
        {isProfilePage && (
          <MDTypography
            component="a"
            href={values.file}
            target="_blank"
            variant="caption"
            color="primary"
            fontWeight="medium"
          >
            Ver Constancia AFIP
          </MDTypography>
        )}
      </Grid>
    </Grid>
  );
}

export default CompanyOnboardingStep1;
