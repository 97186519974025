import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "../../components/MDTypography";
import backgroundImage from "assets/images/bg-profile.jpeg";
import Card from "@mui/material/Card";
import MDBadge from "../../components/MDBadge";
import DefaultDoughnutChart from "../../examples/Charts/DoughnutCharts/DefaultDoughnutChart";
import { useLocation } from "react-router-dom";
import AdminService from "../../services/api/admin/adminService";
import StudentInfo from "../../components/organisms/studentInfo";
import Prediction from "../../services/api/prediction";
import AtomicSpinner from "atomic-spinner";
function StudentInformation() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [currentStudent, setCurrentStudent] = useState(null);
  const [prediction, setPrediction] = useState(null);

  useEffect(() => {
    getStudent();
    getPrediction();
  }, []);

  const getStudent = async () => {
    const response = await AdminService.getStudent(queryParams.get("id"));
    setCurrentStudent(response.data);
  };

  const getPrediction = async () => {
    const response = await Prediction.getPrediction(
      queryParams.get("id"),
      queryParams.get("opportunityId")
    );

    setPrediction(response.data);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <MDBox position="relative" mb={5}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="10.75rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({
              functions: { rgba, linearGradient },
              palette: { gradients },
            }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.6),
                rgba(gradients.info.state, 0.6)
              )}, url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />
        <Card
          sx={{
            position: "relative",
            mt: -8,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <MDTypography variant="h5" fontWeight="medium">
                Información del estudiante:{" "}
                {currentStudent?.name + " " + currentStudent?.surname}
              </MDTypography>
            </Grid>
          </Grid>
          {/* {prediction ? (
            <Grid container mt={8}>
              <DefaultDoughnutChart
                icon={{ color: "info", component: "leaderboard" }}
                title="Porcentaje de aplicación a la oportunidad de pasantía"
                chart={{
                  labels: ["Porcentaje", "none"],
                  datasets: {
                    label: "Variables",
                    data: [
                      prediction.finalPercentage,
                      100 - prediction.finalPercentage,
                    ],
                    percentage: prediction.finalPercentage,
                  },
                }}
                resultPercentage={prediction.resultPercentage}
                subjectsAmountPercentage={prediction.subjectsAmountPercentage}
                careerPercentage={prediction.careerPercentage}
                areaPercentage={prediction.areaPercentage}
              />
            </Grid>
          ) : (
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              p={5}
            >
              <Grid item>
                <AtomicSpinner
                  atomSize={300}
                  displayElectronPaths={false}
                  displayNucleus={true}
                  electronColorPalette={["#0081C9", "#5BC0F8", "#86E5FF"]}
                  electronPathCount={30}
                  electronPathColor="#bc3939"
                  electronPathWidth={0.5}
                  electronsPerPath={3}
                  electronSize={1.6}
                  electronSpeed={0.4}
                  nucleusLayerCount={2}
                  nucleusParticlesPerLayer={3}
                  nucleusParticleFillColor="#737373"
                  nucleusParticleBorderColor="#ffffff"
                  nucleusParticleBorderWidth={0.3}
                  nucleusParticleSize={2.5}
                  nucleusDistanceFromCenter={2.5}
                  nucleusSpeed={2}
                  nucleusMaskOverlap={true}
                />
              </Grid>
              <Grid item>
                <MDTypography variant="caption">
                  Calculando porcentaje a aplicación a la oportunidad de
                  pasantía del estudiante...
                </MDTypography>
              </Grid>
            </Grid>
          )} */}
          <StudentInfo student={currentStudent} />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default StudentInformation;
