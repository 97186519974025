import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Input from "components/molecules/input/input";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Auth from "../../../services/api/auth";
import { Formik } from "formik";
import MDSnackbar from "../../../components/MDSnackbar";
import { SetNewPasswordSchema } from "helpers/schemas/authSchemas";
import useAPIError from "../../../hooks";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function NewPassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [openNotification, setOpenNotification] = useState(false);
  const [token, setToken] = useState(null);
  const { addError } = useAPIError();

  useEffect(() => {
    getToken();
  }, []);

  const getToken = async () => {
    setToken(queryParams.get("token"));
  };

  const onRecoverPassword = async (values) => {
    const response = await Auth.newPassword(token, {
      password: values.password,
    });
    if (response.status === 201) {
      setOpenNotification(true);
      navigate("/login");
    } else {
      addError(`${response.data.message}`, response.status);
    }
  };
  return (
    <BasicLayout>
      <MDSnackbar
        icon="notifications"
        color="success"
        title="Se ha cambiado exitosamente su contraseña"
        open={openNotification}
        close={() => setOpenNotification(false)}
      />
      <MDBox
        bgColor="white"
        borderRadius="lg"
        mx={2}
        mt={-3}
        pr={15}
        pl={0}
        textAlign="center"
      >
        <Grid container>
          <Grid item xs={12}>
            <MDTypography variant="h4" fontWeight="medium" color="dark" mt={1}>
              Nueva contraseña
            </MDTypography>
          </Grid>
          <Formik
            initialValues={{
              password: "",
            }}
            validationSchema={SetNewPasswordSchema}
            validateOnChange={true}
            onSubmit={onRecoverPassword}
          >
            {({ errors, setFieldValue, handleSubmit, touched }) => (
              <Grid item xs={12} mt={4}>
                <Input
                  type={"password"}
                  label={"Contraseña nueva"}
                  onChange={(e) => setFieldValue("password", e.target.value)}
                  error={!!errors.password && touched.password}
                  errorText={errors.password}
                />
                <MDBox mb={1}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Cambiar contraseña
                  </MDButton>
                </MDBox>
              </Grid>
            )}
          </Formik>
        </Grid>
      </MDBox>
    </BasicLayout>
  );
}

export default NewPassword;
