import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import MDTypography from "../../../components/MDTypography";
import React, { useEffect, useState } from "react";
import MDBox from "../../../components/MDBox";
import JobCard from "../../../components/molecules/card/JobCard";
import StudentService from "../../../services/api/student/studentService";
import CustomModal from "../../../components/molecules/modal/modal";
import Chip from "@mui/material/Chip";
import MDButton from "../../../components/MDButton";
import MDAlert from "../../../components/MDAlert";
import Icon from "@mui/material/Icon";
import MDSnackbar from "../../../components/MDSnackbar";
import useAPIError from "../../../hooks";

function JobOffers() {
  const [jobOpportunities, setJobOpportunities] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentJobOpportunity, setJobOpportunity] = useState(null);
  const [openNotification, setOpenNotification] = useState(false);
  const { addError } = useAPIError();

  useEffect(() => {
    getJobOpportunities();
  }, []);
  const getJobOpportunities = async () => {
    const response = await StudentService.getJobOpportunities();

    setJobOpportunities(response.data);
  };

  const handleGetOpportunity = async (opportunity) => {
    setJobOpportunity(opportunity);
    setOpenModal(true);
  };

  const handleApplyToJob = async (jobId) => {
    const response = await StudentService.applyToJob(jobId);
    if (response.status === 200) {
      setOpenNotification(true);
      setOpenModal(false);
      getJobOpportunities();
    } else {
      addError(`${response.data.message}`, response.status);
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDSnackbar
        icon="notifications"
        color="success"
        title="Has aplicado a una oportunidad!"
        open={openNotification}
        close={() => setOpenNotification(false)}
      />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDTypography variant="h4">
              Oportunidades que has aplicado
            </MDTypography>
          </Grid>
          <Grid item container xs={12}>
            <Grid item container spacing={3} xs={12}>
              {jobOpportunities.mainJobs
                ?.filter((job) => job.hasApplied)
                .map((jobOpportunity, index) => (
                  <Grid
                    item
                    xs={4}
                    key={`job-opportunity-main-applied-${index}`}
                  >
                    <JobCard
                      title={jobOpportunity.company.name}
                      description={`Área de trabajo: ${jobOpportunity.area.name}`}
                      action={{
                        onClick: () => handleGetOpportunity(jobOpportunity),
                        color: "white",
                        label: "Ver oportunidad",
                      }}
                      hasApplied={true}
                    />
                  </Grid>
                ))}
              {jobOpportunities.otherJobs
                ?.filter((job) => job.hasApplied)
                .map((jobOpportunity, index) => (
                  <Grid
                    item
                    xs={4}
                    key={`job-opportunity-other-applied-${index}`}
                  >
                    <JobCard
                      title={jobOpportunity.company.name}
                      description={`Área de trabajo: ${jobOpportunity.area.name}`}
                      action={{
                        onClick: () => handleGetOpportunity(jobOpportunity),
                        color: "white",
                        label: "Ver oportunidad",
                      }}
                      hasApplied={true}
                    />
                  </Grid>
                ))}
            </Grid>
            {jobOpportunities.otherJobs?.filter((job) => job.hasApplied)
              .length === 0 &&
              jobOpportunities.mainJobs?.filter((job) => job.hasApplied)
                .length === 0 && (
                <Grid item>
                  <MDTypography variant="button" color={"secondary"}>
                    No hay oportunidades.
                  </MDTypography>
                </Grid>
              )}
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h4">
              Oportunidades que se aplican a tu perfil
            </MDTypography>
          </Grid>
          <Grid item container spacing={3} xs={12}>
            {jobOpportunities.mainJobs?.filter((job) => !job.hasApplied)
              .length > 0 ? (
              jobOpportunities.mainJobs
                ?.filter((job) => !job.hasApplied)
                .map((jobOpportunity, index) => (
                  <Grid item xs={4} key={`job-opportunity-${index}`}>
                    <JobCard
                      title={jobOpportunity.company.name}
                      description={`Área de trabajo: ${jobOpportunity.area.name}`}
                      action={{
                        onClick: () => handleGetOpportunity(jobOpportunity),
                        color: "white",
                        label: "Ver oportunidad",
                      }}
                    />
                  </Grid>
                ))
            ) : (
              <Grid item>
                <MDTypography variant="button" color={"secondary"}>
                  No hay oportunidades disponibles.
                </MDTypography>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h4">Otras oportunidades</MDTypography>
          </Grid>
          <Grid item container spacing={3} xs={12}>
            {jobOpportunities.otherJobs?.filter((job) => !job.hasApplied)
              .length > 0 ? (
              jobOpportunities.otherJobs
                ?.filter((job) => !job.hasApplied)
                .map((jobOpportunity, index) => (
                  <Grid item xs={4} key={`job-opportunity-${index}`}>
                    <JobCard
                      title={jobOpportunity.company.name}
                      description={`Área de trabajo: ${jobOpportunity.area.name}`}
                      action={{
                        onClick: () => handleGetOpportunity(jobOpportunity),
                        color: "white",
                        label: "Ver oportunidad",
                      }}
                    />
                  </Grid>
                ))
            ) : (
              <Grid item>
                <MDTypography variant="button" color={"secondary"}>
                  No hay oportunidades disponibles.
                </MDTypography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </MDBox>
      <CustomModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="job-opportunity-modal"
      >
        <MDTypography variant="h4">
          Oportunidad de trabajo en {currentJobOpportunity?.company.name}
        </MDTypography>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12}>
            <MDTypography variant="h6">
              Área: {currentJobOpportunity?.area.name}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Conocimientos requeridos:</MDTypography>
            <Grid item container xs={12} spacing={1}>
              {currentJobOpportunity?.knowledge.map((knowledge) => {
                return (
                  <Grid item key={knowledge.knowledge.subCategory}>
                    <Chip
                      label={knowledge.knowledge.subCategory}
                      key={knowledge.knowledge.subCategory}
                      variant="filled"
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Tareas a desempeñar:</MDTypography>
            <Grid item container ml={3} xs={12}>
              <ul>
                {currentJobOpportunity?.tasks.map((task) => {
                  return <li key={task.task.name}>{task.task.name}</li>;
                })}
              </ul>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {!currentJobOpportunity?.hasApplied ? (
              <MDButton
                variant="contained"
                size="medium"
                color="success"
                onClick={() => handleApplyToJob(currentJobOpportunity.id)}
              >
                Aplicar
              </MDButton>
            ) : (
              <MDAlert color="success" mt={2}>
                <Icon fontSize="small">thumb_up</Icon>&nbsp;{" "}
                <MDTypography variant="h6" color={"white"}>
                  {" "}
                  Ya has aplicado a esta oportunidad! Estamos analizando tu
                  perfil y nos comunicaremos en breve.
                </MDTypography>
              </MDAlert>
            )}
          </Grid>
        </Grid>
      </CustomModal>
    </DashboardLayout>
  );
}

export default JobOffers;
