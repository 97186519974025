import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import Modal from "@mui/material/Modal";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Grid, Card, Box } from "@mui/material";
import MDInput from "components/MDInput";
import TextField from "@mui/material/TextField";
import {
  AddExperienceSchema,
  AddLanguageSchema,
  AddCertificationSchema,
} from "helpers/schemas/onboardingSchemas";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import Chip from "@mui/material/Chip";
import CancelIcon from "@mui/icons-material/Cancel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import AddIcon from "@mui/icons-material/Add";
import StudentService from "../../../../services/api/student/studentService";
import { getCurrentDate, changeDateFormat } from "../../../../utils/dashboard";
import useAPIError from "../../../../hooks";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
  minWidth: "400px",
};

function ProfileStep5({
  studentId,
  setFieldValue,
  errors,
  values,
  touched,
  experiences,
  onSetExperience,
  languages,
  onSetLanguage,
  studentCertifications,
  studentKnowledges,
  onSetKnowledge,
  studentSoftSkills,
  onSetSoftSkills,
  onGetStudentData,
}) {
  const [openModal, setOpenModal] = useState(false);
  const [currentExperiences, setCurrentExperiences] = useState(
    experiences || []
  );
  const [isEditModal, setIsEditModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [currentExperience, setCurrentExperience] = useState({});
  const [task, setCurrentTask] = useState("");
  const [tasks, setTask] = useState([]);
  const [openLanguagesModal, setOpenLanguagesModal] = useState(false);
  const [currentLanguages, setCurrentLanguages] = useState(languages || []);
  const [openCertificationsModal, setOpenCertificationsModal] = useState(false);
  const [certifications, setCertification] = useState(
    studentCertifications || []
  );
  const [isEditCertificationModal, setIsEditCertificationModal] =
    useState(false);
  const [currentCertification, setCurrentCertification] = useState({});
  const [currentKnowledge, setCurrentKnowledge] = useState("");
  const [knowledges, setKnowledges] = useState(studentKnowledges || []);
  const [currentSoftSkill, setCurrentSoftSkill] = useState("");
  const [softSkills, setSoftSkills] = useState(studentSoftSkills || []);
  const [availableLanguages, setAvailableLanguages] = React.useState([]);
  const [availableKnowledges, setAvailableKnowledges] = React.useState([]);
  const [availableSoftSkills, setAvailableSoftSkills] = React.useState([]);
  const { addError } = useAPIError();

  useEffect(() => {
    getLanguages();
    getKnowledges();
    getSoftSkills();
    setCertification(studentCertifications);
    setCurrentExperiences(experiences);
    setCurrentLanguages(languages);
  }, [studentCertifications, experiences, languages]);

  const getLanguages = async () => {
    const response = await StudentService.getLanguages();
    setAvailableLanguages(response.data);
  };

  const getKnowledges = async () => {
    const response = await StudentService.getKnowledges();
    setAvailableKnowledges(response.data);
  };

  const getSoftSkills = async () => {
    const response = await StudentService.getSoftSkills();
    setAvailableSoftSkills(response.data);
  };
  const handleAddTask = () => {
    setTask([...tasks, task]);
    setCurrentTask("");
  };

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => {
    setOpenModal(false);
    setIsEditModal(false);
    setTask([]);
  };

  const handleDeleteTask = (name) => {
    let array = [];
    array = tasks.filter((task) => task !== name);
    setTask(array);
  };

  const addExperience = async (values) => {
    let body = { ...values, tasks: tasks, studentId };

    const res = await StudentService.createExperience(body);
    if (res.status === 201) {
      onGetStudentData();
      setTask([]);
      handleCloseModal();
    } else {
      addError(`${res.data.message}`, res.status);
    }
  };

  const onEditExperience = (index) => {
    setIsEditModal(true);
    const currentExperience = {
      ...currentExperiences[index],
    };
    setCurrentExperience(currentExperience);
    setTask(currentExperience.tasks.map((task) => task.task.name));
    setOpenModal(true);
  };

  const updateExperience = async (values) => {
    let body = { ...values, tasks: tasks, studentId };

    const res = await StudentService.updateExperience(
      currentExperience.id,
      body
    );
    if (res.status === 200) {
      onGetStudentData();
      setIsEditModal(false);
      setTask([]);
      handleCloseModal();
    } else {
      addError(`${res.data.message}`, res.status);
    }
  };

  const onDeleteExperience = async (index) => {
    const currentExperience = { ...currentExperiences[index] };

    const res = await StudentService.deleteExperience(currentExperience.id);
    if (res.status === 200) {
      onGetStudentData();
      setOpenModal(false);
      handleCloseConfirmationModal();
    } else {
      addError(`${res.data.message}`, res.status);
    }
  };

  const handleOpenLanguagesModal = () => setOpenLanguagesModal(true);

  const handleCloseLanguagesModal = () => {
    setOpenLanguagesModal(false);
  };

  const addLanguage = async (values) => {
    const body = { studentId, languageId: values.language };
    const res = await StudentService.createLanguage(body);
    if (res.status === 201) {
      onGetStudentData();
      handleCloseLanguagesModal();
    } else {
      addError(`${res.data.message}`, res.status);
    }
  };

  const onDeleteLanguage = async (languageId) => {
    const res = await StudentService.deleteLanguage(languageId);
    if (res.status === 200) {
      onGetStudentData();
      handleCloseLanguagesModal(false);
      handleCloseConfirmationModal();
    } else {
      addError(`${res.data.message}`, res.status);
    }
  };

  const handleOpenCertificationsModal = () => setOpenCertificationsModal(true);

  const handleCloseCertificationsModal = () => {
    setOpenCertificationsModal(false);
    setIsEditCertificationModal(false);
  };

  const addCertification = async (values) => {
    const body = { studentId, ...values };
    const res = await StudentService.createCertification(body);
    if (res.status === 201) {
      onGetStudentData();
      handleCloseCertificationsModal();
    } else {
      addError(`${res.data.message}`, res.status);
    }
  };

  const onEditCertification = (index) => {
    setIsEditCertificationModal(true);
    const currentCertification = {
      ...certifications[index],
    };
    setCurrentCertification(currentCertification);
    handleOpenCertificationsModal();
  };

  const updateCertification = async (values) => {
    const body = { studentId, ...values };

    const res = await StudentService.updateCertification(
      currentCertification.id,
      body
    );
    if (res.status === 200) {
      onGetStudentData();
      handleCloseCertificationsModal();
      setIsEditCertificationModal(false);
    } else {
      addError(`${res.data.message}`, res.status);
    }
  };

  const onDeleteCertification = async (index) => {
    const currentCertification = { ...certifications[index] };
    const res = await StudentService.deleteCertification(
      currentCertification.id
    );
    if (res.status === 200) {
      onGetStudentData();
      handleCloseCertificationsModal(false);
      handleCloseConfirmationModal();
    } else {
      addError(`${res.data.message}`, res.status);
    }
  };

  const handleChangeKnowledge = (value) => {
    if (!value) {
      return;
    }
    setKnowledges([
      ...knowledges,
      availableKnowledges.filter(
        (knowledge) => knowledge.subCategory === value
      )[0],
    ]);
    onSetKnowledge([
      ...knowledges,
      availableKnowledges.filter(
        (knowledge) => knowledge.subCategory === value
      )[0],
    ]);
    setCurrentKnowledge("");
  };

  const handleDeleteKnowledge = (knowledge) => {
    let array = [];
    array = knowledges.filter((k) => k.id !== knowledge.id);
    setKnowledges(array);
    onSetKnowledge(array);
  };

  const handleChangeSoftSkill = (value) => {
    if (!value) {
      return;
    }
    setSoftSkills([
      ...softSkills,
      availableSoftSkills.filter((softSkill) => softSkill.name === value)[0],
    ]);
    onSetSoftSkills([
      ...softSkills,
      availableSoftSkills.filter((softSkill) => softSkill.name === value)[0],
    ]);
    setCurrentSoftSkill("");
  };

  const handleDeleteSoftSkill = (softSkill) => {
    let array = [];
    array = softSkills.filter((sk) => sk.id !== softSkill.id);
    setSoftSkills(array);
    onSetSoftSkills(array);
  };

  const handleDeleteAction = (action) => {
    setCurrentAction(action);
    setOpenConfirmationModal(true);
  };

  const handleDelete = () => {
    const { name, index } = currentAction;
    if (name === "experience") {
      onDeleteExperience(index);
    }
    if (name === "certification") {
      onDeleteCertification(index);
    }
    if (name === "language") {
      onDeleteLanguage(index);
    }
  };
  const handleCloseConfirmationModal = () => {
    setCurrentAction(null);
    setOpenConfirmationModal(false);
  };

  return (
    <>
      <Grid container mt={3}>
        <Grid item container xs={12} spacing={3} alignItems={"center"}>
          <Grid item>
            <MDTypography variant="h5" fontWeight="medium" color="dark">
              Antecedentes Laborales
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={8}>
            <MDButton
              variant="outlined"
              color="dark"
              size="small"
              onClick={handleOpenModal}
            >
              <AddIcon />
              &nbsp; Agregar
            </MDButton>
          </Grid>
        </Grid>

        <Grid item xs={12} md={8}>
          {currentExperiences.length > 0
            ? currentExperiences.map((experience, index) => (
                <MDBox
                  component="li"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  bgColor={"#250b4617"}
                  borderRadius="lg"
                  p={3}
                  mb={0}
                  mt={2}
                  key={`experience-${index}`}
                >
                  <Grid item xs={12} sm={9}>
                    <MDBox mb={1} lineHeight={0}>
                      <MDTypography variant="caption" fontWeight="medium">
                        Empresa:&nbsp;&nbsp;&nbsp;
                        <MDTypography
                          variant="caption"
                          textTransform="capitalize"
                        >
                          {experience.enterprise}
                        </MDTypography>
                      </MDTypography>
                    </MDBox>
                    <MDBox mb={1} lineHeight={0}>
                      <MDTypography variant="caption" fontWeight="medium">
                        Cargo:&nbsp;&nbsp;&nbsp;
                        <MDTypography variant="caption">
                          {experience.position}
                        </MDTypography>
                      </MDTypography>
                    </MDBox>
                    <MDTypography variant="caption" fontWeight="medium">
                      Período:&nbsp;&nbsp;&nbsp;
                      <MDTypography variant="caption">
                        {changeDateFormat(experience.startDate)} -
                        {changeDateFormat(experience.endDate)}
                      </MDTypography>
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      mt={{ xs: 2, sm: 0 }}
                      ml={{ xs: -1.5, sm: 0 }}
                    >
                      <MDBox mr={1}>
                        <MDButton
                          variant="text"
                          color={"dark"}
                          onClick={() => onEditExperience(index)}
                        >
                          <Icon>edit</Icon>&nbsp;
                        </MDButton>
                      </MDBox>
                      <MDButton
                        variant="text"
                        color="error"
                        onClick={() =>
                          handleDeleteAction({ name: "experience", index })
                        }
                      >
                        <Icon>delete</Icon>&nbsp;
                      </MDButton>
                    </MDBox>
                  </Grid>
                </MDBox>
              ))
            : null}
        </Grid>
        <Grid item container xs={12} spacing={3} alignItems={"center"} mt={3}>
          <Grid item>
            <MDTypography variant="h5" fontWeight="medium" color="dark">
              Idiomas
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={8}>
            <MDButton
              variant="outlined"
              color="dark"
              size="small"
              onClick={handleOpenLanguagesModal}
            >
              <AddIcon />
              &nbsp; Agregar idioma
            </MDButton>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          {currentLanguages.length > 0
            ? currentLanguages.map((language, index) => (
                <MDBox
                  component="li"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  bgColor={"#250b4617"}
                  borderRadius="lg"
                  p={3}
                  mb={0}
                  mt={2}
                  key={`language-${index}`}
                >
                  <Grid item xs={12} sm={9}>
                    <MDTypography variant="caption" fontWeight="medium">
                      Idioma:&nbsp;&nbsp;&nbsp;
                      {availableLanguages && (
                        <MDTypography variant="caption">
                          {
                            availableLanguages?.filter(
                              (l) => l.id === language.language.id
                            )[0]?.name
                          }
                          &nbsp;
                          {
                            availableLanguages?.filter(
                              (l) => l.id === language.language.id
                            )[0]?.level
                          }
                        </MDTypography>
                      )}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      mt={{ xs: 2, sm: 0 }}
                      ml={{ xs: -1.5, sm: 0 }}
                    >
                      <MDButton
                        variant="text"
                        color="error"
                        onClick={() =>
                          handleDeleteAction({
                            name: "language",
                            index: language.id,
                          })
                        }
                      >
                        <Icon>delete</Icon>&nbsp;
                      </MDButton>
                    </MDBox>
                  </Grid>
                </MDBox>
              ))
            : null}
        </Grid>
        <Grid item container xs={12} spacing={3} alignItems={"center"} mt={3}>
          <Grid item>
            <MDTypography variant="h5" fontWeight="medium" color="dark">
              Cursos y seminarios
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={8}>
            <MDButton
              variant="outlined"
              color="dark"
              size="small"
              onClick={handleOpenCertificationsModal}
            >
              <AddIcon />
              &nbsp; Agregar curso o seminario
            </MDButton>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={8}>
          {certifications.length > 0
            ? certifications.map((certification, index) => (
                <MDBox
                  component="li"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  bgColor={"#250b4617"}
                  borderRadius="lg"
                  p={3}
                  mb={0}
                  mt={2}
                  key={`certification-${index}`}
                >
                  <Grid item xs={12} sm={9}>
                    <MDTypography variant="caption" fontWeight="medium">
                      Curso:&nbsp;&nbsp;&nbsp;
                      <MDTypography variant="caption">
                        {certification.year} - {certification.name}
                      </MDTypography>
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      mt={{ xs: 2, sm: 0 }}
                      ml={{ xs: -1.5, sm: 0 }}
                    >
                      <MDBox mr={1}>
                        <MDButton
                          variant="text"
                          color={"dark"}
                          onClick={() => onEditCertification(index)}
                        >
                          <Icon>edit</Icon>&nbsp;
                        </MDButton>
                      </MDBox>
                      <MDButton
                        variant="text"
                        color="error"
                        onClick={() =>
                          handleDeleteAction({ name: "certification", index })
                        }
                      >
                        <Icon>delete</Icon>&nbsp;
                      </MDButton>
                    </MDBox>
                  </Grid>
                </MDBox>
              ))
            : null}
        </Grid>
        <Grid item xs={12} mt={3}>
          <MDTypography variant="h5" fontWeight="medium" color="dark">
            Conocimientos
          </MDTypography>
        </Grid>
        <Grid item xs={12} md={3} mt={3}>
          <Autocomplete
            id="knowledges"
            freeSolo
            onChange={(event, newValue) => {
              handleChangeKnowledge(newValue);
            }}
            value={currentKnowledge}
            options={availableKnowledges.map((option) => option.subCategory)}
            renderInput={(params) => (
              <TextField {...params} label="Buscar..." />
            )}
          />
        </Grid>
        <Grid item container spacing={1} mt={2}>
          {knowledges.map((knowledge, index) => {
            return (
              <Grid item key={`knowledges-${index}`}>
                <Chip
                  label={knowledge.subCategory}
                  deleteIcon={<CancelIcon />}
                  onDelete={() => handleDeleteKnowledge(knowledge)}
                  variant="outlined"
                />
              </Grid>
            );
          })}
        </Grid>
        <Grid item xs={12} mt={3}>
          <MDTypography variant="h5" fontWeight="medium" color="dark">
            Habilidades blandas
          </MDTypography>
        </Grid>
        <Grid item xs={12} md={3} mt={3}>
          <Autocomplete
            id="soft-skills"
            freeSolo
            onChange={(event, newValue) => {
              handleChangeSoftSkill(newValue);
            }}
            value={currentSoftSkill}
            options={availableSoftSkills.map((option) => option.name)}
            renderInput={(params) => (
              <TextField {...params} label="Buscar..." />
            )}
          />
        </Grid>
        <Grid item container spacing={1} mt={2}>
          {softSkills.map((sk, index) => {
            return (
              <Grid item key={`skill-${index}`}>
                <Chip
                  label={sk.name}
                  deleteIcon={<CancelIcon />}
                  onDelete={() => handleDeleteSoftSkill(sk)}
                  variant="outlined"
                />
              </Grid>
            );
          })}
        </Grid>
        <Grid item xs={12} mt={3}>
          <MDTypography variant="h5" fontWeight="medium" color="dark">
            Motivación personal
          </MDTypography>
        </Grid>
        <Grid item mt={3} xs={12}>
          <TextField
            multiline
            fullWidth
            rows={4}
            variant="outlined"
            value={values.personalMotivation}
            onChange={(e) =>
              setFieldValue("personalMotivation", e.target.value)
            }
            error={!!errors.personalMotivation}
          />
          {errors?.personalMotivation ? (
            <MDTypography variant="caption" color="error">
              {errors.personalMotivation}
            </MDTypography>
          ) : null}
        </Grid>
      </Grid>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Formik
            initialValues={{
              id: isEditModal ? currentExperience.id : "",
              enterprise: isEditModal ? currentExperience.enterprise : "",
              position: isEditModal ? currentExperience.position : "",
              referralName: isEditModal ? currentExperience.referralName : "",
              referralPhone: isEditModal ? currentExperience.referralPhone : "",
              startDate: isEditModal
                ? currentExperience.startDate.split("T")[0]
                : getCurrentDate(),
              endDate: isEditModal
                ? currentExperience.endDate.split("T")[0]
                : getCurrentDate(),
            }}
            validationSchema={AddExperienceSchema}
            validateOnChange={true}
            onSubmit={isEditModal ? updateExperience : addExperience}
          >
            {({ errors, values, setFieldValue, handleSubmit }) => (
              <Card sx={style}>
                <MDTypography variant="h5" fontWeight="medium" color="dark">
                  {isEditModal
                    ? "Editar antecedente laboral"
                    : "Agregar antecedente laboral"}
                </MDTypography>
                <Grid container mt={1} spacing={2}>
                  <Grid item container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <MDInput
                        type="text"
                        label="Empresa"
                        fullWidth
                        value={values.enterprise}
                        onChange={(e) =>
                          setFieldValue("enterprise", e.target.value)
                        }
                        error={!!errors.enterprise}
                      />
                      {errors?.enterprise ? (
                        <MDTypography variant="caption" color="error">
                          {errors.enterprise}
                        </MDTypography>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MDInput
                        type="text"
                        label="Cargo"
                        fullWidth
                        value={values.position}
                        onChange={(e) =>
                          setFieldValue("position", e.target.value)
                        }
                        error={!!errors.position}
                      />
                      {errors?.position ? (
                        <MDTypography variant="caption" color="error">
                          {errors.position}
                        </MDTypography>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <MDInput
                        type="text"
                        label="Referente"
                        fullWidth
                        value={values.referralName}
                        onChange={(e) =>
                          setFieldValue("referralName", e.target.value)
                        }
                        error={!!errors.referralName}
                      />
                      {errors?.referralName ? (
                        <MDTypography variant="caption" color="error">
                          {errors.referralName}
                        </MDTypography>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MDInput
                        type="text"
                        label="Teléfono referente"
                        fullWidth
                        value={values.referralPhone}
                        onChange={(e) =>
                          setFieldValue("referralPhone", e.target.value)
                        }
                        error={!!errors.referralPhone}
                      />
                      {errors?.referralPhone ? (
                        <MDTypography variant="caption" color="error">
                          {errors.referralPhone}
                        </MDTypography>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDInput
                      type="date"
                      label="Fecha comienzo"
                      fullWidth
                      value={values.startDate}
                      onChange={(e) =>
                        setFieldValue("startDate", e.target.value)
                      }
                      error={!!errors.startDate}
                    />
                    {errors?.startDate ? (
                      <MDTypography variant="caption" color="error">
                        {errors.startDate}
                      </MDTypography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDInput
                      type="date"
                      label="Fecha de finalización"
                      fullWidth
                      value={values.endDate}
                      onChange={(e) => setFieldValue("endDate", e.target.value)}
                      error={!!errors.endDate}
                    />
                    {errors?.endDate ? (
                      <MDTypography variant="caption" color="error">
                        {errors.endDate}
                      </MDTypography>
                    ) : null}
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={12} sm={2}>
                      <MDTypography
                        variant="h6"
                        fontWeight="medium"
                        color="dark"
                      >
                        Tareas
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12}>
                      <MDTypography variant="caption" color="info">
                        Para agregar una nueva tarea escriba en el campo
                        inferior y luego haga click en "+"
                      </MDTypography>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} spacing={3}>
                    <Grid item xs={8}>
                      <MDInput
                        type="text"
                        label="Tarea"
                        value={task}
                        fullWidth
                        onChange={(e) => setCurrentTask(e.target.value)}
                      />
                      {/* {errors?.task ? (
                      <MDTypography variant="caption" color="error">
                        {errors.task}
                      </MDTypography>
                    ) : null} */}
                    </Grid>
                    <Grid item xs={2}>
                      <MDButton
                        variant="gradient"
                        color="dark"
                        size="small"
                        iconOnly
                        onClick={() => handleAddTask()}
                      >
                        <AddIcon />
                      </MDButton>
                    </Grid>
                  </Grid>
                  <Grid item container spacing={1}>
                    {tasks.map((task, index) => {
                      return (
                        <Grid item xs={12} key={`task-{index}`}>
                          <Chip
                            label={task}
                            onDelete={() => handleDeleteTask(task)}
                            deleteIcon={<CancelIcon />}
                            variant="outlined"
                          />
                        </Grid>
                      );
                    })}
                  </Grid>

                  <Grid item container justifyContent="space-between" xs={12}>
                    <Grid item>
                      <MDButton
                        variant="text"
                        color="info"
                        size="small"
                        onClick={handleCloseModal}
                      >
                        Cancelar
                      </MDButton>
                    </Grid>
                    <Grid item>
                      <MDButton
                        color="info"
                        size="small"
                        onClick={() => handleSubmit()}
                      >
                        {isEditModal ? "Aceptar" : "Agregar"}
                      </MDButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            )}
          </Formik>
        </>
      </Modal>
      <Modal
        open={openLanguagesModal}
        onClose={handleCloseLanguagesModal}
        aria-labelledby="modal-languages"
        aria-describedby="modal-add-languages"
      >
        <>
          <Formik
            initialValues={{
              language: "",
            }}
            validationSchema={AddLanguageSchema}
            validateOnChange={true}
            onSubmit={addLanguage}
          >
            {({ errors, values, setFieldValue, handleSubmit }) => (
              <Card sx={style}>
                <MDTypography variant="h5" fontWeight="medium" color="dark">
                  {"Agregar nuevo idioma"}
                </MDTypography>
                <Grid container mt={1} mb={2} spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Idioma
                      </InputLabel>
                      <Select
                        id="language-select"
                        value={values.language}
                        label="Lenguaje"
                        onChange={(e) =>
                          setFieldValue("language", e.target.value)
                        }
                      >
                        {availableLanguages.length > 0 &&
                          availableLanguages.map((language, index) => {
                            return (
                              <MenuItem
                                value={language.id}
                                key={`language-item-${index}`}
                              >{`${language.name} - ${language.level}`}</MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>

                    {errors?.language ? (
                      <MDTypography variant="caption" color="error">
                        {errors.language}
                      </MDTypography>
                    ) : null}
                  </Grid>
                </Grid>
                <Grid item container justifyContent="space-between" xs={12}>
                  <Grid item>
                    <MDButton
                      variant="text"
                      color="info"
                      size="small"
                      onClick={handleCloseLanguagesModal}
                    >
                      Cancelar
                    </MDButton>
                  </Grid>
                  <Grid item>
                    <MDButton
                      color="info"
                      size="small"
                      onClick={() => handleSubmit()}
                    >
                      {"Agregar"}
                    </MDButton>
                  </Grid>
                </Grid>
              </Card>
            )}
          </Formik>
        </>
      </Modal>
      <Modal
        open={openCertificationsModal}
        onClose={handleCloseCertificationsModal}
      >
        <>
          <Formik
            initialValues={{
              name: isEditCertificationModal ? currentCertification.name : "",
              year: isEditCertificationModal ? currentCertification.year : "",
              hasCertificate: isEditCertificationModal
                ? currentCertification.hasCertificate
                : false,
            }}
            validationSchema={AddCertificationSchema}
            validateOnChange={true}
            onSubmit={
              isEditCertificationModal ? updateCertification : addCertification
            }
          >
            {({ errors, values, setFieldValue, handleSubmit }) => (
              <Card sx={style}>
                <MDTypography variant="h5" fontWeight="medium" color="dark">
                  {isEditCertificationModal
                    ? "Editar  curso o seminario"
                    : "Agregar nuevo curso o seminario"}
                </MDTypography>
                <Grid container mt={1} mb={2} spacing={2}>
                  <Grid item xs={12}>
                    <MDInput
                      type="number"
                      label="Año"
                      fullWidth
                      value={values.year}
                      onChange={(e) => setFieldValue("year", e.target.value)}
                      error={!!errors.year}
                    />
                    {errors?.year ? (
                      <MDTypography variant="caption" color="error">
                        {errors.year}
                      </MDTypography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <MDInput
                      type="text"
                      label="Nombre"
                      fullWidth
                      value={values.name}
                      onChange={(e) => setFieldValue("name", e.target.value)}
                      error={!!errors.name}
                    />
                    {errors?.name ? (
                      <MDTypography variant="caption" color="error">
                        {errors.name}
                      </MDTypography>
                    ) : null}
                  </Grid>
                </Grid>
                <MDTypography variant="button">Tiene certificado</MDTypography>
                <RadioGroup
                  row
                  name="health-insurance-radio-buttons-group"
                  value={values.hasCertificate}
                  onChange={(e) => {
                    let value = false;
                    if (e.target.value && typeof e.target.value === "string") {
                      if (e.target.value.toLowerCase() === "true") value = true;
                      if (e.target.value.toLowerCase() === "false")
                        value = false;
                    }
                    setFieldValue(
                      "hasCertificate",
                      e.target.value && typeof e.target.value === "string"
                        ? value
                        : e.target.value
                    );
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Si"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
                {errors?.hasCertificate ? (
                  <MDTypography variant="caption" color="error">
                    {errors.hasCertificate}
                  </MDTypography>
                ) : null}

                <Grid item container justifyContent="space-between" xs={12}>
                  <Grid item>
                    <MDButton
                      variant="text"
                      color="info"
                      size="small"
                      onClick={handleCloseCertificationsModal}
                    >
                      Cancelar
                    </MDButton>
                  </Grid>
                  <Grid item>
                    <MDButton
                      color="info"
                      size="small"
                      onClick={() => handleSubmit()}
                    >
                      {isEditCertificationModal ? "Aceptar" : "Agregar"}
                    </MDButton>
                  </Grid>
                </Grid>
              </Card>
            )}
          </Formik>
        </>
      </Modal>
      <Modal
        open={openConfirmationModal}
        onClose={handleCloseConfirmationModal}
      >
        <Card sx={style}>
          <MDTypography variant="h5" fontWeight="medium" color="dark" mb={2}>
            ¿Está seguro que desea eliminar este registro?
          </MDTypography>

          <Grid item container justifyContent="space-between" xs={12}>
            <Grid item>
              <MDButton
                variant="text"
                color="info"
                size="small"
                onClick={handleCloseConfirmationModal}
              >
                Cancelar
              </MDButton>
            </Grid>
            <Grid item>
              <MDButton
                color="info"
                size="small"
                onClick={() => handleDelete()}
              >
                Aceptar
              </MDButton>
            </Grid>
          </Grid>
        </Card>
      </Modal>
    </>
  );
}

export default ProfileStep5;
