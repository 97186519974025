import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBadge from "components/MDBadge";
import CustomModal from "../../components/molecules/modal/modal";
import DataTable from "../../examples/Tables/DataTable";
import MDButton from "../../components/MDButton";
import AddIcon from "@mui/icons-material/Add";
import { convertToCSV } from "../../utils/converter";
import { Formik } from "formik";
import { addAgreementSchema } from "../../helpers/schemas/adminSchemas";
import Input from "../../components/molecules/input/input";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import adminService from "services/api/admin/adminService";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { JobOfferStatus, StudentValidation } from "../../enums";
import AdminService from "services/api/admin/adminService";
import {
  changeDateFormat,
  getEmailList,
  getTasksString,
  isHigherDateThanCurrentDate,
  sortArrayByCurrentDate,
} from "../../utils/dashboard";
import Modal from "@mui/material/Modal";
import MDSnackbar from "../../components/MDSnackbar";
import useAPIError from "../../hooks";
import { getCurrentDate } from "../../utils/dashboard";

const columns = [
  { Header: "Fecha", accessor: "date", align: "left" },
  { Header: "Empresa", accessor: "company", align: "left" },
  { Header: "Alumno", accessor: "student", align: "center" },
  {
    Header: "Duración",
    accessor: "duration",
    align: "center",
  },
  { Header: "Tipo", accessor: "type", align: "center" },
  { Header: "Estado", accessor: "status", align: "center" },
  { Header: "Acciones", accessor: "action", align: "center" },
];

function Agreements() {
  const [openModal, setOpenModal] = useState(false);
  const [openExtensionModal, setOpenExtensionModal] = useState(false);

  const [agreements, setAgreements] = useState([]);
  const [isEditModal, setIsEditModal] = useState(false);
  const [students, setStudents] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [jobOpportunities, setJobOpportunities] = useState([]);
  const [currentCompany, setCurrentCompany] = useState(null);
  const [currentStudent, setCurrentStudent] = useState(null);
  const [currentTeacher, setCurrentTeacher] = useState(null);
  const [currentJobOpportunity, setCurrentJobOpportunity] = useState(null);
  const [currentAgreement, setCurrentAgreement] = useState(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openNotification, setOpenNotification] = useState({
    open: false,
    title: "",
  });
  const { addError } = useAPIError();

  useEffect(() => {
    getStudents();
    getCompanies();
    getTeachers();
    getJobOpportunities();
    getAgreements();
  }, []);

  const getStudents = async () => {
    const response = await adminService.getStudents();

    setStudents(response.data);
  };

  const getTeachers = async () => {
    const response = await adminService.getTeachers();

    setTeachers(response.data);
  };

  const getJobOpportunities = async () => {
    const response = await adminService.getJobOffers();

    setJobOpportunities(response.data);
  };

  const getCompanies = async () => {
    const response = await adminService.getCompanies();

    setCompanies(response.data);
  };

  const getAgreements = async () => {
    const response = await adminService.getAgreements();

    setAgreements(sortArrayByCurrentDate(response.data));
  };

  const onEditAgreement = (id) => {
    setIsEditModal(true);
    const agreement = agreements.find((agreement) => agreement.id === id);
    setCurrentAgreement(agreement);
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setIsEditModal(false);
  };

  const handleChangeCompany = (value) => {
    if (!value) {
      return;
    }
    setCurrentCompany(value);
  };

  const handleChangeStudent = (value) => {
    if (!value) {
      return;
    }
    setCurrentStudent(value);
  };

  const handleChangeTeacher = (value) => {
    if (!value) {
      return;
    }

    setCurrentTeacher(value);
  };

  const handleChangeJobOpportunity = (value) => {
    if (!value) {
      return;
    }

    setCurrentJobOpportunity(value);
  };

  const onAgreementExtension = (id) => {
    const agreement = agreements.find((agreement) => agreement.id === id);
    setCurrentAgreement(agreement);
    setOpenExtensionModal(true);
  };

  const handleApproveAgreementExtension = async (agreementId) => {
    const res = await adminService.acceptAgreementExtension(agreementId);

    if (res.status === 200) {
      setOpenExtensionModal(false);
      setOpenNotification({
        open: true,
        title: "Se ha aceptado la extensión correctamente",
      });
      await getAgreements();
    } else {
      addError(`Algo ha salido mal`, res.status);
    }
  };

  const handleRejectAgreementExtension = async (agreementId) => {
    const res = await adminService.rejectAgreementExtension(agreementId);

    if (res.status === 200) {
      setOpenExtensionModal(false);
      setOpenNotification({
        open: true,
        title: "Se ha rechazado la extensión correctamente",
      });
      await getAgreements();
    } else {
      addError(`Algo ha salido mal`, res.status);
    }
  };

  const rows = agreements.map((agreement) => {
    return {
      date: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {changeDateFormat(agreement.date)}
        </MDTypography>
      ),
      company: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {agreement.company.name}
        </MDTypography>
      ),
      student: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {agreement.student.name + " " + agreement.student.surname}
        </MDTypography>
      ),
      duration: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {agreement.duration} meses
        </MDTypography>
      ),
      type: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent={agreement.internship ? "pasantia" : "empleo efectivo"}
            color={"info"}
            variant="gradient"
            size="sm"
          />
        </MDBox>
      ),
      status: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent={
              isHigherDateThanCurrentDate(agreement.endDate)
                ? "en curso"
                : "finalizado"
            }
            color={
              isHigherDateThanCurrentDate(agreement.endDate)
                ? "success"
                : "error"
            }
            variant="gradient"
            size="sm"
          />
        </MDBox>
      ),
      action: (
        <Grid container spacing={2}>
          {agreement.extensionStatus === "unverified" &&
            agreement.hasExtension && (
              <Grid item>
                <MDTypography
                  component="a"
                  href="#"
                  variant="caption"
                  color="warning"
                  fontWeight="medium"
                  onClick={() => onAgreementExtension(agreement.id)}
                >
                  Extensión a verificar
                </MDTypography>
              </Grid>
            )}
          <Grid item>
            <MDTypography
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              onClick={() => onEditAgreement(agreement.id)}
            >
              Ver convenio
            </MDTypography>
          </Grid>
        </Grid>
      ),
    };
  });

  const formatRepresenativesData = (representatives) => {
    let transformedData = {};

    representatives.forEach((item, index) => {
      const keyPrefix = `representante_empresa_${index + 1}`;

      transformedData[`${keyPrefix}`] = `${item.name} ${item.surname}`;
      transformedData[`${keyPrefix}_CUIT`] = item.cuit;
      transformedData[`${keyPrefix}_ocupacion`] = item.position;
    });

    return transformedData;
  };

  const exportCSV = () => {
    const representatives = formatRepresenativesData(
      currentAgreement.company.representatives
    );

    const data = {
      fecha_convenio: currentAgreement.date.split("T")[0],
      razon_social_empresa: currentAgreement.company.companyPurpose,
      CUIT_empresa: currentAgreement.company.cuit,
      domicilio_empresa: currentAgreement.company.realAddress,
      ciudad_empresa: currentAgreement.company.location.city,
      provincia_empresa: currentAgreement.company.location.province,
      email_empresa: currentAgreement.company.companyEmail,
      nombre_administrador_empresa: currentAgreement.companyAdminName,
      email_sector_administrativo: currentAgreement.adminSectorEmail,
      estudiante:
        currentAgreement.student.name + " " + currentAgreement.student.surname,
      dni_estudiante: currentAgreement.student.dni,
      telefono_estudiante: currentAgreement.student.phone,
      email_estudiante: currentAgreement.student.user.email,
      especialidad_estudiante: currentAgreement.student.career.name,
      legajo_estudiante: currentAgreement.student.id,
      domicilio_estudiante: currentAgreement.student.address,
      localidad_estudiante: currentAgreement.student.location.city,
      provincia_estudiante: currentAgreement.student.location.province,
      duracion_pasantia: `${currentAgreement.duration} meses`,
      fecha_inicio_pasantia: currentAgreement.startDate.split("T")[0],
      fecha_fin_pasantia: currentAgreement.endDate.split("T")[0],
      dias_de_trabajo: currentAgreement.laborDays,
      horas_semanales: currentAgreement.weeklyHours,
      hora_inicio: currentAgreement.startHour,
      hora_fin: currentAgreement.endHour,
      domicilio_trabajo: currentAgreement.workAddress,
      localidad_trabajo: currentAgreement.city,
      provincia_trabajo: currentAgreement.province,
      area_trabajo: currentAgreement.jobOpportunity.area.name,
      actividades_trabajo: getTasksString(
        currentAgreement.jobOpportunity.tasks
      ),
      estimulo: currentAgreement.salary,
      canon_administrativo: currentAgreement.administrativeCanon,
      tutor_empresa: currentAgreement.companyTutorName,
      dni_tutor_empresa: currentAgreement.companyTutorDni,
      docente_supervisor:
        currentAgreement.teacher.name + " " + currentAgreement.teacher.surname,
      cuil_docente_supervisor: currentAgreement.teacher.cuil,
      legajo_docente_supervisor: currentAgreement.teacher.id,
      email_docente_supervisor: currentAgreement.teacher.email,
      email_administracion: "administracion@sanfrancisco.utn.edu.ar",
      email_departamento: getEmailList(currentAgreement.student.career.id),
    };

    const csv = convertToCSV([{ ...data, ...representatives }]);

    // You can use the following code to create a download link for the CSV
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const initialValuesDecider = () => {
    if (isEditModal) {
      return {
        date: currentAgreement.date.split("T")[0],
        duration: currentAgreement.duration,
        startDate: currentAgreement.startDate.split("T")[0],
        endDate: currentAgreement.endDate.split("T")[0],
        laborDays: currentAgreement.laborDays,
        weeklyHours: currentAgreement.weeklyHours,
        startHour: currentAgreement.startHour,
        endHour: currentAgreement.endHour,
        workAddress: currentAgreement.workAddress,
        city: currentAgreement.city,
        province: currentAgreement.province,
        salary: currentAgreement.salary,
        administrativeCanon: currentAgreement.administrativeCanon,
        companyTutorName: currentAgreement.companyTutorName,
        companyTutorDni: currentAgreement.companyTutorDni,
        companyAdminName: currentAgreement.companyAdminName,
        adminSectorEmail: currentAgreement.adminSectorEmail,
        internship: currentAgreement.internship,
      };
    } else {
      return {
        date: getCurrentDate(),
        duration: "",
        startDate: getCurrentDate(),
        endDate: getCurrentDate(),
        laborDays: "",
        weeklyHours: "",
        startHour: "00:00",
        endHour: "00:00",
        workAddress: "",
        city: "",
        province: "",
        salary: "",
        administrativeCanon: "",
        companyTutorName: "",
        companyTutorDni: "",
        companyAdminName: "",
        adminSectorEmail: "",
        internship: true,
      };
    }
  };

  const addAgreement = async (values) => {
    const company = companies.filter((company) => {
      return currentCompany === company.name;
    });

    const res = await AdminService.createAgreement({
      ...values,
      companyId: company[0].id,
      studentId: currentStudent,
      teacherId: parseInt(currentTeacher.match(/\d+/g)[0]),
      jobOpportunityId: currentJobOpportunity,
    });
    if (res.status === 201) {
      handleCloseModal();
      setOpenNotification({
        open: true,
        title: "Convenio creado correctamente",
      });
      await getAgreements();
    } else {
      addError(`Algo ha salido mal`, res.status);
    }
  };

  const deleteAgreement = async (agreementId) => {
    const res = await AdminService.deleteAgreement(agreementId);
    if (res.status === 200) {
      handleCloseModal();
      setOpenConfirmationModal(false);
      setOpenNotification({
        open: true,
        title: "Convenio eliminado correctamente",
      });
      await getAgreements();
    } else {
      addError(`Algo ha salido mal`, res.status);
    }
  };

  const companyJobOpportunities = () => {
    return jobOpportunities.filter((jobOpportunity) => {
      return (
        currentCompany === jobOpportunity.company.name &&
        jobOpportunity.status === JobOfferStatus.PUBLISHED
      );
    });
  };

  const updateAgreement = (values) => {
    setIsEditModal(false);
    setOpenModal(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDSnackbar
        icon="notifications"
        title={openNotification.title}
        content=""
        open={openNotification.open}
        color={"success"}
        close={() => setOpenNotification({ open: false, title: "" })}
      />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDTypography variant="h3">
              Convenios de pasantía / empleo efectivo
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="contained"
                bgColor="dark"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container>
                  <Grid item>
                    <MDButton
                      color="light"
                      variant="outlined"
                      fullWidth
                      onClick={() => setOpenModal(true)}
                    >
                      <AddIcon />
                      &nbsp; Agregar nuevo convenio
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3} pb={3}>
                {agreements.length > 0 ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                ) : (
                  <MDTypography variant="caption" ml={3}>
                    Aún no hay convenios
                  </MDTypography>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <CustomModal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-agreements"
        aria-describedby="modal-modal-agreements"
      >
        <MDTypography mb={2} variant="h5" fontWeight="medium" color="dark">
          {isEditModal
            ? `Convenio empresa ${currentAgreement.company.name} - estudiante ${
                currentAgreement.student.name +
                " " +
                currentAgreement.student.surname
              }`
            : "Agregar nuevo convenio"}
          {isEditModal &&
            currentAgreement?.extension &&
            currentAgreement.extensionStatus === "accepted" && (
              <MDBadge
                badgeContent={`Solicitud de extensión aceptada (${currentAgreement?.extension} meses)`}
                color={"success"}
                variant="gradient"
                size="sm"
              />
            )}
        </MDTypography>
        {isEditModal && (
          <Grid container mb={3}>
            <MDButton color="success" size="medium" onClick={() => exportCSV()}>
              Exportar datos como CSV
            </MDButton>
          </Grid>
        )}
        <Formik
          initialValues={initialValuesDecider()}
          validationSchema={addAgreementSchema}
          validateOnChange={true}
          onSubmit={isEditModal ? updateAgreement : addAgreement}
          enableReinitialize={true}
        >
          {({ errors, values, setFieldValue, handleSubmit, touched }) => (
            <Grid container mt={2} mb={2}>
              <Grid item container spacing={1} xs={12} mb={2}>
                <Grid item xs={6}>
                  <Autocomplete
                    id="company"
                    freeSolo
                    onChange={(event, newValue) => {
                      handleChangeCompany(newValue);
                    }}
                    value={
                      isEditModal
                        ? currentAgreement.company.name
                        : currentCompany
                    }
                    disabled={isEditModal}
                    options={companies.map((option) => option.name)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Buscar empresa (Nombre)..."
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    id="jobOpportunity"
                    freeSolo
                    onChange={(event, newValue) => {
                      handleChangeJobOpportunity(newValue);
                    }}
                    value={
                      isEditModal
                        ? currentAgreement.jobOpportunity.id
                        : currentJobOpportunity
                    }
                    disabled={
                      (!isEditModal && !currentCompany) ||
                      companyJobOpportunities().map((option) => option.id)
                        .length === 0
                    }
                    options={companyJobOpportunities().map(
                      (option) => option.id
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="ID de Oportunidad de trabajo"
                      />
                    )}
                    getOptionLabel={(option) => option.toString()}
                  />
                </Grid>
              </Grid>

              <Grid item container spacing={1} xs={12} mb={2}>
                <Grid item xs={6}>
                  <Autocomplete
                    id="student"
                    freeSolo
                    onChange={(event, newValue) => {
                      handleChangeStudent(newValue);
                    }}
                    value={
                      isEditModal ? currentAgreement.student.id : currentStudent
                    }
                    disabled={isEditModal}
                    options={students.map((option) => option.id)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Buscar estudiante (Legajo)..."
                      />
                    )}
                    getOptionLabel={(option) => option.toString()}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    id="teacher"
                    freeSolo
                    onChange={(event, newValue) => {
                      handleChangeTeacher(newValue);
                    }}
                    value={
                      isEditModal
                        ? currentAgreement.teacher.name +
                          " " +
                          currentAgreement.teacher.surname +
                          " (Legajo: " +
                          currentAgreement.teacher.id +
                          ")"
                        : currentTeacher
                    }
                    disabled={isEditModal}
                    options={teachers.map(
                      (option) =>
                        option.name +
                        " " +
                        option.surname +
                        " (Legajo: " +
                        option.id +
                        ")"
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="Buscar tutor (Nombre)..." />
                    )}
                    getOptionLabel={(option) => option.toString()}
                  />
                </Grid>
              </Grid>

              <Grid item container spacing={1} xs={12} mb={1}>
                <Grid item xs={6}>
                  <Input
                    type={"date"}
                    label={"Fecha convenio"}
                    fullWidth={true}
                    onChange={(e) => setFieldValue("date", e.target.value)}
                    value={values.date}
                    error={!!errors.date && touched.date}
                    errorText={errors.date}
                    disabled={isEditModal}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Input
                    type={"number"}
                    label={"Duración (en meses)"}
                    fullWidth={true}
                    onChange={(e) => setFieldValue("duration", e.target.value)}
                    value={values.duration}
                    error={!!errors.duration && touched.duration}
                    errorText={errors.duration}
                    disabled={isEditModal}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={1} xs={12}>
                <Grid item xs={6} mb={1}>
                  <Input
                    type={"date"}
                    label={"Fecha inicio"}
                    fullWidth={true}
                    onChange={(e) => setFieldValue("startDate", e.target.value)}
                    value={values.startDate}
                    error={!!errors.startDate && touched.startDate}
                    errorText={errors.startDate}
                    disabled={isEditModal}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    type={"date"}
                    label={"Fecha fin"}
                    fullWidth={true}
                    onChange={(e) => setFieldValue("endDate", e.target.value)}
                    value={values.endDate}
                    error={!!errors.endDate && touched.endDate}
                    errorText={errors.endDate}
                    disabled={isEditModal}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={1} xs={12} mb={1}>
                <Grid item xs={6}>
                  <Input
                    type={"text"}
                    label={"Días de trabajo"}
                    fullWidth={true}
                    onChange={(e) => setFieldValue("laborDays", e.target.value)}
                    value={values.laborDays}
                    error={!!errors.laborDays && touched.laborDays}
                    errorText={errors.laborDays}
                    disabled={isEditModal}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Input
                    type={"number"}
                    label={"Horas semanales"}
                    fullWidth={true}
                    onChange={(e) =>
                      setFieldValue("weeklyHours", e.target.value)
                    }
                    value={values.weeklyHours}
                    error={!!errors.weeklyHours && touched.weeklyHours}
                    errorText={errors.weeklyHours}
                    disabled={isEditModal}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={1} xs={12} mb={1}>
                <Grid item xs={6}>
                  <Input
                    type={"time"}
                    label={"Hora inicio"}
                    fullWidth={true}
                    onChange={(e) => setFieldValue("startHour", e.target.value)}
                    value={values.startHour}
                    error={!!errors.startHour && touched.startHour}
                    errorText={errors.startHour}
                    disabled={isEditModal}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Input
                    type={"time"}
                    label={"Hora fin"}
                    fullWidth={true}
                    onChange={(e) => setFieldValue("endHour", e.target.value)}
                    value={values.endHour}
                    error={!!errors.endHour && touched.endHour}
                    errorText={errors.endHour}
                    disabled={isEditModal}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={1} xs={12} mb={1}>
                <Grid item xs={4}>
                  <Input
                    type={"text"}
                    label={"Domicilio trabajo"}
                    fullWidth={true}
                    onChange={(e) =>
                      setFieldValue("workAddress", e.target.value)
                    }
                    value={values.workAddress}
                    error={!!errors.workAddress && touched.workAddress}
                    errorText={errors.workAddress}
                    disabled={isEditModal}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Input
                    type={"text"}
                    label={"Localidad trabajo"}
                    fullWidth={true}
                    onChange={(e) => setFieldValue("city", e.target.value)}
                    value={values.city}
                    error={!!errors.city && touched.city}
                    errorText={errors.city}
                    disabled={isEditModal}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Input
                    type={"text"}
                    label={"Provincia trabajo"}
                    fullWidth={true}
                    onChange={(e) => setFieldValue("province", e.target.value)}
                    value={values.province}
                    error={!!errors.province && touched.province}
                    errorText={errors.province}
                    disabled={isEditModal}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={1} xs={12} mb={1}>
                <Grid item xs={6}>
                  <Input
                    type={"number"}
                    label={"Salario"}
                    fullWidth={true}
                    onChange={(e) => setFieldValue("salary", e.target.value)}
                    value={values.salary}
                    error={!!errors.salary && touched.salary}
                    errorText={errors.salary}
                    disabled={isEditModal}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    type={"number"}
                    label={"Canon administrativo"}
                    fullWidth={true}
                    onChange={(e) =>
                      setFieldValue("administrativeCanon", e.target.value)
                    }
                    value={values.administrativeCanon}
                    error={
                      !!errors.administrativeCanon &&
                      touched.administrativeCanon
                    }
                    errorText={errors.administrativeCanon}
                    disabled={isEditModal}
                  />
                </Grid>
              </Grid>

              <Grid item container spacing={1} xs={12} mb={1}>
                <Grid item xs={6}>
                  <Input
                    type={"text"}
                    label={"Tutor Empresa"}
                    fullWidth={true}
                    onChange={(e) =>
                      setFieldValue("companyTutorName", e.target.value)
                    }
                    value={values.companyTutorName}
                    error={
                      !!errors.companyTutorName && touched.companyTutorName
                    }
                    errorText={errors.companyTutorName}
                    disabled={isEditModal}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    type={"number"}
                    label={"DNI Tutor Empresa"}
                    fullWidth={true}
                    onChange={(e) =>
                      setFieldValue("companyTutorDni", e.target.value)
                    }
                    value={values.companyTutorDni}
                    error={!!errors.companyTutorDni && touched.companyTutorDni}
                    errorText={errors.companyTutorDni}
                    disabled={isEditModal}
                  />
                </Grid>
              </Grid>

              <Grid item container spacing={1} xs={12} mb={1}>
                <Grid item xs={6}>
                  <Input
                    type={"text"}
                    label={"Nombre administrador empresa"}
                    fullWidth={true}
                    onChange={(e) =>
                      setFieldValue("companyAdminName", e.target.value)
                    }
                    value={values.companyAdminName}
                    error={
                      !!errors.companyAdminName && touched.companyAdminName
                    }
                    errorText={errors.companyAdminName}
                    disabled={isEditModal}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    type={"email"}
                    label={"Email sector administrativo"}
                    fullWidth={true}
                    onChange={(e) =>
                      setFieldValue("adminSectorEmail", e.target.value)
                    }
                    value={values.adminSectorEmail}
                    error={
                      !!errors.adminSectorEmail && touched.adminSectorEmail
                    }
                    errorText={errors.adminSectorEmail}
                    disabled={isEditModal}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} mb={5}>
                <MDTypography variant="button">Tipo</MDTypography>
                <RadioGroup
                  row
                  name="is-internship-radio-buttons-group"
                  value={values.internship}
                  onChange={(e) => setFieldValue("internship", e.target.value)}
                >
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Empleo efectivo"
                    disabled={isEditModal}
                  />
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Pasantía"
                    disabled={isEditModal}
                  />
                </RadioGroup>
                {errors?.internship && touched.internship ? (
                  <MDTypography variant="caption" color="error">
                    {errors.internship}
                  </MDTypography>
                ) : null}
              </Grid>
              {currentAgreement?.studentEvaluation && (
                <Grid item xs={12}>
                  <MDTypography variant="h6">
                    Validación de empresa del estudiante
                  </MDTypography>
                  <MDTypography variant="button" color="text">
                    {StudentValidation[currentAgreement.studentEvaluation]}
                  </MDTypography>
                </Grid>
              )}

              <Grid
                item
                container
                justifyContent="space-between"
                xs={12}
                mt={4}
              >
                <Grid item>
                  <MDButton
                    variant="text"
                    color="info"
                    size="small"
                    onClick={handleCloseModal}
                  >
                    Cancelar
                  </MDButton>
                </Grid>
                <Grid item>
                  <MDButton
                    color={isEditModal ? "error" : "info"}
                    size="small"
                    onClick={() => {
                      isEditModal
                        ? setOpenConfirmationModal(true)
                        : handleSubmit();
                    }}
                  >
                    {isEditModal ? "Eliminar" : "Agregar"}
                  </MDButton>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Formik>
      </CustomModal>
      <Modal
        open={openConfirmationModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-confirmation"
        aria-describedby="modal-confirmation"
      >
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            p: 4,
          }}
        >
          <MDTypography variant="h5" fontWeight="medium" color="dark" mb={3}>
            Está seguro que desea eliminar el acuerdo?
          </MDTypography>
          <Grid item container justifyContent="space-between" xs={12}>
            <Grid item>
              <MDButton
                variant="text"
                color="info"
                size="small"
                onClick={() => setOpenConfirmationModal(false)}
              >
                Cancelar
              </MDButton>
            </Grid>
            <Grid item>
              <MDButton
                color="info"
                size="small"
                onClick={() => deleteAgreement(currentAgreement.id)}
              >
                Aceptar
              </MDButton>
            </Grid>
          </Grid>
        </Card>
      </Modal>
      <CustomModal
        open={openExtensionModal}
        onClose={() => setOpenExtensionModal(false)}
        aria-labelledby="student-extension-modal"
        height={"auto"}
      >
        <MDTypography variant="h3" mb={3}>
          Revisar solicitud extensión de convenio
        </MDTypography>

        <MDTypography variant="button" mb={3}>
          La solicitud de extensión de la empresa es de{" "}
          {currentAgreement?.extension} meses. Desea aceptar la solicitud?
        </MDTypography>
        <Grid item container justifyContent="end" xs={12} mt={4} spacing={2}>
          <Grid item>
            <MDButton
              variant="text"
              color="info"
              size="small"
              onClick={() => setOpenExtensionModal(false)}
            >
              Cancelar
            </MDButton>
          </Grid>
          <Grid item>
            <MDButton
              color="error"
              size="small"
              onClick={() =>
                handleRejectAgreementExtension(currentAgreement?.id)
              }
            >
              Rechazar
            </MDButton>
          </Grid>
          <Grid item>
            <MDButton
              color="success"
              size="small"
              onClick={() =>
                handleApproveAgreementExtension(currentAgreement?.id)
              }
            >
              Aceptar
            </MDButton>
          </Grid>
        </Grid>
      </CustomModal>
    </DashboardLayout>
  );
}

export default Agreements;
