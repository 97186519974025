import * as Yup from "yup";

export const addJobOfferSchema = Yup.object().shape({
  studentCondition: Yup.string().required("Este campo es requerido"),
  gender: Yup.string().required("Este campo es requerido"),
  ageRage: Yup.string().required("Este campo es requerido"),
  english: Yup.boolean().required("Este campo es requerido"),
  internship: Yup.boolean().required("Este campo es requerido"),
  closeDate: Yup.string().required("Este campo es requerido"),
  area: Yup.string().required("Este campo es requerido"),
  address: Yup.string().required("Este campo es requerido"),
  showCompanyName: Yup.boolean().required("Este campo es requerido"),
  salary: Yup.string().required("Este campo es requerido"),
  otherBenefits: Yup.string().required("Este campo es requerido"),
  schedule: Yup.string().required("Este campo es requerido"),
});
