import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import { useEffect, useState } from "react";
import AdminService from "../../services/api/admin/adminService";
import StatisticsCard from "../../components/molecules/card/StatisticsCard";
import MDButton from "../../components/MDButton";
import { changeDateFormat } from "../../utils/dashboard";

function Dashboard() {
  const [students, setStudents] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [jobOpportunities, setJobOpportunities] = useState([]);
  const [agreements, setAgreements] = useState([]);

  useEffect(() => {
    getStudents();
    getCompanies();
    getJobOpportunities();
    getAgreements();
  }, []);

  const getStudents = async () => {
    const response = await AdminService.getStudents();

    setStudents(response.data);
  };

  const getCompanies = async () => {
    const response = await AdminService.getCompanies();

    setCompanies(response.data);
  };

  const createTeacher = async () => {
    const response = await AdminService.createTeacher();
  };

  const getJobOpportunities = async () => {
    const response = await AdminService.getJobOffers();

    setJobOpportunities(response.data);
  };

  const getAgreements = async () => {
    const response = await AdminService.getAgreements();

    setAgreements(response.data);
  };

  const generateChartData = (agreements) => {
    const currentYear = new Date().getFullYear();
    const chartData = Array(12).fill(0); // Initialize an array with zeros for each month

    agreements.forEach((agreement) => {
      const agreementDate = new Date(agreement.date.split("T")[0]);
      const agreementYear = agreementDate.getFullYear();
      const agreementMonth = agreementDate.getMonth();

      // Check if the year of the object matches the current year
      if (agreementYear === currentYear) {
        // Increment the count for the corresponding month
        chartData[agreementMonth]++;
      }
    });

    return chartData;
  };

  const chart1Data = {
    labels: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ],
    datasets: {
      label: "Convenios",
      data: generateChartData(agreements),
    },
  };

  function countStudentsByCareer(students) {
    // Create an array to store the counts for each career ID
    const careerCounts = [0, 0, 0, 0, 0, 0, 0];

    // Loop through the careerArray and increment the counts for the corresponding career ID
    for (const student of students) {
      const careerId = student.career.id;
      careerCounts[careerId - 1]++; // Decrement by 1 to match the zero-based index
    }

    return careerCounts;
  }

  function getAgreementDatesOneMonthBefore(agreements) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    const filteredAgreements = agreements
      .filter((agreement) => {
        const agreementDate = new Date(agreement.endDate.split("T")[0]);
        const agreementYear = agreementDate.getFullYear();
        const agreementMonth = agreementDate.getMonth();
        const agreementDay = agreementDate.getDate();

        return (
          agreementYear === currentYear &&
          agreementMonth === currentMonth &&
          agreementDay > currentDay
        );
      })
      .map(
        (agreement) =>
          `Empresa: ${agreement.company.name} - Estudiante: ${
            agreement.student.name
          } ${agreement.student.surname} - Fecha: ${changeDateFormat(
            agreement.endDate
          )}`
      );

    return filteredAgreements;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <MDBox mb={1.5}>
              <StatisticsCard
                color="dark"
                icon="work_history"
                title="Oportunidades de pasantía / empleo efectivo"
                count={jobOpportunities.length}
                action={{
                  text: "Ver más",
                  link: "/admin/dashboard/job-offers",
                  color: "dark",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox mb={1.5}>
              <StatisticsCard
                icon="work"
                title="Convenios"
                count={agreements.length}
                action={{
                  text: "Ver más",
                  link: "/admin/dashboard/agreements",
                  color: "dark",
                }}
                items={getAgreementDatesOneMonthBefore(agreements)}
                warningText={"Próximos convenios a vencer"}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox mb={1.5}>
              <StatisticsCard
                color="success"
                icon="group_add"
                title="Cantidad de empresas registradas"
                count={companies.length}
                action={{
                  text: "Ver más",
                  link: "/admin/dashboard/companies",
                  color: "dark",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox mb={1.5}>
              <StatisticsCard
                color="primary"
                icon="group_add"
                title="Cantidad de estudiantes registrados"
                count={students.length}
                action={{
                  text: "Ver más",
                  link: "/admin/dashboard/students",
                  color: "dark",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="Cantidad de convenios efectuados a lo largo del año"
                  chart={chart1Data}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="Cantidad de estudiantes por especialidad"
                  chart={{
                    labels: [
                      "Ing. en Sistemas",
                      "Ing. Química",
                      "Ing. Electrónica",
                      "Ing. Electromecánica",
                      "Ing. Industrial",
                      "Lic. en Adm. Rural",
                      "Tec. en Programación",
                    ],
                    datasets: {
                      label: "Cantidad de estudiantes",
                      data: countStudentsByCareer(students),
                    },
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
