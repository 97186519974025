import React from "react";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

function StudentOnboardingStep4({ setFieldValue, errors, values, touched }) {
  return (
    <Grid container mt={3}>
      <Grid mt={3} item xs={12}>
        <MDTypography variant="h6" fontWeight="medium" color="dark">
          Datos de salud
        </MDTypography>
      </Grid>
      <Grid item mt={0} xs={12} md={7}>
        <MDTypography variant="button">Tiene obra social</MDTypography>
        <RadioGroup
          row
          name="health-insurance-radio-buttons-group"
          value={values.hasHealthInsurance}
          onChange={(e) => setFieldValue("hasHealthInsurance", e.target.value)}
        >
          <FormControlLabel value={true} control={<Radio />} label="Si" />
          <FormControlLabel value={false} control={<Radio />} label="No" />
        </RadioGroup>
        {errors?.hasHealthInsurance ? (
          <MDTypography variant="caption" color="error">
            {errors.hasHealthInsurance}
          </MDTypography>
        ) : null}
        {values.hasHealthInsurance === "true" ||
        values.hasHealthInsurance === true ? (
          <MDBox mt={1} mb={4}>
            <MDInput
              type="text"
              label="Obra social"
              fullWidth
              value={values.healthInsurance}
              onChange={(e) => setFieldValue("healthInsurance", e.target.value)}
              error={!!errors.healthInsurance}
            />
            {errors?.healthInsurance ? (
              <MDTypography variant="caption" color="error">
                {errors.healthInsurance}
              </MDTypography>
            ) : null}
          </MDBox>
        ) : null}
        <MDBox mt={2} mb={2}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Grupo sanguíneo
            </InputLabel>
            <Select
              labelId="blood-type-select"
              id="blood-type-select"
              value={values.bloodType}
              label="Tipo de sangre"
              onChange={(e) => setFieldValue("bloodType", e.target.value)}
            >
              <MenuItem value={1}>0 negativo</MenuItem>
              <MenuItem value={2}>0 positivo</MenuItem>
              <MenuItem value={3}>A negativo</MenuItem>
              <MenuItem value={4}>A positivo</MenuItem>
              <MenuItem value={5}>B negativo</MenuItem>
              <MenuItem value={6}>B positivo</MenuItem>
              <MenuItem value={7}>AB negativo</MenuItem>
              <MenuItem value={8}>AB positivo</MenuItem>
            </Select>
            {errors?.bloodType ? (
              <MDTypography variant="caption" color="error">
                {errors.bloodType}
              </MDTypography>
            ) : null}
          </FormControl>
        </MDBox>
        <MDTypography variant="button">Es donante de sangre</MDTypography>
        <RadioGroup
          row
          name="health-insurance-radio-buttons-group"
          value={values.bloodDonor}
          onChange={(e) => setFieldValue("bloodDonor", e.target.value)}
        >
          <FormControlLabel value={true} control={<Radio />} label="Si" />
          <FormControlLabel value={false} control={<Radio />} label="No" />
        </RadioGroup>
        {errors?.bloodDonor ? (
          <MDTypography variant="caption" color="error">
            {errors.bloodDonor}
          </MDTypography>
        ) : null}
      </Grid>
    </Grid>
  );
}

export default StudentOnboardingStep4;
