import localStorageService from "./localStorageService";

class AuthStorage {
  setSession(token, role, onboardingCompleted) {
    localStorageService.set("auth-token", token);
    localStorageService.set("role", role);
    localStorageService.set("onboardingCompleted", onboardingCompleted);
  }

  getSession() {
    return localStorageService.get("auth-token");
  }

  getSessionUserRole() {
    return localStorageService.get("role");
  }
  isOnboardingCompleted() {
    return localStorageService.get("onboardingCompleted");
  }

  deleteSession() {
    localStorageService.remove("auth-token");
    localStorageService.remove("role");
    localStorageService.remove("onboardingCompleted");
  }
}

export default new AuthStorage();
