import { Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { StudentSignupSchema } from "helpers/schemas/authSchemas";
import Input from "components/molecules/input/input";
import Grid from "@mui/material/Grid";
import Auth from "../../../services/api/auth";
import useAPIError from "../../../hooks";

function StudentSignUp() {
  const navigate = useNavigate();
  const { addError } = useAPIError();

  const onSubmit = async (values) => {
    const response = await Auth.studentSignUp(values);
    if (response.status === 201) {
      navigate("/" + response.data.role + "/onboarding");
    } else {
      addError(`${response.data.message}`, response.status);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Grid item xs={10}>
        <MDBox textAlign="center">
          <MDTypography variant="h4" fontWeight="medium" color="dark" mt={1}>
            Registro Estudiante
          </MDTypography>
        </MDBox>
      </Grid>
      <Grid item xs={10} sm={7}>
        <Formik
          initialValues={{
            name: "",
            surname: "",
            studentId: "",
            dni: "",
            email: "",
            password: "",
          }}
          validationSchema={StudentSignupSchema}
          validateOnChange={true}
          onSubmit={onSubmit}
        >
          {({ errors, setFieldValue, handleSubmit, touched }) => (
            <MDBox pt={2} pb={3}>
              <Input
                type={"text"}
                label={"Nombre"}
                fullWidth={true}
                onChange={(e) => setFieldValue("name", e.target.value)}
                error={!!errors.name && touched.name}
                errorText={errors.name}
              />
              <Input
                type={"text"}
                label={"Apellido"}
                fullWidth={true}
                onChange={(e) => setFieldValue("surname", e.target.value)}
                error={!!errors.surname && touched.surname}
                errorText={errors.surname}
              />
              <Input
                type={"number"}
                label={"Legajo"}
                fullWidth={true}
                onChange={(e) => setFieldValue("studentId", e.target.value)}
                error={!!errors.studentId && touched.studentId}
                errorText={errors.studentId}
              />
              <Input
                type={"number"}
                label={"Dni"}
                fullWidth={true}
                onChange={(e) => setFieldValue("dni", e.target.value)}
                error={!!errors.dni && touched.dni}
                errorText={errors.dni}
              />
              <Input
                type={"email"}
                label={"Email"}
                fullWidth={true}
                onChange={(e) => setFieldValue("email", e.target.value)}
                error={!!errors.email && touched.email}
                errorText={errors.email}
              />
              <Input
                type={"password"}
                label={"Contraseña"}
                fullWidth={true}
                onChange={(e) => setFieldValue("password", e.target.value)}
                error={!!errors.password && touched.password}
                errorText={errors.password}
              />
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={() => handleSubmit()}
                >
                  Registrarse
                </MDButton>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  Ya tienes una cuenta?{" "}
                  <MDTypography
                    component={Link}
                    to="/auth/signin"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Iniciar sesión
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          )}
        </Formik>
      </Grid>
    </BasicLayout>
  );
}

export default StudentSignUp;
