import * as Yup from "yup";

export const addAgreementSchema = Yup.object().shape({
  date: Yup.string().required("Este campo es requerido"),
  duration: Yup.number()
    .min(2, "Debe ser mayor o igual que 2")
    .max(12, "Debe ser menor o igual que 12")
    .required("Este campo es requerido"),
  startDate: Yup.string().required("Este campo es requerido"),
  endDate: Yup.string().required("Este campo es requerido"),
  laborDays: Yup.string().required("Este campo es requerido"),
  weeklyHours: Yup.number().required("Este campo es requerido"),
  startHour: Yup.string().required("Este campo es requerido"),
  endHour: Yup.string().required("Este campo es requerido"),
  workAddress: Yup.string().required("Este campo es requerido"),
  city: Yup.string().required("Este campo es requerido"),
  province: Yup.string().required("Este campo es requerido"),
  salary: Yup.number().required("Este campo es requerido"),
  administrativeCanon: Yup.number().required("Este campo es requerido"),
  companyTutorName: Yup.string().required("Este campo es requerido"),
  companyTutorDni: Yup.number().required("Este campo es requerido"),
  companyAdminName: Yup.string().required("Este campo es requerido"),
  adminSectorEmail: Yup.string().required("Este campo es requerido"),
  internship: Yup.boolean().required("Este campo es requerido"),
});

export const addTeacherSchema = Yup.object().shape({
  id: Yup.number().required("Este campo es requerido"),
  cuil: Yup.number().required("Este campo es requerido"),
  email: Yup.string().email("Email inválido").required("Este campo es requerido"),
  name: Yup.string().required("Este campo es requerido"),
  surname: Yup.string().required("Este campo es requerido"),
});