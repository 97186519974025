import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBadge from "components/MDBadge";
import CustomModal from "../../../components/molecules/modal/modal";
import companyService from "../../../services/api/company/companyService";
import DataTable from "../../../examples/Tables/DataTable";
import {
  changeDateFormat,
  isHigherDateThanCurrentDate,
  sortArrayByCurrentDate,
} from "../../../utils/dashboard";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import MDButton from "../../../components/MDButton";
import MDSnackbar from "../../../components/MDSnackbar";
import useAPIError from "../../../hooks";
import { StudentValidation } from "../../../enums";
import Input from "../../../components/molecules/input/input";
import * as Yup from "yup";
import { Formik } from "formik";

const columns = [
  { Header: "Fecha", accessor: "date", align: "left" },
  { Header: "Estudiante", accessor: "student", align: "center" },
  {
    Header: "Duración",
    accessor: "duration",
    align: "center",
  },
  { Header: "Tipo", accessor: "type", align: "center" },
  { Header: "Estado", accessor: "status", align: "center" },
  { Header: "Acciones", accessor: "action", align: "center" },
];

function Agreements() {
  const [openModal, setOpenModal] = useState(false);
  const [openValidationModal, setOpenValidationModal] = useState(false);
  const [openExtensionModal, setOpenExtensionModal] = useState(false);
  const [agreements, setAgreements] = useState([]);
  const [currentAgreement, setCurrentAgreement] = useState(null);
  const [validation, setValidation] = useState("");
  const [openNotification, setOpenNotification] = useState({
    open: false,
    title: "",
  });
  const { addError } = useAPIError();

  useEffect(() => {
    getAgreements();
  }, []);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const getAgreements = async () => {
    const response = await companyService.getAgreements();

    setAgreements(sortArrayByCurrentDate(response.data));
  };

  const handleOpenAgreement = async (id) => {
    const agreement = agreements.find((agreement) => agreement.id === id);
    setCurrentAgreement(agreement);
    setOpenModal(true);
  };

  const onValidateStudent = (id) => {
    const agreement = agreements.find((agreement) => agreement.id === id);
    setCurrentAgreement(agreement);
    setOpenValidationModal(true);
  };

  const onAgreementExtension = (id) => {
    const agreement = agreements.find((agreement) => agreement.id === id);
    setCurrentAgreement(agreement);
    setOpenExtensionModal(true);
  };

  const handleValidationChange = (event) => {
    setValidation(event.target.value);
  };

  const handleSubmitValidation = async () => {
    const res = await companyService.validateStudent(currentAgreement?.id, {
      validation,
    });

    if (res.status === 200) {
      setOpenValidationModal(false);
      setOpenNotification({
        open: true,
        title: "Validación enviada correctamente",
      });
      getAgreements();
    } else {
      addError(`Algo ha salido mal`, res.status);
    }
  };

  const handleSubmitAgreementExtension = async (values) => {
    const res = await companyService.extendAgreement(currentAgreement?.id, {
      extension: values.extension,
    });

    if (res.status === 200) {
      setOpenExtensionModal(false);
      setOpenNotification({
        open: true,
        title: "Extensión enviada correctamente",
      });
      getAgreements();
    } else {
      addError(`Algo ha salido mal`, res.status);
    }
  };

  const rows = agreements.map((agreement) => {
    return {
      date: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {changeDateFormat(agreement.date)}
        </MDTypography>
      ),
      student: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {agreement.student.name + " " + agreement.student.surname}
        </MDTypography>
      ),
      duration: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {agreement.duration} meses
        </MDTypography>
      ),
      type: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent={agreement.internship ? "pasantia" : "empleo efectivo"}
            color={"info"}
            variant="gradient"
            size="sm"
          />
        </MDBox>
      ),
      status: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent={
              isHigherDateThanCurrentDate(agreement.endDate)
                ? "en curso"
                : "finalizado"
            }
            color={
              isHigherDateThanCurrentDate(agreement.endDate)
                ? "success"
                : "error"
            }
            variant="gradient"
            size="sm"
          />
        </MDBox>
      ),
      action: (
        <Grid container spacing={2}>
          {!isHigherDateThanCurrentDate(agreement.endDate) &&
            agreement.studentEvaluation === null && (
              <Grid item>
                <MDTypography
                  component="a"
                  href="#"
                  variant="caption"
                  color="success"
                  fontWeight="medium"
                  onClick={() => onValidateStudent(agreement.id)}
                >
                  Validar estudiante
                </MDTypography>
              </Grid>
            )}
          {!isHigherDateThanCurrentDate(agreement.endDate) &&
            !agreement.hasExtension && (
              <Grid item>
                <MDTypography
                  component="a"
                  href="#"
                  variant="caption"
                  color="info"
                  fontWeight="medium"
                  onClick={() => onAgreementExtension(agreement.id)}
                >
                  Solicitar extensión
                </MDTypography>
              </Grid>
            )}

          <Grid item>
            <MDTypography
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              onClick={() => handleOpenAgreement(agreement.id)}
            >
              Ver convenio
            </MDTypography>
          </Grid>
        </Grid>
      ),
    };
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDSnackbar
        icon="notifications"
        title={openNotification.title}
        content=""
        open={openNotification.open}
        color={"success"}
        close={() =>
          setOpenNotification({
            open: false,
            title: "",
          })
        }
      />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDTypography variant="h3">
              Convenios de pasantía / empleo efectivo
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3} pb={3}>
                {agreements.length > 0 ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                ) : (
                  <MDTypography variant="caption" ml={3}>
                    Aún no hay convenios
                  </MDTypography>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <CustomModal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="company-agreement-info-modal"
      >
        <MDTypography variant="h3" color="info">
          Convenio - Estudiante {currentAgreement?.student.name}{" "}
          {currentAgreement?.student.surname}
        </MDTypography>

        <Grid container spacing={1} mt={2}>
          {currentAgreement?.extension &&
            currentAgreement.extensionStatus === "unverified" && (
              <MDBadge
                badgeContent={`Solicitud de extensión enviada (${currentAgreement?.extension} meses) - EN VALIDACIÓN`}
                color={"warning"}
                variant="gradient"
                size="sm"
              />
            )}
          {currentAgreement?.extension &&
            currentAgreement.extensionStatus === "accepted" && (
              <MDBadge
                badgeContent={`Solicitud de extensión aceptada (${currentAgreement?.extension} meses)`}
                color={"success"}
                variant="gradient"
                size="sm"
              />
            )}
          <Grid item xs={12}>
            <MDTypography variant="h5" color="info">
              Datos convenio
            </MDTypography>
          </Grid>

          <Grid item xs={12}>
            <MDTypography variant="h6">Fecha convenio</MDTypography>
            <MDTypography variant="button" color="text">
              {currentAgreement && changeDateFormat(currentAgreement?.date)}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Duración</MDTypography>
            <MDTypography variant="button" color="text">
              {currentAgreement?.duration} meses
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Fecha inicio</MDTypography>
            <MDTypography variant="button" color="text">
              {currentAgreement &&
                changeDateFormat(currentAgreement?.startDate)}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Fecha fin</MDTypography>
            <MDTypography variant="button" color="text">
              {currentAgreement && changeDateFormat(currentAgreement?.endDate)}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Días de trabajo</MDTypography>
            <MDTypography variant="button" color="text">
              {currentAgreement?.laborDays}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Horas semanales</MDTypography>
            <MDTypography variant="button" color="text">
              {currentAgreement?.weeklyHours}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Hora inicio</MDTypography>
            <MDTypography variant="button" color="text">
              {currentAgreement?.startHour}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Hora fin</MDTypography>
            <MDTypography variant="button" color="text">
              {currentAgreement?.endHour}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Domicilio trabajo</MDTypography>
            <MDTypography variant="button" color="text">
              {currentAgreement?.workAddress}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Provincia trabajo</MDTypography>
            <MDTypography variant="button" color="text">
              {currentAgreement?.province}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Localidad trabajo</MDTypography>
            <MDTypography variant="button" color="text">
              {currentAgreement?.city}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Salario</MDTypography>
            <MDTypography variant="button" color="text">
              {currentAgreement?.salary}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Tutor Empresa</MDTypography>
            <MDTypography variant="button" color="text">
              {currentAgreement?.companyTutorName}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">DNI Tutor Empresa</MDTypography>
            <MDTypography variant="button" color="text">
              {currentAgreement?.companyTutorDni}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">
              Nombre administrador empresa
            </MDTypography>
            <MDTypography variant="button" color="text">
              {currentAgreement?.companyAdminName}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">
              Email sector administrativo
            </MDTypography>
            <MDTypography variant="button" color="text">
              {currentAgreement?.adminSectorEmail}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Área de trabajo</MDTypography>
            <MDTypography variant="button" color="text">
              {currentAgreement?.jobOpportunity.area.name}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Tareas a desempeñar</MDTypography>
            <Grid item container ml={3} xs={12}>
              <ul>
                {currentAgreement?.jobOpportunity.tasks.map((task) => {
                  return (
                    <li key={task.task.name}>
                      <MDTypography variant="button">
                        {task.task.name}
                      </MDTypography>
                    </li>
                  );
                })}
              </ul>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Tipo</MDTypography>
            <MDTypography variant="button" color="text">
              {currentAgreement?.internship ? "Pasantía" : "Empleo efectivo"}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h5" color="info" mt={2}>
              Datos Estudiante
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6">Carrera</MDTypography>
            <MDTypography variant="button" color="text">
              {currentAgreement?.student.career.name}
            </MDTypography>
          </Grid>
          {currentAgreement?.studentEvaluation && (
            <Grid item xs={12}>
              <MDTypography variant="h6">Validación estudiante</MDTypography>
              <MDTypography variant="button" color="text">
                {StudentValidation[currentAgreement.studentEvaluation]}
              </MDTypography>
            </Grid>
          )}
        </Grid>
      </CustomModal>
      <CustomModal
        open={openValidationModal}
        onClose={() => setOpenValidationModal(false)}
        aria-labelledby="student-validation-modal"
        height={"auto"}
      >
        <MDTypography variant="h6" mb={3}>
          ¿Cómo evaluarías el desempeño del estudiante?
        </MDTypography>
        <RadioGroup
          name="validation"
          value={validation}
          onChange={handleValidationChange}
          row
        >
          {[
            { text: "Muy malo", value: 1 },
            { text: "Malo", value: 2 },
            { text: "Bueno", value: 3 },
            { text: "Muy bueno", value: 4 },
            { text: "Excelente", value: 5 },
          ].map((item) => (
            <FormControlLabel
              key={item.value}
              value={item.value}
              control={<Radio />}
              label={item.text}
            />
          ))}
        </RadioGroup>
        <Grid item container justifyContent="end" xs={12} mt={4}>
          <Grid item>
            <MDButton
              variant="text"
              color="info"
              size="small"
              onClick={() => setOpenValidationModal(false)}
            >
              Cancelar
            </MDButton>
          </Grid>
          <Grid item>
            <MDButton
              color="info"
              size="small"
              onClick={() => handleSubmitValidation(false)}
            >
              Enviar respuesta
            </MDButton>
          </Grid>
        </Grid>
      </CustomModal>
      <CustomModal
        open={openExtensionModal}
        onClose={() => setOpenExtensionModal(false)}
        aria-labelledby="student-extension-modal"
        height={"auto"}
      >
        <MDTypography variant="h6" mb={3}>
          Solicitar extensión de convenio
        </MDTypography>
        <Formik
          initialValues={{ extension: 0 }}
          validationSchema={Yup.object().shape({
            extension: Yup.number()
              .min(1, "Debe ser mayor que 1 mes")
              .max(
                18 - currentAgreement?.duration,
                "Debe ser menor que 18 meses (duración inicial + extensión)"
              )
              .required("Este campo es requerido"),
          })}
          validateOnChange={true}
          onSubmit={handleSubmitAgreementExtension}
        >
          {({ errors, values, setFieldValue, handleSubmit, touched }) => (
            <>
              <Grid item xs={6}>
                <Input
                  type={"number"}
                  label={"Extensión (en meses)"}
                  min={5}
                  fullWidth={true}
                  onChange={(e) => setFieldValue("extension", e.target.value)}
                  value={values.extension}
                  error={!!errors.extension && touched.extension}
                  errorText={errors.extension}
                />
              </Grid>
              <Grid item container justifyContent="end" xs={12} mt={4}>
                <Grid item>
                  <MDButton
                    variant="text"
                    color="info"
                    size="small"
                    onClick={() => setOpenExtensionModal(false)}
                  >
                    Cancelar
                  </MDButton>
                </Grid>
                <Grid item>
                  <MDButton
                    color="info"
                    size="small"
                    onClick={() => handleSubmit()}
                  >
                    Enviar solicitud
                  </MDButton>
                </Grid>
              </Grid>
            </>
          )}
        </Formik>
      </CustomModal>
    </DashboardLayout>
  );
}

export default Agreements;
