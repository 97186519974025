import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import Grid from "@mui/material/Grid";
import StatisticsCard from "../../../components/molecules/card/StatisticsCard";
import { useEffect, useState } from "react";
import studentService from "../../../services/api/student/studentService";

function Dashboard() {
  const [agreements, setAgreements] = useState([]);

  useEffect(() => {
    getAgreements();
  }, []);

  const getAgreements = async () => {
    const response = await studentService.getAgreements();

    setAgreements(response.data);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <MDBox mb={1.5}>
              <StatisticsCard
                icon="work_history"
                title="Oportunidades de pasantía / empleo efectivo"
                action={{
                  text: "Ver más",
                  link: "/student/dashboard/job-offers",
                  color: "dark",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox mb={1.5}>
              <StatisticsCard
                icon="work"
                title="Convenios"
                count={agreements.length}
                action={{
                  text: "Ver más",
                  link: "/student/dashboard/agreements",
                  color: "dark",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
