import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import MDBox from "../../MDBox";
import MDTypography from "../../MDTypography";
import MDButton from "../../MDButton";

function JobCard({ title, description, action, hasApplied }) {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundImage: hasApplied
          ? "linear-gradient(195deg, rgb(51 185 143), rgb(31 121 131))"
          : "linear-gradient(195deg, rgb(43, 87, 141), rgb(14, 46, 102))",
        color: "white",
        boxShadow: "none",
        overflow: "visible",
      }}
    >
      <MDBox mx={0.5} p={2}>
        <MDBox mb={1}>
          <MDTypography variant="h5" textTransform="capitalize" color="white">
            {title}
          </MDTypography>
        </MDBox>
        <MDBox mb={3} lineHeight={0}>
          <MDTypography variant="button" fontWeight="light" color="white">
            {description}
          </MDTypography>
        </MDBox>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <MDButton
            variant="outlined"
            size="small"
            color={action.color}
            onClick={action.onClick}
          >
            {action.label}
          </MDButton>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Typechecking props for the DefaultProjectCard
JobCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
      "white",
    ]).isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }).isRequired,
};

export default JobCard;
