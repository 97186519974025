import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import CompanyService from "../../../services/api/company/companyService";
import CompanyOnboardingStep1 from "../onboarding/steps/companyOnboardingStep1";
import {
  CompanyOnboardingStep1Schema,
  CompanyOnboardingStep2Schema,
} from "../../../helpers/schemas/onboardingSchemas";
import { Formik } from "formik";
import AppBar from "@mui/material/AppBar";
import { Tab, Tabs } from "@mui/material";
import breakpoints from "../../../assets/theme/base/breakpoints";
import backgroundImage from "../../../assets/images/bg-profile.jpeg";
import Card from "@mui/material/Card";
import CompanyOnboardingStep2 from "../onboarding/steps/companyOnboardingStep2";
import CompanyOnboardingStep3 from "../onboarding/steps/companyOnboardingStep3";
import useAPIError from "../../../hooks";
import MDSnackbar from "../../../components/MDSnackbar";

function CompanyProfile() {
  const [companyData, setCompanyData] = useState();
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [representatives, setRepresentatives] = React.useState([]);
  const [openNotification, setOpenNotification] = useState(false);

  const { addError } = useAPIError();
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const getCompanyData = async () => {
    const response = await CompanyService.getProfile();
    setCompanyData(response.data);
  };

  const getRepresentatives = async () => {
    const response = await CompanyService.getRepresentatives();

    setRepresentatives(response.data);
  };

  useEffect(() => {
    getCompanyData();
    getRepresentatives();
  }, []);

  const onSubmit = async (values) => {
    if (tabValue !== 2) {
      const body = {
        legalPersonType: values.legalPersonType,
        taxStatus: values.taxStatus,
        companyPurpose: values.companyPurpose,
        file: values.file,
        realAddress: values.realAddress,
        legalAddress: values.legalAddress,
        phone: values.phone,
        whatsapp: values.whatsapp,
        companyEmail: values.companyEmail,
        website: values.website,
        location: {
          city: values.city,
          province: values.province,
          postalCode: values.postalCode,
        },
      };
      const res1 = await CompanyService.updateCompany(companyData?.id, body);
      if (res1.status === 200) {
        await getCompanyData();
        setOpenNotification(true);
      } else {
        addError(`${res1.data.message}`, res1.status);
      }
    } else {
      const res2 = await CompanyService.updateRepresentatives(
        companyData?.id,
        representatives
      );

      if (res2.status === 201) {
        await getRepresentatives();
      } else {
        addError(`${res2.data.message}`, res2.status);
      }
    }
  };

  const initialValues = () => {
    return {
      legalPersonType: companyData?.legalPersonType || "",
      taxStatus: companyData?.taxStatus || "",
      companyPurpose: companyData?.companyPurpose || "",
      file: companyData?.afipFile || "",
      realAddress: companyData?.realAddress || "",
      legalAddress: companyData?.legalAddress || "",
      city: companyData?.location.city || "",
      province: companyData?.location.province || "",
      postalCode: companyData?.location.postalCode || "",
      phone: companyData?.phone || "",
      whatsapp: companyData?.whatsapp || "",
      website: companyData?.website || "",
      companyEmail: companyData?.companyEmail || "",
    };
  };

  const schemaDecider = () => {
    if (tabValue === 0) {
      return CompanyOnboardingStep1Schema;
    }
    if (tabValue === 1) {
      return CompanyOnboardingStep2Schema;
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDSnackbar
        icon="notifications"
        color="success"
        title="Perfil actualizado correctamente"
        open={openNotification}
        close={() => setOpenNotification(false)}
      />
      <MDBox mb={2} />
      <MDBox position="relative" mb={5}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="10.75rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({
              functions: { rgba, linearGradient },
              palette: { gradients },
            }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.6),
                rgba(gradients.info.state, 0.6)
              )}, url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />
        <Card
          sx={{
            position: "relative",
            mt: -8,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  Mis datos
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={8} sx={{ ml: "auto" }}>
              <AppBar position="static">
                <Tabs
                  orientation={tabsOrientation}
                  value={tabValue}
                  onChange={handleSetTabValue}
                >
                  <Tab
                    label="Datos fiscales"
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        filter_1
                      </Icon>
                    }
                  />
                  <Tab
                    label="Datos de locación y contacto"
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        filter_2
                      </Icon>
                    }
                  />
                  <Tab
                    label="Representantes"
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        filter_3
                      </Icon>
                    }
                  />
                </Tabs>
              </AppBar>
            </Grid>
          </Grid>
          {companyData && (
            <Formik
              initialValues={initialValues()}
              validationSchema={schemaDecider()}
              validateOnChange={true}
              onSubmit={onSubmit}
              enableReinitialize={true}
            >
              {({ errors, values, handleSubmit, setFieldValue, touched }) => (
                <>
                  {tabValue === 0 && (
                    <CompanyOnboardingStep1
                      setFieldValue={setFieldValue}
                      errors={errors}
                      values={values}
                      touched={touched}
                      isProfilePage={true}
                    ></CompanyOnboardingStep1>
                  )}
                  {tabValue === 1 && (
                    <CompanyOnboardingStep2
                      setFieldValue={setFieldValue}
                      errors={errors}
                      values={values}
                      touched={touched}
                    ></CompanyOnboardingStep2>
                  )}
                  {tabValue === 2 && (
                    <CompanyOnboardingStep3
                      isProfilePage={true}
                      initialRepresentatives={representatives}
                      onSetRepresentative={setRepresentatives}
                    ></CompanyOnboardingStep3>
                  )}
                  <Grid container justifyContent="end">
                    <MDButton
                      color="info"
                      size="small"
                      onClick={() => handleSubmit()}
                    >
                      Actualizar datos
                    </MDButton>
                  </Grid>
                </>
              )}
            </Formik>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default CompanyProfile;
