import Api from "../api";

class CompanyService {
  async getProfile() {
    let response;
    try {
      response = await Api.get("company/profile");
    } catch (err) {
      response = err;
    }
    return response;
  }

  async updateCompany(id, body) {
    let response;
    try {
      response = await Api.put(`company/${id}`, body);
    } catch (err) {
      response = err;
    }
    return response;
  }

  async getRepresentatives() {
    let response;
    try {
      response = await Api.get("company/representatives");
    } catch (err) {
      response = err;
    }
    return response;
  }

  async updateRepresentatives(id, body) {
    let response;
    try {
      response = await Api.put(`representative/${id}`, body);
    } catch (err) {
      response = err;
    }
    return response;
  }

  async getAreas() {
    let response;
    try {
      response = await Api.get("areas");
    } catch (err) {
      response = err;
    }
    return response;
  }

  async getJobOpportunities() {
    let response;
    try {
      response = await Api.get("company/job-opportunity");
    } catch (err) {
      response = err;
    }
    return response;
  }

  async addJobOffer(body) {
    let response;
    try {
      response = await Api.post("company/job-opportunity", body);
    } catch (err) {
      response = err;
    }
    return response;
  }

  async updateJobOffer(id, body) {
    let response;
    try {
      response = await Api.put(`company/job-opportunity/${id}`, body);
    } catch (err) {
      response = err;
    }
    return response;
  }

  async getAgreements() {
    let response;
    try {
      response = await Api.get("agreements/company");
    } catch (err) {
      response = err;
    }
    return response;
  }

  async validateStudent(id, body) {
    let response;
    try {
      response = await Api.put(`agreements/validate/${id}`, body);
    } catch (err) {
      response = err;
    }
    return response;
  }

  async extendAgreement(id, body) {
    let response;
    try {
      response = await Api.put(`agreements/extend/${id}`, body);
    } catch (err) {
      response = err;
    }
    return response;
  }
}

export default new CompanyService();
