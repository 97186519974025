import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import AddIcon from "@mui/icons-material/Add";
import { addJobOfferSchema } from "helpers/schemas/companySchemas";
import { Formik } from "formik";
import Input from "components/molecules/input/input";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import CancelIcon from "@mui/icons-material/Cancel";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "components/MDInput";
import MDBadge from "components/MDBadge";
import OutlinedInput from "@mui/material/OutlinedInput";
import MDSnackbar from "../../../components/MDSnackbar";
import CompanyService from "../../../services/api/company/companyService";
import StudentService from "../../../services/api/student/studentService";
import CustomModal from "../../../components/molecules/modal/modal";
import { JobOfferStatus } from "../../../enums";
import StudentsList from "../../../components/organisms/studentsList";
import {
  changeDateFormat,
  sortJobOffers,
  atomicSpinner,
} from "../../../utils/dashboard";
import useAPIError from "../../../hooks";
import { getCurrentDate } from "../../../utils/dashboard";
import Prediction from "../../../services/api/prediction";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const getJobOfferStatus = (status) => {
  switch (status) {
    case JobOfferStatus.UNVERIFIED:
      return "En validación";
    case JobOfferStatus.PUBLISHED:
      return "Publicada";
    case JobOfferStatus.WORKING:
      return "En convenio";
    case JobOfferStatus.REJECTED:
      return "Rechazada";
  }
};

const getJobOfferBadgeColor = (status) => {
  switch (status) {
    case JobOfferStatus.UNVERIFIED:
      return "warning";
    case JobOfferStatus.PUBLISHED:
      return "success";
    case JobOfferStatus.WORKING:
      return "info";
    case JobOfferStatus.REJECTED:
      return "error";
  }
};

const columns = [
  { Header: "ID Oportunidad", accessor: "id", align: "left" },
  { Header: "Área", accessor: "function", align: "left" },
  { Header: "Estado", accessor: "status", align: "center" },
  {
    Header: "Fecha de finalización de búsqueda",
    accessor: "employed",
    align: "center",
  },
  { Header: "Acciones", accessor: "action", align: "center" },
];

function JobOffers() {
  const [openModal, setOpenModal] = useState(false);
  const [openNotification, setOpenNotification] = useState({
    open: false,
    title: "",
    content: "",
  });
  const [jobOffers, setJobOffers] = useState([]);
  const [isEditModal, setIsEditModal] = useState(false);
  const [currentJobOffer, setCurrentJobOffer] = useState({});
  const [currentKnowledge, setCurrentKnowledge] = useState("");
  const [knowledges, setKnowledges] = useState([]);
  const [task, setCurrentTask] = useState("");
  const [tasks, setTask] = useState([]);
  const [careerIds, setCareersIds] = useState([]);
  const [availableCareers, setAvailableCareers] = useState([]);
  const [availableAreas, setAvailableAreas] = useState([]);
  const [availableKnowledges, setAvailableKnowledges] = useState([]);
  //const [careerPrediction, setCareerPrediction] = useState(null);
  const { addError } = useAPIError();

  useEffect(() => {
    getCareers();
    getAreas();
    getKnowledges();
    getJobOpportunities();
  }, []);

  const getCareers = async () => {
    const response = await StudentService.getCareers();

    setAvailableCareers(response.data);
  };

  const getAreas = async () => {
    const response = await CompanyService.getAreas();

    setAvailableAreas(response.data);
  };

  const getKnowledges = async () => {
    const response = await StudentService.getKnowledges();

    setAvailableKnowledges(response.data);
  };

  const getJobOpportunities = async () => {
    const response = await CompanyService.getJobOpportunities();

    setJobOffers(sortJobOffers(response.data));
  };

  // Disabled for now
  /* const getCareerPrediction = async (id) => {
    const response = await Prediction.getCareerPrediction(id);

    setCareerPrediction(response.data);
  }; */

  const handleCloseModal = () => {
    setOpenModal(false);
    if (isEditModal) {
      //setCareerPrediction(null);
      setCareersIds([]);
      setIsEditModal(false);
    }
  };

  const addJobOffer = async (values) => {
    const knowledgeIds = knowledges.map((k) => {
      return k.id;
    });
    console.log(values)
    if(careerIds.length === 0){
      addError("Debe seleccionar al menos una carrera", 400);
      return;
    }
    if(tasks.length === 0){
      addError("Debe agregar al menos una tarea", 400);
      return;
    }
    if(knowledgeIds.length === 0){
      addError("Debe seleccionar al menos un conocimiento", 400);
      return;
    }
    const res = await CompanyService.addJobOffer({
      studentCondition: values.studentCondition,
      gender: values.gender,
      ageRage: values.ageRage,
      english: values.english,
      closeDate: values.closeDate,
      area: values.area,
      address: values.address,
      showCompanyName: values.showCompanyName,
      salary: values.salary,
      otherBenefits: values.otherBenefits,
      schedule: values.schedule,
      internship: values.internship,
      careerIds: careerIds,
      tasks: tasks,
      knowledgeIds: knowledgeIds,
    });
    if (res.status === 201) {
      handleCloseModal();
      //Disabled for now
      /* setOpenNotification({
        open: true,
        title: "¡Oportunidad de trabajo creada!",
        content:
          "Presiona ver oportunidad para poder ver la especialidad que es apta para esta oportunidad de trabajo.",
      }); */
      await getJobOpportunities();
    } else {
      addError(`Algo ha salido mal`, res.status);
    }
  };

  const updateJobOffer = async (values) => {
    const knowledgeIds = knowledges.map((k) => {
      return k.id;
    });
    const res = await CompanyService.updateJobOffer(currentJobOffer.id, {
      studentCondition: values.studentCondition,
      gender: values.gender,
      ageRage: values.ageRage,
      english: values.english,
      closeDate: values.closeDate,
      area: values.area,
      address: values.address,
      showCompanyName: values.showCompanyName,
      salary: values.salary,
      otherBenefits: values.otherBenefits,
      schedule: values.schedule,
      internship: values.internship === "true" ? true : false,
      careerIds: careerIds,
      tasks: tasks,
      knowledgeIds: knowledgeIds,
    });
    if (res.status === 200) {
      handleCloseModal();
      setOpenNotification({
        open: true,
        title: "Oportunidad de trabajo actualizada",
        content: undefined,
      });
      await getJobOpportunities();
    } else {
      addError(`Algo ha salido mal`, res.status);
    }
    setIsEditModal(false);
    setOpenModal(false);
  };

  const onAddJobOpportunity = () => {
    setCareersIds([]);
    setKnowledges([]);
    setTask([]);
    setOpenModal(true);
  };

  const onEditJobOffer = (id) => {
    setIsEditModal(true);
    const jobOffer = jobOffers.find((jobOffer) => jobOffer.id === id);
    setCurrentJobOffer(jobOffer);

    /* if (jobOffer.status === JobOfferStatus.UNVERIFIED) {
      getCareerPrediction(id);
    } */
    setCareersIds(jobOffer.careers.map((career) => career.career.id));
    setKnowledges(jobOffer.knowledge.map((knowledge) => knowledge.knowledge));
    setTask(jobOffer.tasks.map((task) => task.task.name));
    setOpenModal(true);
  };

  const handleAddTask = () => {
    setTask([...tasks, task]);
    setCurrentTask("");
  };

  const handleDeleteTask = (name) => {
    if (currentJobOffer.status !== JobOfferStatus.UNVERIFIED) {
      return;
    }
    let array = [];
    if (!isEditModal) {
      array = tasks.filter((task) => task !== name);
    } else {
      array = tasks.filter((task) => task !== name);
    }
    setTask(array);
  };

  const handleChangeKnowledge = (value) => {
    if (!value) {
      return;
    }
    setKnowledges([
      ...knowledges,
      availableKnowledges.filter(
        (knowledge) => knowledge.subCategory === value
      )[0],
    ]);
    setCurrentKnowledge("");
  };

  const handleDeleteKnowledge = (knowledge) => {
    if (currentJobOffer.status !== JobOfferStatus.UNVERIFIED) {
      return;
    }
    let array = [];
    array = knowledges.filter((k) => k.id !== knowledge.id);
    setKnowledges(array);
  };

  const initialValuesDecider = () => {
    if (isEditModal) {
      return {
        studentCondition: currentJobOffer.studentCondition,
        gender: currentJobOffer.gender,
        ageRage: currentJobOffer.ageRage,
        english: currentJobOffer.english,
        closeDate: currentJobOffer.closeDate.split("T")[0],
        area: currentJobOffer.area.name,
        address: currentJobOffer.address,
        showCompanyName: currentJobOffer.showCompanyName,
        salary: currentJobOffer.salary,
        otherBenefits: currentJobOffer.otherBenefits,
        schedule: currentJobOffer.schedule,
        internship: currentJobOffer.internship,
      };
    } else {
      return {
        studentCondition: "",
        gender: "",
        ageRage: "",
        english: false,
        closeDate: getCurrentDate(),
        area: "",
        address: "",
        showCompanyName: false,
        salary: "",
        otherBenefits: "",
        schedule: "",
        internship: true,
      };
    }
  };

  const rows = jobOffers.map((jobOffer) => {
    return {
      id: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {`OP-${jobOffer.id}`}
        </MDTypography>
      ),
      function: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {jobOffer.area.name}
        </MDTypography>
      ),
      status: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent={getJobOfferStatus(jobOffer.status)}
            color={getJobOfferBadgeColor(jobOffer.status)}
            variant="gradient"
            size="sm"
          />
        </MDBox>
      ),
      employed: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {changeDateFormat(jobOffer.closeDate)}
        </MDTypography>
      ),
      action: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
          onClick={() => onEditJobOffer(jobOffer.id)}
        >
          Ver oportunidad
        </MDTypography>
      ),
    };
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDSnackbar
        icon="notifications"
        title={openNotification.title}
        content={openNotification.content && openNotification.content}
        open={openNotification.open}
        close={() =>
          setOpenNotification({ title: "", open: false, content: undefined })
        }
      />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDTypography variant="h3">
              Oportunidades de pasantía / empleo efectivo
            </MDTypography>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="contained"
                bgColor="dark"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container>
                  <Grid item>
                    <MDButton
                      color="light"
                      variant="outlined"
                      fullWidth
                      onClick={() => onAddJobOpportunity()}
                    >
                      <AddIcon />
                      &nbsp; Agregar nueva solicitud
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3} pb={3}>
                {jobOffers.length > 0 ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                ) : (
                  <MDTypography variant="caption" ml={3}>
                    Aún no hay oportunidades creadas
                  </MDTypography>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <CustomModal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-job-offers"
        aria-describedby="modal-job-offers"
      >
        <>
          <Formik
            initialValues={initialValuesDecider()}
            validationSchema={addJobOfferSchema}
            validateOnChange={true}
            onSubmit={isEditModal ? updateJobOffer : addJobOffer}
            enableReinitialize={true}
          >
            {({ errors, values, setFieldValue, handleSubmit, touched }) => (
              <>
                <MDTypography variant="h5" fontWeight="medium" color="dark">
                  {isEditModal
                    ? currentJobOffer.status === JobOfferStatus.UNVERIFIED
                      ? `Editar oportunidad OP-${currentJobOffer.id}`
                      : `Oportunidad OP-${currentJobOffer.id}`
                    : "Agregar nueva solicitud de oportunidad de pasantía"}
                  {isEditModal && (
                    <MDBadge
                      badgeContent={getJobOfferStatus(currentJobOffer.status)}
                      color={getJobOfferBadgeColor(currentJobOffer.status)}
                      variant="gradient"
                      size="sm"
                    />
                  )}
                </MDTypography>
                {isEditModal &&
                  currentJobOffer.student.filter(
                    (student) => student.isValidated === true
                  ).length > 0 && (
                    <Grid item xs={12} mt={3}>
                      <Card>
                        <MDBox
                          display="grid"
                          alignItems="center"
                          bgColor="info"
                          color="white"
                          shadow="md"
                          borderRadius="lg"
                          variant="gradient"
                          p={2}
                        >
                          <MDTypography variant="h6" color="white">
                            <b>Estudiantes que aplicaron a la oportunidad</b>
                          </MDTypography>
                          <Grid container mt={2}>
                            <Grid item xs={12}>
                              <StudentsList
                                students={currentJobOffer.student.filter(
                                  (student) => student.isValidated === true
                                )}
                                isCompany={true}
                                opportunityId={currentJobOffer.id}
                              />
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Card>
                    </Grid>
                  )}
                {/* {isEditModal &&
                  currentJobOffer.status === JobOfferStatus.UNVERIFIED && (
                    <Grid item xs={12} my={3}>
                      <Card>
                        <MDBox
                          display="grid"
                          alignItems="center"
                          bgColor="warning"
                          color="white"
                          shadow="md"
                          borderRadius="lg"
                          variant="gradient"
                          p={2}
                        >
                          <MDTypography variant="h6" color="white">
                            <b>Especialidad sugerida para esta oportunidad:</b>
                          </MDTypography>
                          <Grid container spacing={1}>
                            <Grid item>
                              {careerPrediction ? (
                                <MDBadge
                                  badgeContent={careerPrediction}
                                  color="light"
                                  variant="contained"
                                  size="md"
                                />
                              ) : (
                                atomicSpinner()
                              )}
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Card>
                    </Grid>
                  )}
 */}
                <Grid container mt={2} mb={2}>
                  <Grid item xs={12} mb={2}>
                    <MDTypography variant="h6" color="dark">
                      Acerca del estudiante
                    </MDTypography>
                  </Grid>

                  <Grid item xs={12} mb={2}>
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="demo-multiple-career-label">
                        Carreras
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-career-label"
                        id="multiple-select"
                        multiple
                        value={careerIds}
                        onChange={(e) => setCareersIds(e.target.value)}
                        input={<OutlinedInput label="Name" />}
                        MenuProps={MenuProps}
                        disabled={
                          isEditModal &&
                          currentJobOffer.status !== JobOfferStatus.UNVERIFIED
                        }
                      >
                        {availableCareers.map((career) => (
                          <MenuItem
                            key={`career-${career.id}`}
                            value={career.id}
                          >
                            {career.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} mb={2}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Condición del estudiante
                      </InputLabel>
                      <Select
                        labelId="student-condition-select"
                        id="student-condition-select"
                        value={values.studentCondition}
                        label="Condición del estudiante"
                        onChange={(e) =>
                          setFieldValue("studentCondition", e.target.value)
                        }
                        disabled={
                          isEditModal &&
                          currentJobOffer.status !== JobOfferStatus.UNVERIFIED
                        }
                      >
                        <MenuItem value={"beginner"}>Principiante</MenuItem>
                        <MenuItem value={"intermediate"}>Intermedio</MenuItem>
                        <MenuItem value={"advanced"}>Avanzado</MenuItem>
                      </Select>
                      {errors?.studentCondition ? (
                        <MDTypography variant="caption" color="error">
                          {errors.studentCondition}
                        </MDTypography>
                      ) : null}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      type={"text"}
                      label={"Rango etario"}
                      fullWidth={true}
                      onChange={(e) => setFieldValue("ageRage", e.target.value)}
                      value={values.ageRage}
                      error={!!errors.ageRage && touched.ageRage}
                      errorText={errors.ageRage}
                      disabled={
                        isEditModal &&
                        currentJobOffer.status !== JobOfferStatus.UNVERIFIED
                      }
                    />
                  </Grid>
                  <Grid item xs={12} mb={2}>
                    <MDTypography variant="button">Género</MDTypography>
                    <RadioGroup
                      row
                      name="gender-radio-buttons-group"
                      value={values.gender}
                      onChange={(e) => setFieldValue("gender", e.target.value)}
                    >
                      <FormControlLabel
                        value="female"
                        control={<Radio />}
                        label="Mujer"
                        disabled={
                          isEditModal &&
                          currentJobOffer.status !== JobOfferStatus.UNVERIFIED
                        }
                      />
                      <FormControlLabel
                        value="male"
                        control={<Radio />}
                        label="Hombre"
                        disabled={
                          isEditModal &&
                          currentJobOffer.status !== JobOfferStatus.UNVERIFIED
                        }
                      />
                      <FormControlLabel
                        value="nonBinary"
                        control={<Radio />}
                        label="No binario"
                        disabled={
                          isEditModal &&
                          currentJobOffer.status !== JobOfferStatus.UNVERIFIED
                        }
                      />
                      <FormControlLabel
                        value="any"
                        control={<Radio />}
                        label="Indistinto"
                        disabled={
                          isEditModal &&
                          currentJobOffer.status !== JobOfferStatus.UNVERIFIED
                        }
                      />
                    </RadioGroup>
                    {errors?.gender && touched.gender ? (
                      <MDTypography variant="caption" color="error">
                        {errors.gender}
                      </MDTypography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <MDTypography variant="button">
                      Se necesita nivel de inglés
                    </MDTypography>
                    <RadioGroup
                      row
                      name="english-radio-buttons-group"
                      value={values.english}
                      onChange={(e) => {
                        let value = false;
                        if (
                          e.target.value &&
                          typeof e.target.value === "string"
                        ) {
                          if (e.target.value.toLowerCase() === "true")
                            value = true;
                          if (e.target.value.toLowerCase() === "false")
                            value = false;
                        }
                        setFieldValue(
                          "english",
                          e.target.value && typeof e.target.value === "string"
                            ? value
                            : e.target.value
                        );
                      }}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Si"
                        disabled={
                          isEditModal &&
                          currentJobOffer.status !== JobOfferStatus.UNVERIFIED
                        }
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                        disabled={
                          isEditModal &&
                          currentJobOffer.status !== JobOfferStatus.UNVERIFIED
                        }
                      />
                    </RadioGroup>
                    {errors?.showCompanyName && touched.showCompanyName ? (
                      <MDTypography variant="caption" color="error">
                        {errors.showCompanyName}
                      </MDTypography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} mt={3} mb={1}>
                    <MDTypography variant="h6" color="dark">
                      Acerca de la pasantía
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDTypography variant="button">Tipo</MDTypography>
                    <RadioGroup
                      row
                      name="is-internship-radio-buttons-group"
                      value={values.internship}
                      onChange={(e) =>
                        setFieldValue("internship", e.target.value)
                      }
                    >
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="Empleo efectivo"
                        disabled={
                          isEditModal &&
                          currentJobOffer.status !== JobOfferStatus.UNVERIFIED
                        }
                      />
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Pasantía"
                        disabled={
                          isEditModal &&
                          currentJobOffer.status !== JobOfferStatus.UNVERIFIED
                        }
                      />
                    </RadioGroup>
                    {errors?.internship && touched.internship ? (
                      <MDTypography variant="caption" color="error">
                        {errors.internship}
                      </MDTypography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <MDTypography
                      variant="button"
                      fontWeight="medium"
                      color="dark"
                    >
                      Tareas a realizar
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} mb={1}>
                    <MDTypography variant="caption" color="info">
                      Para agregar una nueva tarea escriba en el campo inferior
                      y luego haga click en "+"
                    </MDTypography>
                  </Grid>
                  <Grid item container xs={12} spacing={3}>
                    <Grid item xs={8}>
                      <MDInput
                        type="text"
                        label="Tarea"
                        value={task}
                        fullWidth
                        onChange={(e) => setCurrentTask(e.target.value)}
                        disabled={
                          isEditModal &&
                          currentJobOffer.status !== JobOfferStatus.UNVERIFIED
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <MDButton
                        variant="gradient"
                        color="dark"
                        size="small"
                        iconOnly
                        onClick={() => handleAddTask()}
                        disabled={
                          isEditModal &&
                          currentJobOffer.status !== JobOfferStatus.UNVERIFIED
                        }
                      >
                        <AddIcon />
                      </MDButton>
                    </Grid>
                  </Grid>
                  <Grid item container spacing={1} mt={1}>
                    {tasks.length > 0 &&
                      tasks.map((task, id) => {
                        return (
                          <Grid item xs={12} key={`task-${id}`}>
                            <Chip
                              label={task}
                              onDelete={() => handleDeleteTask(task)}
                              deleteIcon={<CancelIcon />}
                              variant="outlined"
                              clickable={
                                !isEditModal &&
                                (currentJobOffer.status !==
                                  JobOfferStatus.WORKING ||
                                  currentJobOffer.status !==
                                    JobOfferStatus.REJECTED)
                              }
                            />
                          </Grid>
                        );
                      })}
                  </Grid>
                  <Grid item xs={12} mt={2} mb={1}>
                    <MDTypography
                      variant="button"
                      fontWeight="medium"
                      color="dark"
                    >
                      Conocimientos
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} mb={2} mt={0}>
                    <Autocomplete
                      id="knowledges"
                      freeSolo
                      onChange={(event, newValue) => {
                        handleChangeKnowledge(newValue);
                      }}
                      value={currentKnowledge}
                      options={availableKnowledges.map(
                        (option) => option.subCategory
                      )}
                      renderInput={(params) => (
                        <TextField {...params} label="Buscar..." />
                      )}
                      disabled={
                        isEditModal &&
                        currentJobOffer.status !== JobOfferStatus.UNVERIFIED
                      }
                    />
                  </Grid>
                  <Grid item container spacing={1} mb={3}>
                    {knowledges.length > 0 &&
                      knowledges.map((knowledge, id) => {
                        return (
                          <Grid item key={`knowledge-${id}`}>
                            <Chip
                              label={knowledge.subCategory}
                              deleteIcon={<CancelIcon />}
                              onDelete={() => handleDeleteKnowledge(knowledge)}
                              variant="outlined"
                            />
                          </Grid>
                        );
                      })}
                  </Grid>
                  <Grid item xs={12} mb={2} mt={0}>
                    <Autocomplete
                      id="area"
                      freeSolo
                      onChange={(event, newValue) => {
                        setFieldValue("area", newValue);
                      }}
                      value={values.area}
                      options={
                        availableAreas &&
                        availableAreas.length > 0 &&
                        availableAreas.map((option) => option.name)
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Área de la empresa" />
                      )}
                      disabled={
                        isEditModal &&
                        currentJobOffer.status !== JobOfferStatus.UNVERIFIED
                      }
                    />
                    {!!errors.area && touched.area ? (
                      <MDTypography variant="caption" color="error">
                        {errors.area}
                      </MDTypography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      type={"text"}
                      label={"Dirección donde se realizarán las tareas"}
                      fullWidth={true}
                      onChange={(e) => setFieldValue("address", e.target.value)}
                      value={values.address}
                      error={!!errors.address && touched.address}
                      errorText={errors.address}
                      disabled={
                        isEditModal &&
                        currentJobOffer.status !== JobOfferStatus.UNVERIFIED
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      type={"text"}
                      label={"Días y horarios de la pasantía"}
                      fullWidth={true}
                      onChange={(e) =>
                        setFieldValue("schedule", e.target.value)
                      }
                      value={values.schedule}
                      error={!!errors.schedule && touched.schedule}
                      errorText={errors.schedule}
                      disabled={
                        isEditModal &&
                        currentJobOffer.status !== JobOfferStatus.UNVERIFIED
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      type={"number"}
                      label={"Remuneración aproximada"}
                      fullWidth={true}
                      onChange={(e) => setFieldValue("salary", e.target.value)}
                      value={values.salary}
                      error={!!errors.salary && touched.salary}
                      errorText={errors.salary}
                      disabled={
                        isEditModal &&
                        currentJobOffer.status !== JobOfferStatus.UNVERIFIED
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      type={"date"}
                      label={"Fecha de cierre de búsqueda"}
                      fullWidth={true}
                      onChange={(e) =>
                        setFieldValue("closeDate", e.target.value)
                      }
                      value={values.closeDate}
                      error={!!errors.closeDate && touched.closeDate}
                      errorText={errors.closeDate}
                      disabled={
                        isEditModal &&
                        currentJobOffer.status !== JobOfferStatus.UNVERIFIED
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <MDTypography variant="button">
                      Permite publicar su nombre en las oportunidades
                    </MDTypography>
                    <RadioGroup
                      row
                      name="gender-radio-buttons-group"
                      value={values.showCompanyName}
                      onChange={(e) => {
                        let value = false;
                        if (
                          e.target.value &&
                          typeof e.target.value === "string"
                        ) {
                          if (e.target.value.toLowerCase() === "true")
                            value = true;
                          if (e.target.value.toLowerCase() === "false")
                            value = false;
                        }
                        setFieldValue(
                          "showCompanyName",
                          e.target.value && typeof e.target.value === "string"
                            ? value
                            : e.target.value
                        );
                      }}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Si"
                        disabled={
                          isEditModal &&
                          currentJobOffer.status !== JobOfferStatus.UNVERIFIED
                        }
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                        disabled={
                          isEditModal &&
                          currentJobOffer.status !== JobOfferStatus.UNVERIFIED
                        }
                      />
                    </RadioGroup>
                    {errors?.showCompanyName && touched.showCompanyName ? (
                      <MDTypography variant="caption" color="error">
                        {errors.showCompanyName}
                      </MDTypography>
                    ) : null}
                  </Grid>
                  <Grid item mt={3} mb={2} xs={12}>
                    <TextField
                      label={"Otros beneficios"}
                      multiline
                      fullWidth
                      rows={4}
                      variant="outlined"
                      value={values.otherBenefits}
                      onChange={(e) =>
                        setFieldValue("otherBenefits", e.target.value)
                      }
                      error={!!errors.otherBenefits && touched.otherBenefits}
                      disabled={
                        isEditModal &&
                        currentJobOffer.status !== JobOfferStatus.UNVERIFIED
                      }
                    />
                    {errors?.otherBenefits && touched.otherBenefits ? (
                      <MDTypography variant="caption" color="error">
                        {errors.otherBenefits}
                      </MDTypography>
                    ) : null}
                  </Grid>

                  <Grid item container justifyContent="space-between" xs={12}>
                    <Grid item>
                      <MDButton
                        variant="text"
                        color="info"
                        size="small"
                        onClick={handleCloseModal}
                      >
                        Cancelar
                      </MDButton>
                    </Grid>
                    <Grid item>
                      {isEditModal &&
                        currentJobOffer.status ===
                          JobOfferStatus.UNVERIFIED && (
                          <MDButton
                            color="info"
                            size="small"
                            onClick={() => handleSubmit()}
                          >
                            Actualizar
                          </MDButton>
                        )}
                      {!isEditModal && (
                        <MDButton
                          color="info"
                          size="small"
                          onClick={() => handleSubmit()}
                        >
                          Aceptar
                        </MDButton>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Formik>
        </>
      </CustomModal>
    </DashboardLayout>
  );
}

export default JobOffers;
