import Icon from "@mui/material/Icon";
import Dashboard from "../pages/admin/dashboard";
import JobOffers from "../pages/admin/jobOffers";
import StudentInformation from "../pages/admin/studentInformation";
import Students from "../pages/admin/students";
import Companies from "../pages/admin/companies";
import Agreements from "../pages/admin/agreements";
import AdminAccountInfo from "../pages/admin/account";
import Teachers from "pages/admin/teachers";

const adminRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "admin-dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Oportunidades",
    key: "job-offers",
    icon: <Icon fontSize="small">work_history</Icon>,
    route: "/dashboard/job-offers",
    component: <JobOffers />,
  },
  {
    type: "collapse",
    name: "Convenios",
    key: "agreements",
    icon: <Icon fontSize="small">work</Icon>,
    route: "/dashboard/agreements",
    component: <Agreements />,
  },
  
  {
    type: "collapse",
    name: "Información del estudiante",
    key: "student-information",
    route: "/student-information",
    component: <StudentInformation />,
    hidden: true,
  },
  {
    type: "collapse",
    name: "Profesores Tutores",
    key: "teachers",
    icon: <Icon fontSize="small">supervised_user_circle</Icon>,
    route: "/dashboard/teachers",
    component: <Teachers />,
  },
  {
    type: "collapse",
    name: "Estudiantes",
    key: "students",
    route: "/dashboard/students",
    component: <Students />,
    hidden: false,
    icon: <Icon fontSize="small">people</Icon>,
  },
  {
    type: "collapse",
    name: "Empresas",
    key: "companies",
    route: "/dashboard/companies",
    component: <Companies />,
    hidden: false,
    icon: <Icon fontSize="small">people</Icon>,
  },
  {
    type: "collapse",
    name: "Account",
    key: "company-account",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/account",
    component: <AdminAccountInfo />,
    hidden: true,
  },
];

export default adminRoutes;
