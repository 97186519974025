export const Genders = {
  female: "Mujer",
  male: "Hombre",
  nonBinary: "No binario",
  any: "Indistinto",
};

export const CivilStatus = {
  single: "Soltero/a",
  married: "Casado/a",
  divorced: "Divorciado/a",
};

export const BloodType = {
  1: "0 negativo",
  2: "0 positivo",
  3: "A negativo",
  4: "A positivo",
  5: "B negativo",
  6: "B positivo",
  7: "AB negativo",
  8: "AB positivo",
};

export const JobOfferStatus = {
  UNVERIFIED: "unverified",
  PUBLISHED: "published",
  WORKING: "working",
  REJECTED: "rejected",
};

export const StudentValidation = {
  1: "Muy malo",
  2: "Malo",
  3: "Bueno",
  4: "Muy bueno",
  5: "Excelente",
};
