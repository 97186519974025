import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import useAPIError from "../../hooks";
import backgroundImage from "../../assets/images/bg-profile.jpeg";
import Card from "@mui/material/Card";
import Input from "../../components/molecules/input/input";
import { Link } from "react-router-dom";
import AdminService from "../../services/api/admin/adminService";
import MDButton from "../../components/MDButton";
import { Formik } from "formik";
import MDSnackbar from "../../components/MDSnackbar";

function AdminAccountInfo() {
  const [adminData, setAdminData] = useState();
  const [openNotification, setOpenNotification] = useState(false);

  const { addError } = useAPIError();

  const getAdminData = async () => {
    const response = await AdminService.getProfile();
    setAdminData(response.data);
  };

  useEffect(() => {
    getAdminData();
  }, []);

  const accountInfoInitialValues = () => {
    return {
      name: adminData?.name || "",
      surname: adminData?.surname || "",
      email: adminData?.user.email || "",
      password: adminData?.user.password || "",
    };
  };

  const onSubmit = async (values) => {
    const body = { name: values.name, cuit: values.cuit };
    const res1 = await AdminService.updateAdmin(adminData?.id, body);
    if (res1.status === 200) {
      await getAdminData();
      setOpenNotification(true);
    } else {
      addError(`${res1.data.message}`, res1.status);
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDSnackbar
        icon="notifications"
        color="success"
        title="Datos actualizados correctamente"
        open={openNotification}
        close={() => setOpenNotification(false)}
      />
      <MDBox mb={2} />
      <MDBox position="relative" mb={5}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="10.75rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({
              functions: { rgba, linearGradient },
              palette: { gradients },
            }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.6),
                rgba(gradients.info.state, 0.6)
              )}, url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />
        <Card
          sx={{
            position: "relative",
            mt: -8,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  Información de cuenta de usuario
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sx={{ ml: "auto" }}>
              <Formik
                initialValues={accountInfoInitialValues()}
                validationSchema={null}
                validateOnChange={true}
                onSubmit={onSubmit}
                enableReinitialize={true}
              >
                {({ values, errors, setFieldValue, handleSubmit, touched }) => (
                  <Grid item container mt={1} xs={12}>
                    <Grid item xs={12} md={7}>
                      <Input
                        type={"text"}
                        label={"Nombre"}
                        fullWidth={true}
                        onChange={(e) => setFieldValue("name", e.target.value)}
                        error={!!errors.name && touched.name}
                        errorText={errors.name}
                        value={values.name}
                      />
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <Input
                        type={"text"}
                        label={"Apellido"}
                        fullWidth={true}
                        onChange={(e) =>
                          setFieldValue("surname", e.target.value)
                        }
                        error={!!errors.surname && touched.surname}
                        errorText={errors.surname}
                        value={values.surname}
                      />
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <Input
                        type={"email"}
                        label={"Email"}
                        fullWidth={true}
                        disabled
                        error={!!errors.email && touched.email}
                        errorText={errors.email}
                        value={values.email}
                      />
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <Input
                        type={"password"}
                        label={"Contraseña"}
                        fullWidth={true}
                        disabled
                        error={!!errors.password && touched.password}
                        errorText={errors.password}
                        value={"**********"}
                      />
                    </Grid>
                    <Grid item xs={12} md={3} ml={2}>
                      <Link to={"/auth/password/reset"}>
                        <MDTypography
                          variant="button"
                          fontWeight="medium"
                          color="info"
                        >
                          Cambiar contraseña
                        </MDTypography>
                      </Link>
                    </Grid>
                    <Grid container justifyContent="end" mt={4} mb={1}>
                      <MDButton
                        color="info"
                        size="small"
                        onClick={() => handleSubmit()}
                      >
                        Actualizar datos
                      </MDButton>
                    </Grid>
                  </Grid>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default AdminAccountInfo;
