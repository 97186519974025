import { JobOfferStatus } from "enums";
import AtomicSpinner from "atomic-spinner";

export const getEmailList = (id) => {
  let emailList = "";

  switch (id) {
    case 1:
      emailList = "sistemas@sanfrancisco.utn.edu.ar";
      break;
    case 2:
      emailList = "quimica@sanfrancisco.utn.edu.ar";
      break;
    case 3:
      emailList = "electronica@sanfrancisco.utn.edu.ar";
      break;
    case 4:
      emailList = "electromecanica@sanfrancisco.utn.edu.ar";
      break;
    case 5:
      emailList = "industrial@sanfrancisco.utn.edu.ar";
      break;
    case 6:
      emailList = "lar@sanfrancisco.utn.edu.ar";
      break;
    case 7:
      emailList = "tup@sanfrancisco.utn.edu.ar";
      break;
    default:
      emailList = "basicas@sanfrancisco.utn.edu.ar";
  }

  return emailList;
};

export const getTasksString = (taskArray) => {
  if (!taskArray || taskArray.length === 0) {
    return "";
  }
  const taskNames = taskArray.map((item) => item.task.name);
  return taskNames.join(", ");
};

export const isHigherDateThanCurrentDate = (inputDate) => {
  const inputDateObject = new Date(inputDate);
  const currentDate = new Date();
  return inputDateObject >= currentDate;
};

export const sortArrayByCurrentDate = (dataArray) => {
  // Sorting the array by the proximity of "closeDate" to the current date
  return dataArray.sort((a, b) => {
    const closeDateA = new Date(a.endDate).getTime();
    const closeDateB = new Date(b.endDate).getTime();
    const currentDate = new Date().getTime();

    const differenceA = Math.abs(currentDate - closeDateA);
    const differenceB = Math.abs(currentDate - closeDateB);

    // Sorting in descending order (farthest date first)
    return differenceB - differenceA;
  });
};

export const changeDateFormat = (originalDate) => {
  const date = originalDate.split("T")[0];
  const parts = date.split("-");
  if (parts.length === 3) {
    // Ensure the date string has three parts (year, month, day)
    const [year, month, day] = parts;
    // Create a new date string with the desired format
    return `${day}-${month}-${year}`;
  } else {
    // Return the original date if the format is incorrect
    return date;
  }
};

export const getCurrentDate = () => {
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const mergeStudentsArrays = (firstArray, secondArray) => {
  console.log(firstArray, secondArray);
  const firstArrayMap = new Map(
    firstArray.map((item) => [item.student.id, item])
  );

  const resultArray = secondArray.map((item) => {
    const studentId = item.student.id;
    const existingItem = firstArrayMap.get(studentId);

    if (existingItem) {
      // If the student is found in the first array, update the item, add the item with 'hasApplied' property
      return {
        ...existingItem,
        ...item,
      };
    } else {
      // If the student is not found in the first array, add the item with 'hasApplied' property false
      return {
        ...item,
      };
    }
  });

  console.log(resultArray, "resutl");
  return resultArray;
};

export const sortJobOffers = (jobs) => {
  const statusOrder = [
    JobOfferStatus.UNVERIFIED,
    JobOfferStatus.PUBLISHED,
    JobOfferStatus.WORKING,
    JobOfferStatus.REJECTED,
  ];
  return jobs.sort((a, b) => {
    const statusA = a.status.toLowerCase();
    const statusB = b.status.toLowerCase();

    const orderA = statusOrder.indexOf(statusA);
    const orderB = statusOrder.indexOf(statusB);

    // Compare based on custom order
    return orderA - orderB;
  });
};

export const atomicSpinner = () => {
  return (
    <AtomicSpinner
      atomSize={75}
      displayElectronPaths={false}
      displayNucleus={true}
      electronColorPalette={["#757575", "#b1b1b1", "#d2d2d2", "#ffffff"]}
      electronPathCount={23}
      electronPathWidth={1.7}
      electronsPerPath={5}
      electronSize={1.7}
      electronSpeed={0.4}
      nucleusLayerCount={2}
      nucleusParticlesPerLayer={3}
      nucleusParticleFillColor="#efefef"
      nucleusParticleBorderColor="#ffffff"
      nucleusParticleBorderWidth={0.1}
      nucleusParticleSize={2.9}
      nucleusDistanceFromCenter={2.8}
      nucleusSpeed={3.4}
      nucleusMaskOverlap={true}
    />
  );
};
