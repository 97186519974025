import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import AdminService from "../../services/api/admin/adminService";
import CustomModal from "../../components/molecules/modal/modal";
import DataTable from "../../examples/Tables/DataTable";
import StudentInfo from "../../components/organisms/studentInfo";

function Students() {
  const [openModal, setOpenModal] = useState(false);
  const [students, setStudents] = useState([]);
  const [currentStudent, setCurrentStudent] = useState(null);

  useEffect(() => {
    getStudents();
  }, []);

  const getStudents = async () => {
    const response = await AdminService.getStudents();

    setStudents(response.data);
  };

  const handleGetStudent = async (studentId) => {
    const response = await AdminService.getStudent(studentId);
    setCurrentStudent(response.data);
    setOpenModal(true);
  };

  const columns = [
    { Header: "Nombre", accessor: "name", align: "left" },
    { Header: "Carrera", accessor: "career", align: "center" },
    { Header: "Acciones", accessor: "action", align: "center" },
  ];

  const rows = students.map((student) => {
    return {
      name: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {student.name + " " + student.surname}
        </MDTypography>
      ),
      career: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {student.career.name}
        </MDTypography>
      ),
      action: (
        student.onboardingStep < 5 ? (
          <MDTypography
            variant="caption"
            color="warning"
            fontWeight="medium"
          >
            Sin finalizar registro
          </MDTypography>
        ) : (
          <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
          onClick={() => handleGetStudent(student.id)}
        >
          Ver más
        </MDTypography>)
      ),
    };
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDTypography variant="h3">Estudiantes registrados</MDTypography>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <MDBox pt={2} pb={3}>
                {students.length > 0 ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                ) : (
                  <MDTypography variant="caption" ml={3}>
                    Aún no hay estudiantes registrados
                  </MDTypography>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <CustomModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="student-info-modal"
      >
        <MDTypography variant="h2" color="info">
          {currentStudent?.name + " " + currentStudent?.surname}
        </MDTypography>
        <StudentInfo student={currentStudent} />
      </CustomModal>
    </DashboardLayout>
  );
}

export default Students;
