import Api from "./api";
class Prediction {
  async getPrediction(studentId, opportunityId) {
    let response;
    try {
      response = await Api.get(
        `predict?studentId=${studentId}&opportunityId=${opportunityId}`
      );
    } catch (err) {
      response = err;
    }
    return response;
  }

  async getCareerPrediction(opportunityId) {
    let response;
    try {
      response = await Api.get(`predict/career/${opportunityId}`);
    } catch (err) {
      response = err;
    }
    return response;
  }

  async getStudentsPrediction(opportunityId) {
    let response;
    try {
      response = await Api.get(`predict/ranking/${opportunityId}`);
    } catch (err) {
      response = err;
    }
    return response;
  }
}

export default new Prediction();
