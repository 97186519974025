import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Card } from "@mui/material";
import { isMobile } from "../../../utils/isMobile";

function CustomModal({ open, onClose, children, height, width }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    p: 4,
    height: height || "90%",
    overflowY: "auto",
    width: isMobile() ? "98%" : "65%",
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Card sx={style}>{children}</Card>
    </Modal>
  );
}

export default CustomModal;
